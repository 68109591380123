import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Modal from 'react-modal';
import Wrapper from '@components/card/wrapper';
import Title from '@components/text/title';
import { Spacings } from '@components/variables';
import { Button } from '../../../../../../packs/rc_v1/main.js';
import styles from './modalWrapper.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => prevProps === nextProps;

const ModalWrapper = ({
  className,
  header,
  footer,
  isOpen,
  onClose,
  children,
  size,
  close,
}) => {
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const classes = cx(
    {
      modalWrapper: true,
    },
    className
  );

  const classesBody = cx(
    {
      body: true,
    },
    className
  );

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const toggleModal = (e) => {
    setIsOpen(!modalIsOpen);
    onClose && onClose();
  };

  return (
    <div className={classes}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick
        className={styles.modalWrapper}
        ariaHideApp={false}
        contentLabel='Example Modal'
        portalClassName={'portal-node'}
      >
        <Wrapper size={size}>
          {(header || close) && (
            <div className={styles.header}>
              {header && header.title && (
                <Title size={'h2'} {...header.title} />
              )}
              {close && (
                <Button
                  color={'dark'}
                  className={styles.close}
                  link
                  text={false}
                  icon={{ name: 'close', width: 20, height: 18 }}
                  action={() => toggleModal()}
                />
              )}
            </div>
          )}
          {children && <div className={classesBody}>{children}</div>}
          {footer && (
            <div className={styles.footer}>
              {footer.buttonsLeft && (
                <div className={styles.buttonsLeft}>
                  {footer.buttonsLeft.map((item, index) => (
                    <Button key={index} className={styles.button} {...item} />
                  ))}
                </div>
              )}
              {footer.buttonsRight && (
                <div className={styles.buttonsRight}>
                  {footer.buttonsRight.map((item, index) => (
                    <Button key={index} className={styles.button} {...item} />
                  ))}
                </div>
              )}
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ModalWrapper.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Function to trigger when modal closes */
  onClose: PropTypes.func,
  /** To size of the card (spacing between card & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** Body of the modal containing children elements */
  children: PropTypes.node,
  /** The close button of the modal */
  close: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  className: '',
  header: null,
  footer: null,
  isOpen: false,
  children: null,
  size: 'no-p',
  close: true,
  onClose: null,
};

// Needed for Storybook
ModalWrapper.displayName = 'ModalWrapper';

export default React.memo(ModalWrapper, checkEqual);
