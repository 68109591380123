import React, { forwardRef } from 'react';
import classNames from 'classnames/bind';
import styles from './loader.module.scss';
import Content from '@components/text/content';

const cx = classNames.bind(styles);

const Loader = forwardRef((props, ref) => {
  const loadingMessages = [
    '...Retrieving rulecards...',
    '...Drawing lines...',
    '...Updating activations...',
    '...Adding some sprinkles...',
    '...Retrieving subsets...',
    '...Dashing lines...',
    '...Calculating corners...',
    '...Almost done!...',
  ];
  return (
    <div className={styles.loading} ref={ref}>
      <div className={styles.boxes}>
        {[1, 2, 3, 4].map((box, i) => (
          <div key={i} className={styles.box}>
            <div />
            <div />
            <div />
            <div />
          </div>
        ))}
      </div>
      <div className={styles.marqueeBlockWrap}>
        <div className={styles.marqueeContent}>
          <Content
            className={styles.marquee}
            text={loadingMessages.join('', loadingMessages)}
            color={'positive'}
            id='cycle'
          />
        </div>
      </div>
    </div>
  );
});

Loader.propTypes = {};

export default React.memo(Loader);
