// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.// Entry point for the build script in your package.json
import React from 'react';
import { createRoot } from 'react-dom/client';

import '@remark/components/build/components/index.js';
import '../controllers';
import '../stream_actions';
import Rails from '@rails/ujs';
import uShiftForm from 'lib/u_shift_form';
import WidgetInitializer from 'components/widgetWrapper';

//remark components V1
import './rc_v1/main.css';
import * as Components from './rc_v1/main.js';

//remark components V2
import '@remark/components/build/brms.css';
import '@remark/components/build/react.js';
import '@remark/components/build/brms.js';

Rails.start();
require('@rails/activestorage').start();
require('channels');
require('packs/cocoon');
require('user_agent_timezone');

//alert developers if there is a mismatch between the required and installed version of remark components
//will log a message to the console
if (process?.env?.RAILS_ENV?.toString() === 'development') {
  let installedRCVersion = require('@remark/components/package.json');
  let requiredRCVersion = require('../../../package.json');
  if (installedRCVersion && requiredRCVersion) {
    installedRCVersion = parseInt(installedRCVersion.version.split('.').pop());
    requiredRCVersion = parseInt(
      requiredRCVersion.dependencies['@remark/components'].split('.').pop()
    );
    if (installedRCVersion < requiredRCVersion) {
      const cssRule =
        'color: rgb(233,96,93);' +
        'font-size: 32px;' +
        'font-weight: bold;' +
        'text-shadow: 1px 1px 5px rgb(233,96,93);' +
        'filter: dropshadow(color=rgb(233,96,93), offx=1, offy=1);';
      setTimeout(console.log.bind(console, '%cUpdate required!', cssRule), 0);
      const cssText = 'color: rgb(34,34,34);' + 'font-size: 14px;';
      setTimeout(
        console.log.bind(
          console,
          `%cInvalid version of remark components installed. Please run yarn install to install the correct version of remark components`,
          cssText
        ),
        0
      );
    }
  }
}

// V1 & V2 of components have a react clash
// This surpresses the warning
const originalConsoleError = console.error;

console.error = (...args) => {
  if (
    args
      .filter((arg) => typeof arg === 'string')
      .some(
        (arg) =>
          arg.includes('You are calling ReactDOMClient.createRoot()') ||
          arg.includes(
            'You are importing createRoot from "react-dom" which is not supported.'
          )
      ) ||
    args
      .filter((arg) => typeof arg === 'object')
      .some(
        (arg) =>
          arg.message ===
            "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node." ||
          arg.message ===
            'Minified React error #62; visit https://reactjs.org/docs/error-decoder.html?invariant=62 for the full message or use the non-minified dev environment for full errors and additional helpful warnings.'
      )
  ) {
    // Ignore the warnings
    return;
  }

  // Call the original console.error function for other warnings/errors
  originalConsoleError.call(console, ...args);
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/* Register module components */
const lib = (window.lib = global.lib = {});
Object.keys(Components).forEach((component) => {
  if (Components[component].displayName) {
    lib[Components[component].displayName] = Components[component];
  }
});

/* Register local components */
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

window.launchModal = function (content) {
  if (document.querySelector('.portal-node')) {
    document.querySelector('.portal-node').remove();
  }

  const container = document.createElement('div');
  container.innerHTML = content;
  document.body.appendChild(container);

  ReactRailsUJS.mountComponents(container);
  Rails.refreshCSRFTokens();
};

function initialLoad() {
  Rails.refreshCSRFTokens();
  ReactRailsUJS.mountComponents(document.body);
}
document.addEventListener('turbo:load', initialLoad);

function afterCocoon(customEvent) {
  const nodes = customEvent.target.querySelectorAll('.cocoon-inner');
  const previousNode = nodes[nodes.length - 2];
  const newNode = nodes[nodes.length - 1];

  const sequenceNode = newNode.querySelector('[data-sequence]');
  if (sequenceNode) {
    if (previousNode) {
      sequenceNode.value =
        parseInt(previousNode.querySelector('[data-sequence]').value) + 1;
    } else {
      sequenceNode.value = 1;
    }
  }

  ReactRailsUJS.mountComponents(newNode);

  setTimeout(() => {
    setCocoonRandomId(customEvent);
  }, 500);
}

function setCocoonRandomId(customEvent) {
  let parent_elements = customEvent.target.querySelectorAll('.cocoon-inner');
  parent_elements.forEach((parent_element) => {
    let randomId = Math.floor(Math.random() * 1000000000);
    let elements = document.querySelectorAll('[name*="COCOON_REPLACE_ME"]');
    elements.forEach((element) => {
      let name = element.getAttribute('name');
      element.setAttribute(
        'name',
        name.replace(/COCOON_REPLACE_ME/gi, randomId)
      );
    });
  });
}

window.deeplyNestedCocoon = () => {
  const nodes = document.querySelectorAll('[data-add-variable]');
  nodes.forEach((node, index) => {
    const insertion_node = node
      .closest('.u-grid')
      .closest('.nested-fields')
      .querySelector('.cocoon-assignment-rows');
    const uniqueId = `unique-${Date.now()}${index}`;
    insertion_node.setAttribute('id', uniqueId);
    node.setAttribute('data-association-insertion-node', `#${uniqueId}`);
  });
};

document.addEventListener('cocoon:after-insert', afterCocoon);
document.addEventListener('turbo:load', function () {
  document.querySelectorAll('.cocoon-inner').forEach(function (element) {
    setCocoonRandomId({ target: element });
  });
});
document.addEventListener('turbo:load', window.deeplyNestedCocoon);
document.addEventListener('cocoon:after-insert', window.deeplyNestedCocoon);

window.replaceInnards = function (nodeId, content) {
  const domNode = document.getElementById(nodeId);
  domNode.innerHTML = content;
  ReactRailsUJS.mountComponents(domNode);
};

document.addEventListener('turbo:load', () => {
  uShiftForm();
});

/**
 * Fix for Emotion CSS not working with Turbolinks
 * Emotion CSS is used for React Select.
 * Relatet tickets; BRMS-1693, BRMS-1555
 */

document.addEventListener('turbo:before-visit', () => {
  // get all emotion css sheets
  const emotionSheets = [...document.styleSheets].filter((sheet) =>
    sheet.ownerNode.hasAttribute('data-emotion')
  );

  if (emotionSheets) {
    // get all css from rules per sheet, flatten the output
    const emotionRules = emotionSheets
      .map((sheet) => [...sheet.rules].map((rule) => rule.cssText))
      .flat();

    // make output unique
    const emotionCss = [...new Set(emotionRules)];

    // create new style tag
    const emotionCombinedTag = document.createElement('style');
    emotionCombinedTag.setAttribute('data-emotion-combined', '');
    emotionCombinedTag.innerHTML = emotionCss.join(' ');

    // only append to head when there are rules
    document.head.appendChild(emotionCombinedTag);
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (Components._breakpointsInit) {
    Components._breakpointsInit();
  }
});

// Monkey patch Turbolinks to render 403, 404 & 500 normally
// See https://github.com/turbolinks/turbolinks/issues/179
// ---
// TODO: Need to rewrite this to work with TURBO OR is this patch no longer needed?
// ---
// window.Turbolinks.HttpRequest.prototype.requestLoaded = function () {
//   return this.endRequest(() => {
//     const code = this.xhr.status;
//     if (
//       (code >= 200 && code < 300) ||
//       code === 403 ||
//       code === 404 ||
//       code === 500
//     ) {
//       this.delegate.requestCompletedWithResponse(
//         this.xhr.responseText,
//         this.xhr.getResponseHeader('Turbo-Location')
//       );
//     } else {
//       this.failed = true;
//       this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
//     }
//   });
// };

//If V1 components are added to a V2 component, the mount event needs to mount the components inside
document.addEventListener('brms-row:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);
});
document.addEventListener('brms-column:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);

  // temp solution to fix attribute changes of elements in old components
  if (navigator.userAgent.match(/firefox|fxios/i)) {
    document.querySelectorAll('brms-column [padding]').forEach((elem) => {
      const padding = elem.padding;
      const paddingWithValue = padding
        .split('/')
        .filter((p) => parseInt(p) !== 0);
      if (paddingWithValue.length > 0) {
        requestAnimationFrame(() => {
          const newPadding = elem.padding
            .split('/')
            .map((p) => (parseInt(p) > 0 ? parseInt(p) + 1 : p))
            .join('/');
          elem.padding = newPadding;
        });
      }
    });
    document.querySelectorAll('brms-column [height]').forEach((elem) => {
      const height = elem.height;
      if (height > 1) {
        requestAnimationFrame(() => {
          const newHeight = parseInt(height) + 1;
          elem.height = newHeight;
        });
      }
    });
  }
});
document.addEventListener('brms-card:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);
});

/* temp solution to render widget in RC V2 correctly */
document.addEventListener('turbo:load', (event) => {
  const widget = document.getElementById('brms-widget-placeholder');
  if (widget) {
    const atts = widget?.attributes;
    const widgetRoot = createRoot(widget);
    const props = {};
    Array.from(atts).map(({ name, value }) => {
      props[name.replace(/(-\w)/g, (match) => match[1].toUpperCase())] = value;
    });
    widgetRoot.render(<WidgetInitializer {...props} />);
  }
});

// Dirty hack for initial page load, will be removed when vanilla is fully implemented
document.addEventListener('DOMContentLoaded', () => {
  let navigationType;
  if (window.performance.getEntriesByType('navigation').length > 0) {
    navigationType = window.performance.getEntriesByType('navigation')[0].type;
  }
  const turboVisitContent = document.querySelector(
    'meta[name="turbo-visit-control"]'
  );
  if (!turboVisitContent && navigationType === 'reload') {
    Turbo.visit(window.location.href);
  }
});
