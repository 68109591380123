export default function turboFetch(endpoint) {
  fetch(endpoint, {
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.text();
    })
    .then((html) => {
      Turbo.renderStreamMessage(html);
    })
    .catch((error) => {
      console.error('Fetch error:', error);
    });
}
