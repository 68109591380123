import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import Icon from '@components/ui/icon';

const cx = classNames.bind(styles);

// Compare on port level
const checkEqual = (prev, next) =>
  JSON.stringify(prev.port) === JSON.stringify(next.port);

const CustomPort = ({ port, arrow, iconModule }) => {
  const classes = cx({
    dotWrapper: true,
  });
  const dotClasses = cx({
    dot: true,
    dotIn: port.type === 'top' || port.type === 'left',
    dotOut: port.type === 'bottom' || port.type === 'right',
    arrow,
  });

  const PortWrapper = () => {
    if (arrow) {
      return (
        <div className={dotClasses}>        
          <Icon
            width={8}
            height={8}
            iconModule={iconModule}
            icon={port.type === 'top' || port.type === 'bottom' ? 'chevron-down' : 'chevron-right'} 
            color={port.type === 'top' || port.type === 'left' ? 'balanced-500' : 'light'} 
          /> 
        </div>
      )
    } else {
      return <div className={dotClasses} />
    }
  }

  return (
    <div className={classes} id={port.id}>
      <div>
        <PortWrapper />
      </div>
    </div>
  );
};

CustomPort.propTypes = {
  port: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  arrow: PropTypes.bool,
};

export default React.memo(CustomPort, checkEqual);
