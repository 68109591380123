import React from 'react';
import AssignmentModal from '.';

export default {
  title: 'BRMS/flowchart/modals/AssignmentModal',
  component: AssignmentModal,
};

const Template = (args) => <AssignmentModal {...args} />;

export const Default = Template.bind({});

Default.args = {
  isOpen: true,
  header: {
    title: {
      text: 'Edit assignment',
    },
  },
  footer: {
    buttonsLeft: [
      {
        color: 'stable-500',
        outline: true,
        icon: {
          name: 'trash',
          color: 'assertive',
        },
      },
    ],
    buttonsRight: [
      {
        color: 'stable-500',
        text: 'Cancel',
        textColor: 'positive',
        outline: true,
      },
      {
        text: 'Save changes',
      },
    ],
  },
  data: [
    {
      select: {
        id: '0',
        type: 'select',
        attributes: [],
        options: [
          { value: 'question', label: 'Question', checked: true },
          { value: 'premium', label: 'Premium' },
        ],
      },
      fields: [
        {
          label: 'field 1',
          inline: true,
          background: true,
        },
      ],
    },
  ],
  addData: {
    select: {
      id: '1',
      type: 'select',
      attributes: [],
      options: [
        { value: 'question', label: 'Question', checked: true },
        { value: 'premium', label: 'Premium' },
      ],
    },
    fields: [
      {
        label: 'field 1',
        inline: true,
        background: true,
      },
      {
        label: 'field 2',
        inline: true,
        background: true,
      },
    ],
  },
  selectLabel: {
    text: 'Variables',
    size: 's',
  },
  fieldLabel: {
    text: 'Settings',
    size: 's',
  },
};
