import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['validate'];

  static values = {
    url: { type: String, default: '/admin/evaluation_validations' },
    method: { type: String, default: 'post' },
    validator: String,
    subsetId: String,
    setValidity: Boolean,
  };

  static targets = ['editor', 'errorMessage', 'errorRow'];

  connect() {
    this.controller = null;
    this.errors = [];
    this.initializeEditor();
    useDebounce(this, { wait: 300, method: 'validate' });
  }

  validate() {
    const editorSession = this.editorTarget.editor.session;
    const url = this.urlValue;
    const method = this.methodValue;

    if (url === '') return;

    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();
    const signal = this.controller.signal;

    fetch(url, {
      method: method,
      body: `evaluation[condition]=${encodeURIComponent(
        editorSession.doc.$lines.join(' ')
      )}&evaluation[validator]=${this.validatorValue}&evaluation[subset_id]=${
        this.subsetIdValue
      }`,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      signal: signal,
    })
      .then((response) => response.json())
      .then((resp) => {
        const ruleErrors = [];
        editorSession.doc.$lines.map((words, index) => {
          words.split(' ').map((word) => {
            if (Object.keys(resp.errors).includes(word.replace(/\s/g, ''))) {
              if (ruleErrors.findIndex((x) => x.index === index) === -1) {
                ruleErrors.push({
                  word,
                  index,
                  errors: [
                    {
                      key: word,
                      error: resp.errors[word.replace(/\s/g, '')],
                    },
                  ],
                });
              }
            }
          });
        });

        const genericErrors = [];
        Object.keys(resp.errors).map((error) => {
          if (error === 'syntax' || error === 'ending_token') {
            genericErrors.push(resp.errors[error]);
          } else {
            genericErrors.push(`${error}: ${resp.errors[error]}`);
          }
        });

        const shouldSetValidity = this.setValidityValue;
        if (shouldSetValidity !== undefined) {
          if (ruleErrors.length > 0 || genericErrors.length > 0) {
            this.setValidity(false);
          } else {
            this.setValidity(true);
          }
        }

        this.setErrors(genericErrors);
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('Fetch error:', error);
        }
      });
  }

  initializeEditor() {
    this.editorTarget.addEventListener(
      'rmv-code-editor:input:code-editor',
      () => {
        this.validate();
      }
    );

    this.errorRowTarget.hidden = true;
  }

  setValidity(isValid) {
    if (this.setValidityValue) {
      this.validateInputTarget.value = isValid ? 'valid' : 'invalid';
    }
  }

  setErrors(errors) {
    this.errorMessageTarget.innerText = errors.join(', ');

    if (errors.length > 0) {
      this.editorTarget.setAttribute('error', true);
      this.errorRowTarget.hidden = false;
    } else {
      this.editorTarget.removeAttribute('error');
      this.errorRowTarget.hidden = true;
    }
  }
}
