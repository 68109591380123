import React from 'react';
import Divider from '../divider';
import Snackbar from '.';

export default {
  title: 'BRMS/ui/Snackbar',
  component: Snackbar,
};

const Template = (args) => <Snackbar {...args} />;

export const Default = Template.bind({});
Default.args = {
  type: 'success',
  title: {
    text: 'Successfull request for consultation for claim 3510396',
  },
  dismissable: true,
};

export const Types = () => (
  <>
    <Snackbar {...Default.args} type={'none'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'info'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} dismissable={false} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'warning'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'error'} />
    <Divider height={15} color={'transparent'} />
    <Snackbar {...Default.args} type={'error'} fixed />
    <Divider height={15} color={'transparent'} />
    <Snackbar
      type={'warning'}
      title={{
        text: 'Update available',
        color: 'royal',
      }}
      icon={{
        icon: 'warning-square',
        color: 'royal',
      }}
      content={{
        text: 'There are updates available to pull from the master ruleset',
      }}
      dismissable={false}
      buttons={[
        {
          action: '#',
          text: 'Get updates from master',
          color: 'stable-500',
          textColor: 'positive',
          outline: true,
        },
      ]}
    />
  </>
);

export const markdown = () => (
  <Snackbar
    {...Default.args}
    type={'warning'}
    markdown={
      'The rule [answer_now](https://www.remarkgroup.com) has been updated!'
    }
  />
);
