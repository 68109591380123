import React from 'react';
import RemoveModal from '.';

export default {
  title: 'BRMS/flowchart/modals/RemoveModal',
  component: RemoveModal,
};

const Template = (args) => <RemoveModal {...args} />;

export const Default = Template.bind({});

Default.args = {
  isOpen: true,
  title: {
    text: 'Are you sure you want to remove this condition?',
  },
  content: {
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget vestibulum est. Suspendisse porttitor sit amet nisi in tempus. Maecenas hendrerit tempus maximus. Morbi nec justo aliquet, egestas orci non, semper quam.',
  },
  buttons: [
    {
      color: 'stable-500',
      text: 'Cancel',
      textColor: 'positive',
      outline: true,
    },
    {
      text: 'Remove condition',
      color: 'assertive',
    },
  ],
};
