import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames/bind';
import styles from './markdown.module.scss';
import Content from '@components/text/content';
import Button from '@components/button/button';

const cx = classNames.bind(styles);

const Markdown = ({ className, body }) => {
  const classes = cx(
    {
      markdown: true,
    },
    className
  );

  // TO-DO: Update with more components where needed.
  const renderers = {  
    p: ({ ...props }) => <Content color={'dark'} {...props} />,
    a: ({ href, ...props }) => <Button link size={'m'} action={href} {...props} />
  }

  return (
    <ReactMarkdown className={classes} children={body} components={renderers} remarkPlugins={[remarkGfm]} />
  );
};

Markdown.propTypes = {
  /** To use markdown strings */
  body: PropTypes.string.isRequired,
  /** Adds custom styles to the component. Mostly passed when used in another component */
  className: PropTypes.string,
};

Markdown.defaultProps = {
  body: '',
  className: '',
};

// Needed for Storybook
Markdown.displayName = 'Markdown';

export default Markdown;
