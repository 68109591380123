import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';

const IconRight = (props) => <Button {...props} iconRight />;

// Needed for Storybook
IconRight.displayName = 'IconRight';

IconRight.propTypes = {
  /** All button props are allowed */
  button: PropTypes.shape(Button.propTypes),
};

IconRight.defaultProps = {};

export default IconRight;
