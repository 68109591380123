import React from 'react';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Divider from '@components/ui/divider';
import * as Variables from './index';

export default {
  title: 'BRMS/ui/Variables',
};

const ColorOutput = () => (
  <div style={{ display: 'flex', overflow: 'auto', maxWidth: '100%' }}>
    {Variables.Colors.map((color, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
        <div
          className={color}
          style={{
            width: 100,
            height: 100,
            backgroundColor: 'currentColor',
            border: color == 'light' ? '1px solid #ccc' : '1px solid #fff',
            marginRight: index != Variables.Colors.length - 1 ? 10 : 0,
          }}
        />
        <Title text={color} size={'body'} />
        <Content
          text={getComputedStyle(document.documentElement).getPropertyValue(
            `--color-${color}`
          )}
          size={'s'}
        />
      </div>
    ))}
  </div>
);

const FontOutput = () => (
  <div>
    {Variables.Sizes.map((size, index) => (
      <div key={index}>
        <Content
          text={`Lorem ipsum dolor sit`}
          size={size}
          color={'dark'}
          className={`font-size-${size}`}
        />
        <Title text={size} size={'body'} />
        <Content
          text={getComputedStyle(document.documentElement).getPropertyValue(
            `--font-size-${size}`
          )}
          size={'s'}
        />
        <Divider height={20} color={'light'} />
      </div>
    ))}
  </div>
);

const RadiusOutput = () => (
  <div style={{ display: 'flex' }}>
    {Variables.Radius.map((radius, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
        <div
          className={`light radius-${radius}`}
          style={{
            width: 100,
            height: 50,
            backgroundColor: 'currentColor',
            border: '1px solid #ccc',
            marginRight: index != Variables.Colors.length - 1 ? 10 : 0,
          }}
        />
        <Title text={radius} size={'body'} />
        <Content
          text={getComputedStyle(document.documentElement).getPropertyValue(
            `--radius-${radius}`
          )}
          size={'s'}
        />
      </div>
    ))}
  </div>
);

const SpacingOutput = () => (
  <div style={{ display: 'flex' }}>
    {Variables.Spacings.map((spacing, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
        <div
          style={{
            width: 100,
            height: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #ccc',
            marginRight: index != Variables.Colors.length - 1 ? 10 : 0,
            padding: `var(--spacing-${spacing})`,
          }}
        >
          <div
            className={`stable-500`}
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'currentColor',
            }}
          />
        </div>
        <Title text={spacing} size={'body'} />
        <Content
          text={getComputedStyle(document.documentElement).getPropertyValue(
            `--spacing-${spacing}`
          )}
          size={'s'}
        />
      </div>
    ))}
  </div>
);

const Template = (args) => (
  <div>
    <Title text={'Colors'} />
    <Divider color={'dark'} />
    <Divider color={'light'} height={10} />
    {ColorOutput()}
    <Divider color={'light'} height={50} />
    <Title text={'Fontsizes'} />
    <Divider color={'dark'} />
    {FontOutput()}
    <Divider color={'light'} height={50} />
    <Title text={'Radius'} />
    <Divider color={'dark'} />
    <Divider color={'light'} height={10} />
    {RadiusOutput()}
    <Divider color={'light'} height={50} />
    <Title text={'Spacings'} />
    <Divider color={'dark'} />
    <Divider color={'light'} height={10} />
    {SpacingOutput()}
  </div>
);

export const Default = Template.bind({});

export const Colors = () => ColorOutput();

export const FontSizes = () => FontOutput();

export const Radius = () => RadiusOutput();

export const Spacings = () => SpacingOutput();
