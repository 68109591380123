import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';

const iconLeft = (props) => <Button {...props} />;

// Needed for Storybook
iconLeft.displayName = 'iconLeft';

iconLeft.propTypes = {
  /** All button props are allowed */
  button: PropTypes.shape(Button.propTypes),
};

iconLeft.defaultProps = {};

export default iconLeft;
