import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Count from '@components/badge/count';
import Button from '@components/button/button';
import Title from '@components/text/title';
import Content from '@components/text/content';
import { Spacings } from '@components/variables';
import Wrapper from '@components/card/wrapper';
import Icon from '../icon';
import styles from './snackbar.module.scss';
import Divider from '../divider';
import Markdown from '@components/text/markdown';

const cx = classNames.bind(styles);

const Snackbar = ({
  fixed,
  inline,
  icon,
  className,
  type,
  size,
  content,
  buttons,
  title,
  dismissable,
  markdown,
}) => {
  const [dismissed, setDismissed] = useState(false);
  const classes = cx(
    {
      snackbar: true,
      fixed,
    },
    className
  );

  const iconClass = cx({
    inline,
  });

  const iconObject =
    icon ||
    (type == 'info'
      ? { icon: 'comment' }
      : type == 'success'
      ? { icon: 'checkmark' }
      : type == 'error'
      ? { icon: 'close' }
      : { icon: 'warning-square' });

  const iconColor =
    icon && icon.color
      ? icon.color
      : type == 'info'
      ? 'positive'
      : type == 'success'
      ? 'energized'
      : type == 'error'
      ? 'assertive'
      : type == 'warning'
      ? 'balanced-100'
      : 'transparent';

  if (dismissed) {
    return null;
  }
  return (
    <Wrapper color={'light'} size={size} className={classes}>
      {(iconObject || type !== 'none') && (
        <Count
          size={inline ? 's' : 'l'}
          lighten
          color={iconColor}
          round={!inline}
          className={iconClass}
          icon={iconObject}
        />
      )}
      {type !== 'none' && <Divider width={15} color={'transparent'} />}
      <div className={styles.content}>
        {markdown && <Markdown body={markdown} />}
        {title && !markdown && <Title size={'body'} emphasis {...title} />}
        {content && !markdown && <Content size={'m'} emphasis {...content} />}
      </div>
      {dismissable && <Divider width={15} color={'transparent'} />}
      {dismissable && (
        <Button
          text={'Close'}
          color={'positive'}
          size={'m'}
          className={styles.button}
          action={() => {
            setDismissed(true);
          }}
        />
      )}
      {buttons && (
        <div className={styles.buttons}>
          {buttons.map((button, index) => {
            return <Button key={index} size={'m'} {...button} />;
          })}
        </div>
      )}
    </Wrapper>
  );
};

Snackbar.propTypes = {
  /** The type of the bar */
  type: PropTypes.oneOf(['none', 'info', 'success', 'error', 'warning']),
  /** The name of the user */
  title: PropTypes.shape(Title.propTypes),
  /** The name of the icon, shown before the title */
  icon: PropTypes.oneOfType([PropTypes.shape(Icon.propTypes), PropTypes.bool]),
  /** Whether the bar can be dismissed */
  dismissable: PropTypes.bool,
  /** Whether the bar is fixed in the view */
  fixed: PropTypes.bool,
  /** To show an icon without a background */
  inline: PropTypes.bool,
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** The size of the card */
  size: PropTypes.oneOf(Spacings),
  /** Whether markdown is used in the snackbar (i.e. for links) */
  markdown: PropTypes.string,
};

Snackbar.defaultProps = {
  size: 'm',
  type: 'none',
  title: '',
  icon: false,
  dismissable: true,
  fixed: false,
  inline: false,
  className: '',
  markdown: '',
};

// Needed for Storybook
Snackbar.displayName = 'Snackbar';

export default Snackbar;
