import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'content'];

  connect() {
    this.openFirst();
  }

  openFirst() {
    this.contentTargets[0].style.display = 'block';
    this.linkTargets[0].selected = true;
    this.setNamespaceTitle(this.linkTargets[0].text);
  }

  open(e) {
    e.preventDefault();
    this.closeAllButtons();

    this.contentTargets.forEach((content) => {
      if (e.params.id == content.getAttribute('data-pane-id')) {
        content.style.display = 'block';
        this.findClosestButton(e.target).selected = true;
        this.setNamespaceTitle(this.findClosestButton(e.target).text);
      } else {
        content.style.display = 'none';
      }
    });
  }

  closeAllButtons() {
    this.linkTargets.forEach((link) => {
      link.selected = false;
    });
  }

  findClosestButton(el) {
    if (el.matches('rmv-button')) {
      return el;
    } else {
      return el.closest('rmv-button');
    }
  }

  setNamespaceTitle(name) {
    document.getElementById('namespace_title').innerHTML = name;
  }
}
