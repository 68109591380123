import React from 'react';
import RuleCard from '.';

export default {
  title: 'BRMS/flowchart/cards/RuleCard',
  component: RuleCard,
};

const Template = (args) => <RuleCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Anaemia pregnancy rule',
  },
  link: '#',
};
