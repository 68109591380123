import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static values = { endpoint: String, noresults: String, nosearch: String };
  static debounces = ['submit'];

  connect() {
    useDebounce(this, { wait: 300 });
    this.element.eventId = 'async-select';
    if (
      this.element.options &&
      this.element.options.length === 1 &&
      this.element.options[0].value === ''
    ) {
      this.placeholder = this.nosearchValue;
    }
  }

  disconnect() {
    this.abortController?.abort();
  }

  get searchInput() {
    return this.element.shadowRoot.querySelector('[name="search_terms"]');
  }

  get placeholder() {
    return this.element.shadowRoot.querySelector('#placeholder');
  }

  set placeholder(value) {
    if (value && !this.placeholder) {
      const cardPlaceholder = document.createElement('rmv-button');
      cardPlaceholder.variant = 'tertiary';
      cardPlaceholder.setAttribute('text', value);
      cardPlaceholder.classList.add('choices__item');
      cardPlaceholder.classList.add('has-no-results');
      cardPlaceholder.id = 'placeholder';
      const listBox = this.element.shadowRoot.querySelector(
        '.choices__list [role="listbox"]'
      );
      listBox.append(cardPlaceholder);
    } else if (!value) {
      this.element.shadowRoot.querySelector('#placeholder').remove();
    }
  }

  get endpoint() {
    const url = this.endpointValue;
    const rootUrl = url.startsWith('http')
      ? url
      : [window.location.origin, url.startsWith('/') ? url.slice(1) : url].join(
          '/'
        );
    const urlObject = new URL(rootUrl);
    const paramsObject = new URLSearchParams({
      query: this.searchInput.value,
    });
    for (const [key, value] of paramsObject) {
      urlObject.searchParams.append(key, value);
    }
    return urlObject.toString();
  }

  clear() {
    this.placeholder = this.nosearchValue;
  }

  async submit(e) {
    // Abort the previous fetch request if any
    this.abortController?.abort();
    // Create a new AbortController for the new fetch request
    this.abortController = new AbortController();
    if (this.searchInput.value === '') {
      this.element.options = [];
      this.placeholder = this.nosearchValue;
    } else {
      try {
        const response = await fetch(this.endpoint, {
          signal: this.abortController.signal,
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.options && data.options.length > 0) {
          const options = data.options.map((option) => {
            return {
              label: option.title,
              value: option.value,
            };
          });
          this.element.options = JSON.stringify(options);
        } else {
          this.element.options = [];
          this.placeholder = this.noresultsValue;
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Fetch Error:', error);
        }
      }
    }
  }
}
