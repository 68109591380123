import React from 'react';
import { Divider } from '@components/ui';
import { Colors as ColorNames } from '@components/variables';
import Tab from '.';

export default {
  title: 'BRMS/button/Tab',
  component: Tab,
};

const Template = (args) => (
  <div
    style={{
      display: 'flex',
    }}
  >
    <Tab {...args} active />
    <Tab {...args} />
  </div>
);

export const Default = Template.bind({});
Default.args = {
  button: {
    text: 'Tab',
    icon: {
      icon: 'empty',
    },
    color: 'transparent',
  },
  textColor: 'balanced-500',
  activeColor: 'positive',
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => {
      const bgColor =
        color == 'positive-300'
          ? `rgba(var(--color-${color}), 0.15)`
          : `rgba(var(--color-${color}), 1)`;
      return (
        <div key={index}>
          <div style={{ backgroundColor: bgColor, display: 'flex' }}>
            <Tab
              {...Default.args}
              active
              button={{
                text: color.charAt(0).toUpperCase() + color.slice(1),
                color,
              }}
            />
            <Tab
              {...Default.args}
              button={{
                text: color.charAt(0).toUpperCase() + color.slice(1),
                color,
              }}
            />
          </div>
          <Divider height={15} color={'light'} />
        </div>
      );
    })}
  </>
);
