import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import Divider from '@components/ui/divider';
import Badge from '.';

export default {
  title: 'BRMS/badge/Badge',
  component: Badge,
};

const Template = (args) => <Badge {...args} />;

export const Default = Template.bind({});
Default.args = {
  content: {
    text: 'Default value',
  },
};

const types = ['text', 'operator', 'boolean', '!boolean'];

export const Variants = () => (
  <>
    {types.map((type, index) => (
      <Badge {...Default.args} type={type} key={index} />
    ))}
  </>
);

export const VariantsLighten = () => (
  <div style={{ display: 'flex' }}>
    {types.map((type, index) => (
      <>
        <Badge content={{ text: type }} type={type} lighten key={index} />
        <Divider width={10} color={'transparent'} />
      </>
    ))}
  </div>
);

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => (
      <Badge key={index} content={{ text: color }} color={color} />
    ))}
  </>
);

const sizes = ['s', 'm', 'l'];

export const Sizes = () => (
  <>
    {sizes.map((size, index) => (
      <Badge key={index} content={{ text: `size ${size}` }} size={size} />
    ))}
  </>
);
