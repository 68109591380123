import React from 'react';
import Table from '.';

export default {
  title: 'BRMS/table/Table',
  component: Table,
};

const Template = (args) => (
  <>
    <div id='hidden_div' className='u-hide'>
      <p>I'm in a dropdown</p>
    </div>
    <Table {...args} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  sizes: [50, 20, 20, 5, 5],
  head: [
    {
      action: '?q=x&y=z',
      text: 'Rules',
    },
    {
      action: '?q=x&y=z',
      text: 'Kind',
    },
    {
      action: '?q=x&y=z',
      text: 'Category',
    },
    {},
    {},
  ],
  body: [
    {
      data: [
        {
          action: '#',
          text: 'First lane',
        },
        {
          text: 'Ben Smith',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'balanced-500',
          icon: { icon: 'chevron-down' },
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          action: '#',
          text: 'Second lane',
        },
        {
          text: 'Ben Smith',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'balanced-500',
          icon: { icon: 'chevron-down' },
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
    {
      data: [
        {
          action: '#',
          text: 'Third lane',
        },
        {
          text: 'Ben Smith',
        },
        {
          text: '14-07-2020',
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'balanced-500',
          icon: { icon: 'chevron-down' },
        },
        {
          action: 'url',
          color: 'transparent',
          textColor: 'assertive',
          icon: { icon: 'trash' },
        },
      ],
    },
  ],
};

export const WithPagination = () => (
  <>
    <Table
      pagination={{
        current: 44,
        max: 100,
        param: 'page',
      }}
      {...Default.args}
    />
  </>
);

export const Simple = () => (
  <>
    <Table
      simple
      collapse
      sizes={[30, 30, 30]}
      head={[
        {
          text: 'Experience class',
          color: 'light',
        },
        {
          text: 'Name',
          color: 'light',
        },
        {
          text: 'Title',
          color: 'light',
        },
      ]}
      body={[
        {
          data: [
            {
              text: 'class_1',
              color: 'balanced-500',
            },
            {
              text: 'treatment_1',
              color: 'balanced-500',
            },
            {
              text: 'Treatment A',
              color: 'balanced-500',
            },
          ],
        },
        {
          data: [
            {
              text: 'class_2',
              color: 'balanced-500',
            },
            {
              text: 'treatment_2',
              color: 'balanced-500',
            },
            {
              text: 'Treatment B',
              color: 'balanced-500',
            },
          ],
        },
        {
          data: [
            {
              text: 'class_3',
              color: 'balanced-500',
            },
            {
              text: 'treatment_3',
              color: 'balanced-500',
            },
            {
              text: 'Treatment C',
              color: 'balanced-500',
            },
          ],
        },
      ]}
    />
  </>
);

export const WithUnreachableRow = () => (
  <>
    <Table
      sizes={[50, 20, 20, 5, 5]}
      head={[
        {
          action: '?q=x&y=z',
          text: 'Rules',
        },
        {
          action: '?q=x&y=z',
          text: 'Kind',
        },
        {
          action: '?q=x&y=z',
          text: 'Category',
        },
        {},
        {},
      ]}
      body={[
        {
          tooltipText: 'This is unreachable',
          unreachable: true,
          data: [
            {
              action: '#',
              text: 'First lane',
            },
            {
              text: 'Ben Smith',
            },
            {
              text: '14-07-2020',
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'balanced-500',
              icon: { icon: 'chevron-down' },
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'assertive',
              icon: { icon: 'trash' },
            },
          ],
        },
        {
          data: [
            {
              action: '#',
              text: 'Second lane',
            },
            {
              text: 'Ben Smith',
            },
            {
              text: '14-07-2020',
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'balanced-500',
              icon: { icon: 'chevron-down' },
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'assertive',
              icon: { icon: 'trash' },
            },
          ],
        },
        {
          data: [
            {
              action: '#',
              text: 'Third lane',
            },
            {
              text: 'Ben Smith',
            },
            {
              text: '14-07-2020',
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'balanced-500',
              icon: { icon: 'chevron-down' },
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'assertive',
              icon: { icon: 'trash' },
            },
          ],
        },
      ]}
    />
  </>
);

export const WithTableContent = () => (
  <>
    <Table
      tableContent
      sizes={[50, 20, 20, 5, 5]}
      head={[
        {
          action: '?q=x&y=z',
          text: 'Rules',
        },
        {
          action: '?q=x&y=z',
          text: 'Kind',
        },
        {
          action: '?q=x&y=z',
          text: 'Category',
        },
        {},
        {},
      ]}
      body={[
        {
          data: [
            {
              action: '#',
              text: 'First lane',
            },
            {
              text: 'Ben Smith',
            },
            {
              text: '14-07-2020',
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'balanced-500',
              icon: { icon: 'chevron-down' },
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'assertive',
              icon: { icon: 'trash' },
            },
            {
              content: 'Content',
            },
          ],
        },
      ]}
    />
  </>
);

export const WithStatus = () => (
  <>
    <Table
      tableContent
      sizes={[50, 20, 20, 5, 5]}
      head={[
        {
          action: '?q=x&y=z',
          text: 'Rules',
        },
        {
          action: '?q=x&y=z',
          text: 'Kind',
        },
        {
          action: '?q=x&y=z',
          text: 'Category',
        },
        {},
        {},
      ]}
      body={[
        {
          data: [
            {
              status: {
                title: {
                  text: 'Warning!',
                },
                icon: {
                  icon: 'warning',
                },
                color: 'royal',
              },
            },
            {
              text: 'Ben Smith',
            },
            {
              text: '14-07-2020',
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'balanced-500',
              icon: { icon: 'chevron-down' },
            },
            {
              action: 'url',
              color: 'transparent',
              textColor: 'assertive',
              icon: { icon: 'trash' },
            },
          ],
        },
      ]}
    />
  </>
);

export const WithSearch = () => (
  <>
    <Table search={{ placeholder: 'Search in the table' }} {...Default.args} />
  </>
);
