import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '@components/button/button';
import Badge from '@components/badge/badge';
import Purify from '@components/ui/purify';
import styles from './detail.module.scss';
import Title from '../title';
import Status from '../status';
import List from '../list';

const cx = classNames.bind(styles);

const Detail = ({
  className,
  reverse,
  reverseBadge,
  row,
  minWidth,
  maxWidth,
  value,
  label,
  values,
  badges,
}) => {
  const classes = cx(
    {
      detail: true,
      reverseBadge,
      row,
      [`labelWidth-${minWidth}`]: row,
      [`valueWidth-${maxWidth}`]: row,
    },
    className
  );
  const labelClasses = cx({
    label: true,
    valueTop: reverse,
  });

  const valueClasses = cx({
    value: true,
    valueBottom: !reverse,
  });

  const labelProps = {
    size: reverse ? 'body' : 'label',
    color: 'balanced-500',
    emphasis: !!reverse,
    ...label,
  };
  const valueProps = {
    size: reverse ? 'label' : 'body',
    color: row ? 'dark' : 'balanced-500',
    emphasis: !reverse,
    ...value,
  };

  return (
    <div className={classes}>
      {label &&
        (label.badge ? (
          <Badge className={styles.badge} {...label.badge} />
        ) : (
          <Title className={labelClasses} {...labelProps} />
        ))}
      {value &&
        (value.status ? (
          <Status {...value.status} />
        ) : value.badge ? (
          <Badge className={styles.badge} {...value.badge} />
        ) : value.action ? (
          <Button link inline className={styles.subaction} {...value} />
        ) : value.list ? (
          <List className={valueClasses} {...value.list} />
        ) : value.html ? (
          <Purify html={value.html} className={valueClasses} {...valueProps} />
        ) : (
          <Title className={valueClasses} {...valueProps} />
        ))}
      {values && (
        <div className={styles.values}>
          {values.map((item, index) => {
            const valuesProps = {
              size: reverse ? 'label' : 'body',
              color: reverse ? 'balanced-300' : 'balanced-500',
              emphasis: !reverse,
              ...item,
            };
            return item.action ? (
              <Button
                key={index}
                link
                inline
                className={styles.action}
                {...item}
              />
            ) : (
              <Title
                key={index}
                className={!reverse ? styles.valueBottom : ''}
                {...valuesProps}
              />
            );
          })}
        </div>
      )}
      {badges && (
        <div>
          {badges.map((item, i) => (
            <div key={i} className={styles.badges}>
              {item.values.map((badge, index) => (
                <Badge key={index} className={styles.badge} {...badge} />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Detail.propTypes = {
  /** Flip label and value position */
  reverse: PropTypes.bool,
  /** Flip label (badge) and value position */
  reverseBadge: PropTypes.bool,
  /** Make the details horizontal (row) */
  row: PropTypes.bool,
  /** The min-width of the label when the details are horizontal (row) */
  minWidth: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'xxl']),
  /** The max-width of the value when the details are horizontal (row) */
  maxWidth: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'xxl']),
  /** The detail label */
  label: PropTypes.shape(Title.propTypes),
  /** The detail value */
  value: PropTypes.oneOfType([
    PropTypes.shape(Title.propTypes),
    PropTypes.shape(List.propTypes),
    PropTypes.shape({ status: Status.propTypes }),
  ]),
  /** Adds custom styles to the component. Mostly passed when used in another component */
  className: PropTypes.string,
  /** The actual steps */
  values: PropTypes.arrayOf(PropTypes.shape(Title.propTypes)),
  /** The details with badges */
  badges: PropTypes.arrayOf(PropTypes.shape(Badge.propTypes)),
};

Detail.defaultProps = {
  label: { text: '' },
  value: { text: '' },
  className: '',
  reverse: false,
  reverseBadge: false,
  row: false,
};

// Needed for Storybook
Detail.displayName = 'Detail';

export default Detail;
