import React, { forwardRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import styles from './draggable.module.scss';

const DraggableView = forwardRef(
  ({ offset, scale, diagramRef, dragCanvas, nodes }, ref) => {
    const [convertedOffset, setConvertedOffset] = useState({ x: 0, y: 0 });
    const [handleSquare, setHandleSquare] = useState({
      width: '100px',
      height: '100px',
    });
    const [factor, setFactor] = useState(50);

    const handleDrag = (e, node) => {
      dragCanvas(node.x * -factor, node.y * -factor, scale);
    };

    const handleStop = (e, node) => {
      setConvertedOffset({ x: node.x, y: node.y });
    };

    const convertOffset = (val) => {
      const pos = Math.abs(val);
      return pos / factor;
    };

    const convertSize = (val) => ({
      width: diagramRef.current.offsetWidth / factor,
      height: val / factor,
    });

    useEffect(() => {
      setHandleSquare(convertSize(diagramRef.current.clientWidth));
    }, [factor]);

    useEffect(() => {
      setConvertedOffset({
        x: convertOffset(offset.x),
        y: convertOffset(offset.y),
      });
    }, [offset]);

    useEffect(() => {
      setFactor(50 * scale);
    }, [scale]);

    return (
      <div className={styles.draggable}>
        {nodes.map((node, i) => (
          <div
            className={styles.questionCard}
            style={{ left: node.x / 50, top: node.y / 50 }}
          />
        ))}
        <Draggable
          axis='both'
          handle='.handle'
          position={{ x: convertedOffset.x, y: convertedOffset.y }}
          bounds={'parent'}
          grid={[5, 5]}
          scale={1}
          onDrag={handleDrag}
          onStop={handleStop}
        >
          <div className={styles.handle} style={{ ...handleSquare }}>
            <div
              className={'handle'}
              style={{ height: '100%', width: '100%' }}
            ></div>
          </div>
        </Draggable>
      </div>
    );
  }
);

DraggableView.propTypes = {};

export default React.memo(DraggableView);
