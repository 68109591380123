import Rails from '@rails/ujs';

/** ***
 * API Helper
 */
let apiState = null;
const callApi = (url, data = {}, method = 'GET', byPassAbort = false) =>
  new Promise((resolve, reject) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
        'X-Requested-With': 'XMLHttpRequest', // ugly-hack to let rails recognize this as Ajax
        Accept:
          'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
      },
      credentials: 'same-origin',
      timeout: 35000,
    };

    const controller = new AbortController();
    const signal = controller.signal;
    options.signal = signal;
    if (Object.keys(data).length > 0) {
      options.body = JSON.stringify(data);
    }

    if (apiState && !byPassAbort) apiState.abort();
    apiState = controller;

    fetch(url, {
      method,
      ...options,
    })
      .then((response) => {
        if (response.status === 200) {
          apiState = null;
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((e) => {
        if(e.name !== 'AbortError') reject(e)
      });
  });

export default callApi;
