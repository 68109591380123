import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import Divider from '@components/ui/divider';
import Table from '@components/table/table';
import Reader from '@components/form/reader';
import Button from '@components/button/button';
import { Title, Detail } from '@components/text';
import Wrapper from '../wrapper';
import styles from './ruleCard.module.scss';
import callApi from '../../helpers/api';

const cx = classNames.bind(styles);

const RuleCard = ({
  size,
  color,
  header,
  className,
  questionDetails,
  answerDetails,
  title,
  endpoint,
  customDetails,
}) => {
  const [answerDetailsState, setAnswerDetailsState] = useState(answerDetails);
  const [loading, setLoading] = useState('initial');

  const fetchAnswers = async (e) => {
    e.preventDefault();
    setLoading('fetching');
    await callApi(endpoint)
      .then((res) => res.json())
      .then((data) => {
        const { answers } = data;
        const tableHead = [];
        // Its possible more properties are comming in then before, therefore we
        // might need a new table head
        answers.forEach((answer) => {
          if (
            answer.equivalence &&
            !tableHead.some((x) => x.text === 'Equivalence class')
          ) {
            tableHead.push({ text: 'Equivalence class', order: 0 });
          }
          if (answer.name && !tableHead.some((x) => x.text === 'Name')) {
            tableHead.push({ text: 'Name', order: 1 });
          }
          if (answer.label && !tableHead.some((x) => x.text === 'Title')) {
            tableHead.push({ text: 'Title', order: 2 });
          }
          if (answer.subset && !tableHead.some((x) => x.text === 'Subset')) {
            tableHead.push({ text: 'Subset', order: 3 });
          }
        });

        // Again, its possible some options are added, lets filter out
        // the ones that dont excist and render the rest
        const tableBody = answers.map((item) => ({
          data: [
            { text: item.equivalence },
            { text: item.name },
            { text: item.label },
            { text: item.subset },
          ].filter((x) => x.text !== undefined),
        }));
        const newData = {
          table: {
            // Assuming the order of the original state is best, we
            // use the order we mapped before
            head: tableHead.sort((a, b) => a.order - b.order),
            body: tableBody,
          },
        };
        setAnswerDetailsState([answerDetails[0], newData]);
        setLoading('done');
      });
  };
  const lineClass = cx({
    [`line-${size}`]: true,
  });

  const answerWrapper = cx({
    answerwrapper: true,
    loading: loading === 'fetching',
  });

  return (
    <Wrapper size={size} color={color} header={header} className={className}>
      {questionDetails.length > 0 &&
        questionDetails.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider color={'transparent'} height={15} />}
            <Detail
              row
              label={{ color: 'dark' }}
              minWidth={'xxl'}
              classname={styles.detail}
              {...item}
            />
          </div>
        ))}
      {title && <Title className={styles.title} size={'h4'} {...title} />}
      {title && answerDetailsState.length > 0 && (
        <Divider className={lineClass} color={'stable-500'} height={1} />
      )}
      {answerDetailsState.length > 0 && (
        <div className={styles.answers}>
          {answerDetailsState.map((item, index) => (
            <div key={index}>
              {index !== 0 && <Divider color={'transparent'} height={15} />}
              <div className={styles.answer}>
                <Detail
                  row
                  minWidth={'xxl'}
                  classname={styles.detail}
                  {...item}
                />
                {item.table && (
                  <Wrapper color={'stable-300'} className={answerWrapper}>
                    <Table
                      simple
                      sizes={[33, 33, 33]}
                      className={styles.table}
                      head={item.table.head}
                      body={item.table.body}
                      {...item}
                    />
                    {loading === 'initial' && (
                      <>
                        <Divider color={'transparent'} height={15} />
                        <Button
                          link
                          text={'Show more'}
                          action={(e) => fetchAnswers(e)}
                        />
                      </>
                    )}
                  </Wrapper>
                )}
                {item.reader && (
                  // TODO: needs styling like the table in rule card
                  <Reader
                    persisted_content={item.reader.persisted_content}
                    tableOnly={item.reader.tableOnly}
                    {...item}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.customDetails}>
        {customDetails?.map((item, index) => (
          <div key={index} className={styles.customDetail}>
            {item?.title && (
              <Title className={styles.title} size={'h4'} {...item.title} />
            )}
            {item?.details?.map((detail, i) => (
              <Detail key={i} row minWidth={'xxl'} {...detail} />
            ))}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

RuleCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf([...Colors]),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The header of the card */
  header: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  /** The title of the content */
  title: PropTypes.shape(Title.propTypes),
  /** The question details of the card */
  questionDetails: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The answer details of the card */
  answerDetails: PropTypes.arrayOf(PropTypes.shape({})),
  /** Adds a custom class to the card */
  className: PropTypes.string,
  /** Endpoint for retreiving options */
  endpoint: PropTypes.string,
  customDetails: PropTypes.arrayOf(
    PropTypes.shape(Detail.propTypes),
    PropTypes.shape(Title.propTypes)
  ),
};

RuleCard.defaultProps = {
  color: 'light',
  size: 'xl',
  header: false,
  className: '',
  truncateNumber: 1,
  answerDetails: [],
  questionDetails: [],
  title: null,
  endpoint: '',
  customDetails: null,
};

// Needed for Storybook
RuleCard.displayName = 'RuleCard';

export default RuleCard;
