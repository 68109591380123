import React from 'react';
import FlowChart from '.';
import { dummyData } from './dummyData';

export default {
  title: 'BRMS/flowchart/Chart',
  component: FlowChart,
  parameters: {
    componentSubtitle:
      'This component makes use of https://github.com/MrBlenny/react-flow-chart',
  },
};

const Template = () => (
  <FlowChart
    data={JSON.stringify(dummyData)}
    paths={{
      add_rule: '/admin/subsets/19/subset_rules',
      delete_rule: '/admin/subset_rules/-1',
      edit_rule: '/admin/subset_rules/-1/edit',
      new_rule: '/admin/rules/new?subset_id=19',
      save_position: '/admin/subset_rules/-1?subset_id=19',
      offset: '/Jetfuel_doesnt_melt_steel_beams',
      subset: '/Epstein_did_not_kill_himself',
    }}
  />
);

export const Default = Template.bind({});
