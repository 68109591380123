import React, { useState, useEffect } from 'react';
import { toJS } from 'mobx';
import PropTypes, { node } from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import ExportOverlay from './exportOverlay';

const cx = classNames.bind(styles);

const checkEqual = (prev, next) => prev === next;

const CustomCanvas = React.forwardRef(({ config, children }, ref) => {
  const classes = cx({
    diagram: true,
  });

  const scale = (fromRange, toRange) => {
    const d = (toRange[1] - toRange[0]) / (fromRange[1] - fromRange[0]);
    return (from) => (from - fromRange[0]) * d + toRange[0];
  };
  const [zoomState, setZoomState] = useState(0);
  const [offsetState, setOffsetState] = useState({
    x: 0,
    y: 0,
  });

  // useEffect(() => {
  //   setZoomState(scale([0.1, 2], [0, 400])(config.zoom.currentZoom));
  // }, []);

  useEffect(() => {
    setOffsetState({
      x: toJS(config.offset.positionX),
      y: toJS(config.offset.positionY),
    });
  }, [config.offset]);

  return (
    <div
      ref={ref}
      className={classes}
      data-chart
      style={{
        backgroundSize: `${200}px ${200}px`,
        backgroundPosition: `${offsetState.x}px ${offsetState.y}px`,
      }}
    >
      {config?.exporting && config?.subset && (
        <ExportOverlay
          detail={{
            label: {
              markdown: {
                text: config.subset?.title,
              },
            },
            value: {
              text: config.subset?.name,
              color: 'positive',
              size: 'h3',
            },
          }}
        />
      )}
      <div>{children}</div>
    </div>
  );
});

CustomCanvas.propTypes = {
  children: PropTypes.shape({}),
};

CustomCanvas.defaultProps = {
  children: null,
};

export default React.memo(CustomCanvas, checkEqual);
