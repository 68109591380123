import React from 'react';
import { Spacings, Colors as ColorNames } from '@components/variables';
import Pagination from '.';

export default {
  title: 'BRMS/layout/Pagination',
  component: Pagination,
};

const Template = (args) => <Pagination {...args} />;

export const Default = Template.bind({});

Default.args = {
  current: 44,
  max: 100,
  param: 'page',
};

export const Variants = () => (
  <>
    <Pagination {...Default.args} current={1} max={2} />
    <Pagination {...Default.args} current={1} max={4} />
    <Pagination {...Default.args} current={2} max={3} />
    <Pagination {...Default.args} current={2} max={4} />
    <Pagination {...Default.args} max={44} />
    <Pagination {...Default.args} max={44} param={'view'} />
  </>
);
