/* This file is used to transform the backend data to flowchart nodes */

import { toJS } from 'mobx';
/* eslint-disable camelcase */

/*
 ** Start by defining 'global' variables, these are available to all nodes
 ** while looping over the data. Nodes can go horizontally & vertically so
 ** both x & y values need to be tracked
 **
 ** The cumulativeX/Y values will be updated for each node. The startX/Y values
 ** are used to define the starting position of the first node. All other nodes
 ** will be drawn based on this starting position
 */
let cumulativeX = 5;
let startX = cumulativeX;
let cumulativeY = 5;
let cumulativeXAssignment = cumulativeX;
let cumulativeYAssignment = cumulativeY;
let cumulativeXSubsetActivation = cumulativeX;
let cumulativeYSubsetActivation = cumulativeY;
const canvasWidth = 10000;
export const dataToPost = [];

/*
 ** Define some default data to make it easier to access it further on. The
 ** component to render is stored along with the size of the cards (and some
 ** additional space if needed)
 */

export const cardSettings = {
  question: {
    component: 'QuestionCard',
    width: 365 + 50,
    height: 250 + 50,
  },
  assignment: {
    component: 'AssignmentCard',
  },
  activation: {
    component: 'ActivationCard',
  },
  subset_activation: {
    component: 'SubsetCard',
    width: 135 + 50,
    height: 135 + 50,
  },
};

export const nodeTransform = (
  node,
  index,
  connections,
  id,
  hasAlreadyBeenRendered,
  newCardPos
) => {
  /*
   ** The first node is the most important one. This one will decide where all other
   ** nodes are rendered.
   ** The X position of the first node is based on the maximum number of nodes
   ** one the x-axis. This is done by calculating the maximum number of connections
   ** a node has. Those connections are drawn on the same x-axis. The max number
   ** defines how many nodes are drawn next to each other. The first node is drawn
   ** at the half X of the sum of the width of these nodes
   */
  if (index === 0 && Object.keys(connections).length > 0) {
    /* Find the node with the most connections */
    const mostConnectionsKey = Object.keys(connections).reduce((a, b) =>
      connections[a].length > connections[b].length ? a : b
    );
    /* Get the count of the number of connections of above node */
    const connectionCount = connections[mostConnectionsKey].length;
    if (connectionCount > 1) {
      startX =
        cumulativeX + cardSettings.question.width * (connectionCount - 1);
    }
  }

  const mapQuestionNode = (n) => {
    const { id, activation } = n;
    const finalId = `node_${id}`;
    /* Get the connection data of the current node */
    const currentConnection =
      activation && activation.from_ids
        ? connections[`node_${activation?.from_ids[0]}`]
        : [];

    /*
     ** Get the position of the node in its current connection. This is the base
     ** to determine the position of the node on the x-axis
     */
    const connectionIndex =
      activation && activation?.from_ids?.length > 0
        ? currentConnection.findIndex((k) => k === n.id)
        : -1;
    /*
     ** Based on the first node, the current node should be drawn left, right
     ** or straight below the first node
     */
    const diff =
      currentConnection && currentConnection.length > 1
        ? -1 * (currentConnection.length - 1) + connectionIndex
        : 0;
    /* isStart & isEnd should be taken from BE data, not via logic by FE */
    const isStart = !activation || activation?.from_ids?.length === 0;
    const { unreachable } = n;
    const isEnd = false;

    /*
     ** Calculate the final x position of the current node. First check if the x
     ** position is already stored in the back-end data. If it is, it should be
     ** in the node data.
     **
     ** Otherwise check if it is a lower card (so it has connections from other cards)
     ** in that case the x is based on it's poisition on the x-axis. Otherwise the x
     ** is based on startX & the width of the card
     */
    const x = n.x
      ? n.x
      : hasAlreadyBeenRendered.length > 0
      ? newCardPos.x
      : currentConnection && currentConnection.length > 1
      ? cumulativeX - 50 - cardSettings.question.width
      : startX + cardSettings.question.width * connectionIndex - 50;
    /*
     ** Store this position in the cumulative variable, because if there are
     ** more than one node on the x-axis, the next node should take the last known
     ** x position as start for its calculation
     */
    cumulativeX = x;

    /*
     ** The Y is the same for all nodes in a connection. But first we check if the
     ** backend already provides us with a stored Y of the node. Otherwise we use
     ** the last known cumulative value. If the node has an activation condition
     ** we update the cumulative value with the height of the card so the next Y
     ** will take this condition into account
     */
    const y = n.y
      ? n.y
      : hasAlreadyBeenRendered.length > 0
      ? newCardPos.y +
        hasAlreadyBeenRendered.filter((item) => item.id === id)[0].increment *
          200
      : connectionIndex > 0
      ? cumulativeY + 100 * connectionIndex
      : cumulativeY +
        (activation.condition
          ? cardSettings.question.height + 50 * connectionIndex
          : 0);
    /*
     ** Store this position in the cumulative variable, so it can be used in the
     ** other calculations
     */
    cumulativeY = y;

    // Get X + Center of Canvas
    let centerX = x;

    // const postInitialPos = () => {
    if (n.y === null && n.x === null) {
      centerX = x + canvasWidth / 2;
      dataToPost.push({
        x: hasAlreadyBeenRendered.length > 0 ? x : centerX,
        y,
        assignments_attributes: [],
        activations_attributes: [],
        diagram: true,
        id,
        hasNotYetBeenDragged: true,
      });
    }

    /* Define the data structure of the node. This is the data the flowchart expects
     ** extended with our own key/values */
    return {
      id: finalId,
      position: {
        x: hasAlreadyBeenRendered.length > 0 ? x : centerX,
        y,
      },
      component: cardSettings.question.component,
      props: {
        diagram: true,
        start: isStart && unreachable?.length === 0,
        unreachable: unreachable?.length > 0 && unreachable,
        end: isEnd,
        color: isStart
          ? 'positive-100'
          : isEnd
          ? 'energized'
          : unreachable?.length > 0
          ? 'energized'
          : 'light',
        label: n.type
          ? {
              text: n.type,
            }
          : null,
        title: n.title
          ? {
              text: n.title,
            }
          : null,
        content: [
          {
            text: 'Symptoms',
          },
        ],
        buttons: [
          {
            icon: {
              icon: 'trash',
            },
            color: 'assertive',
          },
          {
            icon: {
              icon: 'eye-round',
            },
            color: 'positive',
          },
        ],
        disabled: false,
      },
      ports: {
        [`${finalId}_top`]: {
          id: `${finalId}_top`,
          type: 'top',
          properties: {
            linkColor: 'rgba(var(--color-balanced-500), 1)',
            label:
              activation && activation.condition
                ? {
                    text: activation.condition,
                    simple: activation.simple,
                  }
                : {},
          },
        },
        [`${finalId}_right`]: {
          id: `${finalId}_right`,
          type: 'right',
        },
        [`${finalId}_bottom`]: { id: `${finalId}_bottom`, type: 'bottom' },
      },
    };
  };

  const mapAssignmentNode = (n, i) => {
    const { id, assignments } = n;
    const assignment = assignments[i];
    const finalId = `node_${id}_assignment_${i}`;

    /*
     ** Assignment nodes are always drawn to the right of a question node
     ** therefore, x is equal to the last known x of the question node appended
     ** with the width of the question node itself. (Because x starts at the top-
     ** left corner).
     ** The first assignment node is drawn on the same y position as the question
     ** node. Each additional assignment node is drawn below the last node.
     ** @todo, the hardcoded 120 is the height of the node, but should be added to
     ** the data.assignment variable
     */
    if (i === 0) {
      cumulativeXAssignment = cumulativeX + cardSettings.question.width;
      cumulativeYAssignment = cumulativeY;
    } else {
      cumulativeYAssignment += 120;
    }

    /*
     ** If the assignment position is stored in the back-end, it should be used.
     ** Otherwise the position is based on the last known x position increased with
     ** the width of the node (40 or 220, based on the simple prop in the data) and
     ** an additional spacing of 100
     */
    const x = assignment.x
      ? assignment.x
      : hasAlreadyBeenRendered.length > 0
      ? newCardPos.x
      : cumulativeXAssignment + (assignment.simple ? 40 : 220) + 100;

    /*
     ** If the assignment position is stored in the back-end, it should be used.
     ** Otherwise the position calculated above is used.
     */
    const y = assignment.y
      ? assignment.y
      : hasAlreadyBeenRendered.length > 0
      ? newCardPos.y +
        hasAlreadyBeenRendered.filter((item) => item.id === assignment.id)[0]
          .increment *
          200
      : cumulativeYAssignment;

    /* Store the last known position so the next node can access it */
    cumulativeX = x;

    // Get X + Center of Canvas
    const centerX = x;

    if (assignment.y === null && assignment.x === null) {
      dataToPost.push({
        assignments_attributes: [
          {
            id: assignment.id,
            x: centerX,
            y,
          },
        ],
        diagram: true,
        id,
      });
    }

    /* Define the data structure of the node. This is the data the flowchart expects
     ** extended with our own key/values */
    return {
      id: finalId,
      position: {
        x: centerX,
        y,
      },
      component: cardSettings.assignment.component,
      props: {
        title: {
          text: 'Assignment',
          id: assignment.id,
        },
        assignment_rows: assignment.assignment_rows,
        disabled: false,
      },
      ports: {
        [`${finalId}_left`]: {
          id: `${finalId}_left`,
          type: 'left',
          properties: {
            linkColor: 'rgba(var(--color-balanced-500), 1)',
            dashArray: '5px',
            label: {
              text: assignment.condition,
              simple: assignment.simple,
            },
          },
        },
      },
    };
  };

  const mapSubsetActivationNode = (n, i) => {
    const { id, subset_activations } = n;
    const finalId = `node_${id}_subset_activation_${i}`;
    const subset_activation = subset_activations[i];

    /*
     ** Subset activation nodes are always drawn to the right of a question node
     ** therefore, x is equal to the last known x of the question node appended
     ** with the width of the question node itself. (Because x starts at the top-
     ** left corner).
     ** The first subset activation node is drawn on the same y position as the question
     ** node. Each additional subset activation node is drawn below the last node.
     */
    if (i === 0) {
      cumulativeXSubsetActivation = cumulativeX + cardSettings.question.width;
      cumulativeYSubsetActivation = cumulativeY;
    } else {
      cumulativeYSubsetActivation += cardSettings.subset_activation.height;
    }
    /*
     ** If the subset activation position is stored in the back-end, it should be used.
     ** Otherwise the position is based on the last known x position increased with
     ** the width of the node (40 or 220, based on the simple prop in the data) and
     ** an additional spacing of 130
     */
    const x = subset_activation.x
      ? subset_activation.x
      : hasAlreadyBeenRendered.length > 0
      ? newCardPos.x
      : cumulativeXSubsetActivation +
        (subset_activation.simple ? 40 : 220) +
        100;

    /*
     ** If the subset activation position is stored in the back-end, it should be used.
     ** Otherwise the position calculated above is used.
     */
    const y = subset_activation.y
      ? subset_activation.y
      : hasAlreadyBeenRendered.length > 0
      ? newCardPos.y +
        hasAlreadyBeenRendered.filter(
          (item) => item.id === subset_activation.id
        )[0].increment *
          200
      : cumulativeYSubsetActivation;

    cumulativeX = x;
    cumulativeY = y;

    // Get X + Center of Canvas
    const centerX = x;

    if (subset_activation.y === null && subset_activation.x === null) {
      dataToPost.push({
        activations_attributes: [
          {
            id: subset_activation.id,
            x: centerX,
            y,
          },
        ],
        diagram: true,
        id,
      });
    }

    /* Define the data structure of the node. This is the data the flowchart expects
     ** extended with our own key/values */
    return {
      id: finalId,
      position: {
        x: centerX,
        y,
      },
      component: cardSettings.subset_activation.component,
      props: {
        title: {
          text: subset_activation?.subset?.name,
          id: subset_activation?.subset?.id,
        },
      },
      ports: {
        [`${finalId}_left`]: {
          id: `${finalId}_left`,
          type: 'left',
          properties: {
            linkColor: 'rgba(var(--color-balanced-500), 1)',
            dashArray: '5px',
            label:
              subset_activation && subset_activation.condition
                ? {
                    text: subset_activation.condition,
                    simple: subset_activation.simple,
                  }
                : {},
          },
        },
      },
    };
  };

  const nodes = {};

  const jsNode = toJS(node);
  /* Transform all question nodes */
  const qNode = mapQuestionNode(jsNode);
  nodes[qNode.id] = qNode;

  /* Transform all assignment nodes */
  if (jsNode.assignments && jsNode.assignments.length > 0) {
    jsNode.assignments.forEach((n, i) => {
      const asNode = mapAssignmentNode(jsNode, i);
      nodes[asNode.id] = asNode;
    });
  }
  /* Transform all subset_activations nodes */
  if (jsNode.subset_activations && jsNode.subset_activations.length > 0) {
    jsNode.subset_activations.forEach((n, i) => {
      const sNode = mapSubsetActivationNode(jsNode, i);
      nodes[sNode.id] = sNode;
    });
  }
  return nodes;
};

export const linkTransform = (nodes) => {
  const link = {};
  const filteredIds = toJS(nodes).map((object) => object.id);
  let count = 0;
  nodes.map((x, i) => {
    if (x.activation.from_ids) {
      toJS(x.activation.from_ids).map((n, k) => {
        if (!filteredIds.includes(n)) return;
        count++;
        link[`link_${count}`] = {
          id: `link_${count}`,
          from: {
            nodeId: `node_${n}`,
            portId: `node_${n}_bottom`,
          },
          to: {
            nodeId: `node_${x.id}`,
            portId: `node_${x.id}_top`,
          },
          condition:
            !x.activation.simple && x.activation.condition
              ? x.activation.condition
              : null,
        };
      });
    }
    if (x.assignments) {
      toJS(x.assignments).map((n, k) => {
        count++;
        link[`link_${count}`] = {
          id: `link_${count}`,
          from: {
            nodeId: `node_${x.id}`,
            portId: `node_${x.id}_right`,
          },
          to: {
            nodeId: `node_${x.id}_assignment_${k}`,
            portId: `node_${x.id}_assignment_${k}_left`,
          },
          condition: !n.simple && n.condition ? n.condition : null,
        };
      });
    }
    if (x.subset_activations) {
      toJS(x.subset_activations).map((n, k) => {
        count++;
        link[`link_${count}`] = {
          id: `link_${count}`,
          from: {
            nodeId: `node_${x.id}`,
            portId: `node_${x.id}_right`,
          },
          to: {
            nodeId: `node_${x.id}_subset_activation_${k}`,
            portId: `node_${x.id}_subset_activation_${k}_left`,
          },
          condition: !n.simple && n.condition ? n.condition : null,
        };
      });
    }
  });
  return link;
};
