/** ***
 * API Helper
 */
const callApi = (url, data = {}, method = 'GET') =>
  new Promise((resolve, reject) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Accept:
          'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
      },
      credentials: 'same-origin',
      timeout: 35000,
    };
    if (data.signal) {
      options.signal = data.signal;
      delete data.signal;
    }

    if (Object.keys(data).length > 0) {
      options.body = JSON.stringify(data);
    }

    fetch(url, {
      method,
      ...options,
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((e) => reject(e));
  });

export default callApi;
