import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '@components/button/button';
import IconLeft from '@components/button/icon-left';
import IconRight from '@components/button/icon-right';
import Badge from '@components/badge/badge';
import Title from '@components/text/title';
import Divider from '@components/ui/divider';
import Tooltip from '@components/ui/tooltip/base';
import Icon from '@components/ui/icon';
import Purify from '@components/ui/purify';
import styles from './element.module.scss';
import Simple from '../simple';
import Textarea from '../textarea';
import Group from '../group';
import Option from '../option';
import Datepicker from '../datepicker';
import Conversion from '../conversion';
import Select from '../select';
import AsynchronousSelect from '../asynchronousSelect';
import File from '../file';
import RadioSelect from '../radioSelect';
import CheckGroup from '../checkGroup';
import Toggle from '../toggle';
import SwitchElement from '../switchElement';
import Reading from '../reading';

const cx = classNames.bind(styles);

const Element = ({
  inline,
  inlineReverse,
  background,
  label,
  htmlInLabel,
  flat,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState({});
  const classes = cx(
    {
      element: true,
      inline,
      inlineReverse,
      background,
      error: props.errors && props.errors.length > 0,
      column: props.column,
      remove: props.remove,
      removeSwitchElement: props.remove && props.type === 'switch-element',
    },
    props.className
  );
  const inputClasses = cx({
    flat,
    input: ![
      'checkbox',
      'radio',
      'button',
      'submit',
      'file',
      'check-group',
      'switch-element',
      'reading',
    ].includes(props.type),
    inputFont: true,
  });
  const extraProps = {};
  const identifier = props.identifier
    ? props.identifier
    : Math.random().toString(36).substr(2, 9);
  const InputElement = ['button', 'submit'].includes(props.type)
    ? props.iconRight
      ? IconRight
      : props.icon || props.iconLeft
      ? IconLeft
      : Button
    : ['datepicker'].includes(props.type)
    ? Datepicker
    : ['select', 'multi-select'].includes(props.type)
    ? Select
    : ['check-group'].includes(props.type)
    ? CheckGroup
    : ['asynchronous-select'].includes(props.type)
    ? AsynchronousSelect
    : ['radio', 'checkbox'].includes(props.type)
    ? props.options
      ? Group
      : Option
    : props.options && props.button
    ? RadioSelect
    : props.options && ['radio-select'].includes(props.type)
    ? RadioSelect
    : ['textarea'].includes(props.type)
    ? Textarea
    : ['toggle'].includes(props.type)
    ? Toggle
    : ['file'].includes(props.type)
    ? File
    : ['switch-element'].includes(props.type)
    ? SwitchElement
    : ['reading'].includes(props.type)
    ? Reading
    : ['length', 'weight', 'amount_of_substance'].includes(props.quantity)
    ? Conversion
    : Simple;
  if (['button', 'submit'].includes(props.type)) {
    extraProps.color = 'positive-300';
    extraProps.size = 'l';
    extraProps.icon = props.iconRight || props.iconLeft || props.icon;
  }
  if (['length', 'weight', 'amount_of_substance'].includes(props.quantity)) {
    extraProps.errorValues = (e) => setErrorMessage(e);
  }
  if (['datepicker'].includes(props.type)) {
    extraProps.color = 'positive';
  }
  if (['select'].includes(props.type)) {
    extraProps.className = 'inputSelect';
  }
  if (['multi-select'].includes(props.type)) {
    extraProps.multi = true;
  }

  const _customDataToAttr = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value]));
  const attributes = props.customData
    ? _customDataToAttr(props.customData)
    : [];
  return (
    <div className={classes}>
      {(label || props.errors) && (
        <label htmlFor={identifier} className={styles.label}>
          {label && !htmlInLabel && (
            <Title
              text={label}
              size={'label'}
              inline
              color={
                props.errors && props.errors.length > 0
                  ? 'assertive'
                  : 'balanced-500'
              }
            />
          )}

          {label && htmlInLabel && (
            <Purify
              className={styles.htmlInLabel}
              html={label}
              color={
                props.errors && props.errors.length > 0
                  ? 'assertive'
                  : 'balanced-500'
              }
            />
          )}
          {props.required && (
            <Title
              text={'*'}
              size={'label'}
              inline
              color={
                props.errors && props.errors.length > 0
                  ? 'assertive'
                  : 'balanced-500'
              }
            />
          )}
          {props.tooltip && <Divider width={5} color={'transparent'} />}
          {props.tooltip && (
            <Tooltip trigger={'mouseenter'} simple {...props.tooltip}>
              <Icon icon='info' color='positive' />
            </Tooltip>
          )}
          {props.widgetLabelAction && (
            <Divider width={10} color={'transparent'} />
          )}
          {props.widgetLabelAction && <Button {...props.widgetLabelAction} />}
          {props.errors &&
            props.errors.length > 0 &&
            props.errors.map((error, index) => (
              <Badge
                key={index}
                color={'assertive'}
                size={'s'}
                className={styles.badge}
                content={
                  typeof error === 'object'
                    ? error
                    : {
                        text:
                          props.quantity !== '' &&
                          props.quantity !== null &&
                          props.quantity?.length !== 0 &&
                          (props.min || props.max)
                            ? error
                                .replace(props.min, errorMessage.convertedMin)
                                .replace(props.max, errorMessage.convertedMax)
                            : error,
                      }
                }
              />
            ))}
          {props.labelAction && (
            <a
              href={props.labelAction.action}
              className={styles.labelAction}
              tabIndex={2}
            >
              <Title
                text={props.labelAction.text}
                color={'positive'}
                size={'label'}
              />
            </a>
          )}
        </label>
      )}
      {props.children && props.children}
      {label && !background && (
        <Divider
          width={inline ? 15 : 0}
          height={inline ? 0 : 5}
          color={'transparent'}
        />
      )}
      {props.remove ? (
        <div className={styles.removeWrapper}>
          <InputElement
            id={identifier}
            className={inputClasses}
            {...extraProps}
            {...props}
            attributes={attributes}
          />
          <Button
            outline
            color={'stable-500'}
            icon={{ icon: 'close', color: 'balanced-500' }}
            className={styles.removeButton}
            {...props.remove}
          />
        </div>
      ) : (
        <InputElement
          id={identifier}
          className={inputClasses}
          {...extraProps}
          {...props}
          attributes={attributes}
        />
      )}
    </div>
  );
};

Element.propTypes = {
  /** The input type */
  type: PropTypes.oneOf([
    'text',
    'password',
    'tel',
    'email',
    'url',
    'textarea',
    'datepicker',
    'select',
    'multi-select',
    'checkbox',
    'radio',
    'button',
    'toggle',
    'submit',
    'file',
    'hidden',
    'number',
    'asynchronous-select',
    'check-group',
    'switch-element',
    'reading',
  ]).isRequired,
  /** The name of the input */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The value of the input */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  /** True when the label should be placed before the input */
  inline: PropTypes.bool,
  /** True when the label should be placed before the input */
  background: PropTypes.bool,
  /** The label text */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** The placeholder text */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** Whether the input is required */
  required: PropTypes.bool,
  /** Whether the group is a column */
  column: PropTypes.bool,
  /** Whether the input has a remove button */
  remove: PropTypes.shape(Button.propTypes),
  /** Whether the label contains HTML */
  htmlInLabel: PropTypes.bool,
  /** Disable shadow underneath field */
  flat: PropTypes.bool,
};

Element.defaultProps = {
  type: 'text',
  name: 'default',
  htmlInLabel: false,
  flat: false,
  label: false,
};

// Needed for Storybook
Element.displayName = 'Element';

export default Element;
