import React from 'react';
import { Divider } from '@components/ui';
import Element from '.';

export default {
  title: 'BRMS/form/Element',
  component: Element,
};

const Template = (args) => <Element {...args} />;

export const Default = Template.bind({});

Default.args = {};

export const Variants = () => (
  <>
    <Element
      label={'This is a length conversion'}
      quantity={'length'}
      min={10}
      max={100}
      onChange={(value) => {
        console.log(value);
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a prefilled length conversion'}
      quantity={'length'}
      value={2000}
      onChange={(value) => {
        console.log(value);
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a weight conversion'}
      quantity={'weight'}
      onChange={(value) => {
        console.log(value);
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a prefilled weight conversion'}
      quantity={'weight'}
      value={2000}
      onChange={(value) => {
        console.log(value);
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a glucose conversion'}
      quantity={'amount_of_substance'}
      onChange={(value) => {
        console.log(value);
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a prefilled glucose conversion'}
      quantity={'amount_of_substance'}
      value={2.2}
      onChange={(value) => {
        console.log(value);
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'This is a label'} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is a label with a tooltip'}
      tooltip={{ content: { text: 'I am a Tooltip' } }}
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'This is an inline label'} inline />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'ID'}
      placeholder={'This is an inline label with background'}
      inline
      background
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'Number'} type={'number'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Password'} type={'password'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'E-mail'} type={'email'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Phone'} type={'tel'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Disabled'} disabled />
    <Divider height={20} color={'transparent'} />
    <Element label={'Readonly'} readonly />
    <Divider height={20} color={'transparent'} />
    <Element label={'_Markdown_'} markdown />
    <Divider height={20} color={'transparent'} />
    <Element remove={{ action: '#' }} label={'This is an input with remove'} />
    <Divider height={20} color={'transparent'} />
    <Element label={'Textarea'} type={'textarea'} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Textarea'}
      type={'textarea'}
      errors={[{ text: 'can`t be blank' }]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Textarea'}
      type={'textarea'}
      errors={[{ text: 'can`t be blank' }, { text: 'second error' }]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Select'}
      type={'select'}
      options={[
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Select'}
      type={'AjaxSelect'}
      options={[
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      remove={{ action: '#' }}
      type={'select'}
      label={'Select with remove'}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'multi-select'}
      label={'Multi select'}
      options={[
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'multi-select'}
      label={'Multi select with checked options'}
      options={[
        { value: 'chocolate', label: 'Chocolate', checked: true },
        { value: 'strawberry', label: 'Strawberry', checked: true },
        { value: 'vanilla', label: 'Vanilla', checked: true },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'multi-select'}
      label={'Multi select with checked and fixed options'}
      options={[
        {
          value: 'chocolate',
          label: 'Chocolate',
          checked: true,
          isFixed: true,
        },
        { value: 'strawberry', label: 'Strawberry', checked: true },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'multi-select'}
      label={
        'Multi select with checked, fixed, disabled and visible options menu'
      }
      hideSelectedOptions={false}
      options={[
        {
          value: 'chocolate',
          label: 'Chocolate',
          checked: true,
          isFixed: true,
          isDisabled: true,
        },
        {
          value: 'strawberry',
          label: 'Strawberry',
          checked: true,
          isFixed: true,
          isDisabled: true,
        },
        {
          value: 'vanilla',
          label: 'Vanilla',
          checked: true,
          isFixed: true,
          isDisabled: true,
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Checkbox'}
      type={'checkbox'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Radio'}
      type={'radio'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'radio'}
      button
      label={'Radio select'}
      options={[
        {
          checked: true,
          label: 'Value',
          name: 'same',
          value: 'value',
        },
        {
          checked: false,
          label: 'Range',
          name: 'same',
          value: 'range',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Activation condition'}
      type={'radio'}
      column
      options={[
        {
          checked: false,
          name: 'name',
          value: 'value',
          fields: [
            {
              label: 'if what_illness == broken_bones',
              status: 'Current',
            },
          ],
        },
        {
          checked: false,
          fields: [
            {
              label: 'if what_illness == broken_bones',
              status: 'Update',
            },
            {
              label: '&& what_illness == Bruised_bones',
            },
          ],
          name: 'name',
          value: 'value',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Assignments'}
      type={'radio'}
      column
      options={[
        {
          checked: false,
          name: 'name',
          value: 'value',
          assignment: true,
          fields: [
            {
              label: 'if answer is ill_01 then penalty +1',
              status: 'Current',
            },
            {
              label: 'If answer is ill_02 then penalty +2',
            },
          ],
        },
        {
          checked: false,
          assignment: true,
          fields: [
            {
              label: 'If answer is ill_01 then penalty +1',
              status: 'Update',
            },
          ],
          name: 'name',
          value: 'value',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'CheckGroup'}
      type={'check-group'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
      select={{
        type: 'select',
        name: 'selectName',
        id: '#select1',
        options: [
          {
            checked: false,
            label: 'Male',
            name: 'claim[gender]',
            value: 'male',
          },
          {
            checked: true,
            label: 'X',
            name: 'claim[gender]',
            value: 'x',
          },
          {
            checked: false,
            label: 'Helicopter',
            name: 'claim[gender]',
            value: 'helicopter',
          },
        ],
      }}
      button={{
        text: 'Other choice?',
      }}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Toggle'}
      type={'toggle'}
      options={[{ text: 'Option 1' }, { text: 'Option 2' }]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'switch-element'}
      modal={{
        id: 'SwitchElementModal',
        elementTrigger: {
          content: {
            text: 'SwitchElement',
          },
          button: {
            icon: {
              icon: 'edit',
            },
          },
          input: {
            id: 'switchElementInput',
            name: 'input',
          },
        },
        header: {
          title: {
            text: 'Modal Wrapper',
          },
        },
        footer: {
          buttonsRight: [
            {
              text: 'Save changes',
            },
          ],
        },
      }}
      options={[
        { text: 'I know when it was' },
        { text: 'I know how old I was' },
        { text: 'I am not sure' },
      ]}
      elements={[
        {
          label: 'Select a date',
          type: 'datepicker',
          name: 'datepicker',
          id: 'datepicker#1',
        },
        {
          label: 'Select one of the options',
          type: 'select',
          name: 'select',
          id: 'select',
          options: [
            {
              value: 'option_1',
              label: 'Option 1',
              checked: true,
            },
            {
              value: 'option_2',
              label: 'Option 2',
            },
          ],
        },
        {
          label: 'Select one of the options',
          type: 'radio',
          name: 'radio',
          column: true,
          options: [
            {
              text: 'Option 1',
              name: 'option[name]',
              value: 0,
              checked: true,
            },
            {
              text: 'Option 2',
              name: 'option[name]',
              value: 1,
            },
            {
              text: 'Option 3',
              name: 'option[name]',
              value: 2,
            },
          ],
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'switch-element'}
      remove={{ action: '#' }}
      modal={{
        id: 'SwitchElementModal',
        elementTrigger: {
          content: {
            text: 'SwitchElement with remove',
          },
          button: {
            icon: {
              icon: 'edit',
            },
          },
          input: {
            id: 'switchElementInput',
            name: 'input',
          },
        },
        header: {
          title: {
            text: 'Modal Wrapper',
          },
        },
        footer: {
          buttonsRight: [
            {
              text: 'Save changes',
            },
          ],
        },
      }}
      options={[
        { text: 'I know when it was' },
        { text: 'I know how old I was' },
        { text: 'I am not sure' },
      ]}
      elements={[
        {
          label: 'Select a date',
          type: 'datepicker',
          name: 'datepicker',
          id: 'datepicker#1',
        },
        {
          label: 'Select one of the options',
          type: 'select',
          name: 'select',
          id: 'select',
          options: [
            {
              value: 'option_1',
              label: 'Option 1',
              checked: true,
            },
            {
              value: 'option_2',
              label: 'Option 2',
            },
          ],
        },
        {
          label: 'Select one of the options',
          type: 'radio',
          name: 'radio',
          column: true,
          options: [
            {
              text: 'Option 1',
              name: 'option[name]',
              value: 0,
              checked: true,
            },
            {
              text: 'Option 2',
              name: 'option[name]',
              value: 1,
            },
            {
              text: 'Option 3',
              name: 'option[name]',
              value: 2,
            },
          ],
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <Element
      type={'reading'}
      label={'Reading'}
      inputLabel={{
        text: 'How many?',
      }}
      group={{
        options: [
          {
            checked: false,
            label: '1',
            name: 'name[defaultV]',
            value: '1',
          },
          {
            checked: false,
            label: '2',
            name: 'name[defaultV]',
            value: '2',
          },
          {
            checked: false,
            label: '3',
            name: 'name[defaultV]',
            value: '3',
          },
        ],
      }}
      title={{
        text: 'Reading',
      }}
      value={{
        rule_struct: true,
        readings: {
          Diastolic: [10, 20],
          Systolic: [30, 40],
        },
        aggregated: {
          Diastolic: null,
          Systolic: null,
        },
        aggregation_method: {
          Diastolic: 'min',
          Systolic: 'avg',
        },
      }}
      newCard={{
        title: {
          text: 'Reading',
        },
        variables: [
          [
            {
              label: {
                text: 'Diastolic',
              },
              input: {
                value: 'Hi1',
              },
            },
            {
              label: {
                text: 'Systolic',
              },
              input: {
                value: 'Hi',
              },
            },
          ],
        ],
      }}
      submit={{
        text: 'Submit',
      }}
      onChange={(e) => console.log(e)}
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'Datepicker'} type={'datepicker'} />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Checkbox with htmlInValue'}
      type={'checkbox'}
      htmlInValue
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
    />
  </>
);

export const Prefix_And_Suffix = () => (
  <>
    <Element
      label={'€'}
      placeholder={'This is an prefix label with background'}
      inline
      background
      flat
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'%'}
      placeholder={'This is a suffix label with background'}
      inlineReverse
      background
      flat
    />
  </>
);
export const Placeholder = (args) => (
  <>
    <Element label={'This is a label'} placeholder='label' />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'This is an inline label'}
      inline
      placeholder='inline label'
    />
    <Divider height={20} color={'transparent'} />
    <Element label={'E-mail'} type={'email'} placeholder='email' />
    <Divider height={20} color={'transparent'} />
    <Element label={'Phone'} type={'tel'} placeholder='phone number' />
    <Divider height={20} color={'transparent'} />
    <Element label={'Disabled'} disabled placeholder='disabled' />
    <Divider height={20} color={'transparent'} />
    <Element label={'Readonly'} readonly />
    <Divider height={20} color={'transparent'} />
    <Element label={'Textarea'} type={'textarea'} placeholder='textarea' />
    {/* <Divider height={20} color={'transparent'} />
    <Element label={'Datepicker'} type={'datepicker'} /> */}
  </>
);

export const LabelActions = () => (
  <>
    <Element
      label={'Checkbox'}
      type={'checkbox'}
      options={[
        {
          checked: false,
          label: 'Male',
          name: 'claim[gender]',
          value: 'male',
          labelAction: {
            size: 'xs',
            text: '(Continue answering)',
            action: '#',
            link: true,
            color: 'balanced-500',
          },
        },
        {
          checked: false,
          label: 'Female',
          name: 'claim[gender]',
          value: 'female',
          labelAction: {
            size: 'xs',
            text: '(Continue answering)',
            action: '#',
            link: true,
            color: 'balanced-500',
          },
        },
        {
          checked: false,
          label: 'Other',
          name: 'claim[gender]',
          value: 'other',
        },
      ]}
      column
    />
    <Divider height={20} color={'transparent'} />
    <Element
      label={'Radio'}
      type={'radio'}
      options={[
        {
          checked: false,
          label: 'Yes',
          name: 'active',
          value: '1',
          labelAction: {
            size: 'xs',
            text: '(Continue answering)',
            action: '#',
            link: true,
            color: 'balanced-500',
          },
        },
        {
          checked: false,
          label: 'No',
          name: 'active',
          value: '0',
        },
      ]}
      column
    />
  </>
);
