import React, { useEffect, useState } from "react";

const Icon = ({ icon, ...otherProps}) => {

  const [iconModule, setIconModule] = useState(null);

  useEffect(() => {
    import(`../../../../../assets/images/icons/${icon}.svg`)
      .then((module) => {
        setIconModule(module);
      })
      .catch((error) => {
        console.error(`Icon with name: ${icon} not found!`);
      });
  }, [icon]);

  const renderIcon = () => {
    if (!iconModule) return null;

    const Component = iconModule.default;

    return <Component {...otherProps} />;
  };

  return <>{renderIcon()}</>;
}

export default Icon;