
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    pattern: { type: String, default: '{{(.*?)}}' },
    color: { type: String, default: 'energized-700' }
  }

  connect() {
    super.connect();

    // Use the highlightPatternValue here
    const pattern = new RegExp(this.patternValue, 'g');

    this.element.innerHTML = this.element.innerHTML.replace(pattern, (match) => {
      return `<span style="color: rgb(var(--color-${this.colorValue}));">${match}</span>`;
    });
  }
}
