import React, { forwardRef, useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import Content from '@components/text/content';
import styles from './thumbnail.module.scss';

const cx = classNames.bind(styles);

const Thumbnail = forwardRef(({ sendValues, clearThumb, diagramRef }, ref) => {
  const [startPos, setStartPos] = useState({ left: 0, top: 0 });
  const [movingMouse, setMovingMouse] = useState({ width: 0, height: 0 });
  const classes = cx(styles.thumbnail);
  let isActive = false;
  let internalState = {};
  let movingState = {};

  const clickFunction = (e) => {
    internalState = { left: e.layerX, top: e.layerY };
    setStartPos({
      left: e.layerX || 0,
      top: e.layerY || 0,
      mutatedLeft: e.clientX || 0,
      mutatedTop: e.clientY || 0,
    });
    setMovingMouse({
      width: 0,
      height: 0,
    });
    isActive && finalizeSnap();
    isActive = !isActive;
  };

  const moveFunction = (e) => {
    setMovingMouse({
      width: e.layerX - internalState.left,
      height: e.layerY - internalState.top,
    });
    movingState = {
      width: e.layerX - internalState.left,
      height: e.layerY - internalState.top,
    };
  };
  useEffect(() => {
    if (diagramRef) {
      diagramRef.current.addEventListener('click', (e) => clickFunction(e));
      diagramRef.current.addEventListener('mousemove', (e) => moveFunction(e));
    }
  }, [diagramRef]);

  const finalizeSnap = () => {
    clearThumb();
  };

  return (
    <div style={{ ...startPos, ...movingMouse }} className={classes}></div>
  );
});

Thumbnail.propTypes = {};

export default React.memo(Thumbnail);
