import React from 'react';
import ElementValues from '.';

export default {
  title: 'BRMS/layout/testing/form/ElementValues',
  component: ElementValues,
};

const Template = (args) => <ElementValues {...args} />;

export const Default = Template.bind({});

Default.args = {
  minMaxLabels: {
    date: {
      min: 'Minimum date',
      max: 'Maxium date',
    },
    text: {
      min: 'Minimum amount of characters',
      max: 'Maximum amount of characters',
    },
    checkbox: {
      min: 'Minimum amount of answers to select',
      max: 'Maximum amount of answers to select',
    },
    select: {
      min: 'Minimum amount of answers to select',
      max: 'Maximum amount of answers to select',
    },
    radio: {
      min: 'Minimum amount of answers to select',
      max: 'Maximum amount of answers to select',
    },
  },
  assignableOptions: [
    {
      type: 'text',
      value: 0,
      label: 'Text',
      checked: true,
    },
    {
      type: 'date',
      value: 1,
      label: 'Date',
    },
    {
      type: 'select',
      value: 2,
      label: 'fruits',
    },
    {
      type: 'select',
      value: 3,
      label: 'shapes',
    },
    {
      type: 'radio',
      value: 4,
      label: 'boolean',
    },
    {
      type: 'multi-select',
      value: 5,
      label: 'Multi select',
    },
    {
      type: 'switch-element',
      value: 6,
      label: 'Date approximation',
    },
  ],
  assignableData: {
    2: {
      min: 1,
      max: 3,
      options: [
        {
          label: 'Apple',
          value: 1,
        },
        {
          label: 'Banana',
          value: 2,
        },
      ],
    },
    3: {
      min: 2,
      max: 4,
      options: [
        {
          label: 'Triangle',
          value: 1,
        },
        {
          label: 'Square',
          value: 2,
        },
      ],
    },
    4: {
      min: 2,
      max: 4,
      options: [
        {
          label: 'Yes',
          value: 1,
        },
        {
          label: 'No',
          value: 2,
        },
      ],
    },
    5: {
      min: 2,
      max: 4,
      options: [
        {
          label: 'Yes',
          value: 1,
        },
        {
          label: 'No',
          value: 2,
        },
        {
          label: 'long string',
          value: 3,
        },
        {
          label: 'one more',
          value: 4,
        },
        {
          label: 'lorem ipsum lorem ipsum abacadabra',
          value: 5,
        },
        {
          label: 'lorem ipsum lorem ipsum abacadabra',
          value: 6,
        },
      ],
    },
    6: {
      options: [
        { text: 'I know when it was' },
        { text: 'I know how old I was' },
        { text: 'I am not sure' },
      ],
    },
  },
  select: {
    type: 'select',
    name: 'select',
    label: 'Rule/Variable',
  },
  radioSelectName: 'kind',
  elementGroup: {
    label: {
      text: 'Values',
    },
    button: true,
    elements: [
      {
        name: 'name[]',
        type: 'switch-element',
        initialDate: '2020-05-20',
        modal: {
          id: 'SwitchElementModal',
          elementTrigger: {
            button: {
              icon: {
                icon: 'edit',
              },
            },
            input: {
              id: 'switchElementInput',
              name: 'input',
            },
          },
          header: {
            title: {
              text: 'Modal Wrapper',
            },
          },
          footer: {
            buttonsRight: [
              {
                text: 'Save changes',
                action: 'save',
              },
            ],
          },
        },
        elements: [
          {
            label: 'Select a date',
            type: 'datepicker',
            name: 'datepicker',
            id: 'datepicker#1',
          },
          {
            label: 'Select one of the options',
            type: 'select',
            name: 'select',
            id: 'select',
            options: [
              {
                value: 'option_1',
                label: 'Option 1',
                checked: true,
              },
              {
                value: 'option_2',
                label: 'Option 2',
              },
            ],
          },
          {
            label: 'Select one of the options',
            type: 'radio',
            name: 'radio',
            column: true,
            options: [
              {
                text: 'Option 1',
                name: 'option[name]',
                value: 0,
                checked: true,
              },
              {
                text: 'Option 2',
                name: 'option[name]',
                value: 1,
              },
              {
                text: 'Option 3',
                name: 'option[name]',
                value: 2,
              },
            ],
          },
        ],
      },
    ],
    newElement: {
      name: 'name[]',
      type: 'text',
    },
    newSwitchElement: {
      initialDate: '2020-05-20',
      modal: {
        id: 'SwitchElementModal',
        elementTrigger: {
          button: {
            icon: {
              icon: 'edit',
            },
          },
          input: {
            id: 'switchElementInput',
            name: 'input',
          },
        },
        header: {
          title: {
            text: 'Modal Wrapper',
          },
        },
        footer: {
          buttonsRight: [
            {
              text: 'Save changes',
              action: 'save',
            },
          ],
        },
      },
      options: [
        { text: 'I know when it was' },
        { text: 'I know how old I was' },
        { text: 'I am not sure' },
      ],
      elements: [
        {
          label: 'Select a date',
          type: 'datepicker',
          name: 'datepicker',
          id: 'datepicker#1',
        },
        {
          label: 'Select one of the options',
          type: 'select',
          name: 'select',
          id: 'select',
          options: [
            {
              value: 'option_1',
              label: 'Option 1',
              checked: true,
            },
            {
              value: 'option_2',
              label: 'Option 2',
            },
          ],
        },
        {
          label: 'Select one of the options',
          type: 'radio',
          name: 'radio',
          column: true,
          options: [
            {
              text: 'Option 1',
              name: 'option[name]',
              value: 0,
              checked: true,
            },
            {
              text: 'Option 2',
              name: 'option[name]',
              value: 1,
            },
            {
              text: 'Option 3',
              name: 'option[name]',
              value: 2,
            },
          ],
        },
      ],
    },
  },
};
