import React, { useEffect, useState } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import classNames from 'classnames/bind';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Detail from '@components/text/detail';
import Button from '@components/button/button';
import ConfigurationCard from '@components/card/configurationCard';
import Divider from '@components/ui/divider';
import Tooltip from '@components/ui/tooltip';
import ModalWrapper from '../modalWrapper';
import styles from './running.module.scss';

const cx = classNames.bind(styles);

const RunningModal = ({
  className,
  header,
  footer,
  details,
  accordion,
  isOpen,
  progress,
}) => {
  const [collapsed, setCollapsed] = useState(0);
  const classes = cx(
    {
      running: true,
    },
    className
  );

  const handleClick = (index) => {
    setCollapsed(index);
  };

  return (
    <div className={classes}>
      <ModalWrapper
        progress={progress}
        header={header}
        footer={footer}
        isOpen={isOpen}
        className={styles.modal}
      >
        {details && (
          <div className={styles.details}>
            {details.map((item, index) => (
              <Detail key={index} {...item} />
            ))}
          </div>
        )}
        {details && accordion && <Divider height={25} color={'transparent'} />}
        {accordion && (
          <div className={styles.accordion}>
            {accordion.map((item, index) => (
              <div key={index} className={styles.item}>
                <div className={styles.itemHeading}>
                  {item.title && <Title size={'h4'} {...item.title} />}
                  <Button
                    link
                    action={() => handleClick(index)}
                    icon={{
                      icon: 'chevron-up',
                      color: 'dark',
                    }}
                    style={{
                      transform: collapsed === index ? '' : 'rotate(180deg)',
                    }}
                  />
                </div>
                {collapsed === index && (
                  <>
                    {item.settings && (
                      <div className={styles.settings}>
                        {item.settings.map((setting, index) => (
                          <React.Fragment key={index}>
                            {index !== 0 && (
                              <Divider height={15} color={'transparent'} />
                            )}
                            {setting.content && (
                              <Content
                                color={'dark'}
                                size={'s'}
                                {...setting.content}
                              />
                            )}
                            {setting.details?.map((detail, index) => (
                              <React.Fragment key={index}>
                                {detail.tooltip && detail.detail ? (
                                  <Tooltip
                                    position={'left-start'}
                                    simple
                                    {...detail.tooltip}
                                  >
                                    <Detail 
                                      size={'xs'}
                                      minWidth={'xl'}                                               
                                      {...detail.detail} 
                                    />
                                  </Tooltip>
                                ) : (
                                  <Detail 
                                    size={'xs'}
                                    minWidth={'xl'}   
                                    {...detail.detail} 
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    {item.cards && (
                      <div className={styles.cards}>
                        {item.cards.map((card, index) => (
                          <React.Fragment key={index}>
                            {index !== 0 && (
                              <Divider height={10} color={'transparent'} />
                            )}
                            <ConfigurationCard {...card} />
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </ModalWrapper>
    </div>
  );
};

RunningModal.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** The details */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The accordion */
  accordion: PropTypes.arrayOf(
    PropTypes.shape(Title.propTypes),
    PropTypes.arrayOf(
      PropTypes.shape(Content.propTypes),
      PropTypes.shape(Detail.propTypes)
    ),
    PropTypes.arrayOf(PropTypes.shape(ConfigurationCard.propTypes))
  ),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** The current loading percentage */
  progress: PropTypes.number,
};

RunningModal.defaultProps = {
  className: '',
  header: null,
  footer: null,
  details: null,
  accordion: null,
  isOpen: false,
  progress: 0,
};

// Needed for Storybook
RunningModal.displayName = 'RunningModal';

export default RunningModal;
