/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes, { element } from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Content from '@components/text/content';
import Select from '@components/form/select';
import Group from '@components/form/group';
import Button from '@components/button/button';
import AdvancedModal from '@components/modal/advancedModal';
import styles from './switchElement.module.scss';

const cx = classNames.bind(styles);

const SwitchElement = ({
  className,
  modalLabel,
  options,
  elements,
  minYear,
  onChange,
  initialDate,
  initialBackendDate,
  strings,
  answerType,
  modal,
  dependentVariableValue,
  id,
  placeholder,
}) => {
  const d = new Date();

  // Incase strings is missing months
  const fallbackMonths = [
    { value: 'january', label: 'January', checked: false },
    { value: 'february', label: 'February', checked: false },
    { value: 'march', label: 'March', checked: false },
    { value: 'april', label: 'April', checked: false },
    { value: 'may', label: 'May', checked: false },
    { value: 'june', label: 'June', checked: false },
    { value: 'july', label: 'July', checked: false },
    { value: 'august', label: 'August', checked: false },
    { value: 'september', label: 'September', checked: false },
    { value: 'october', label: 'October', checked: false },
    { value: 'november', label: 'November', checked: false },
    { value: 'december', label: 'December', checked: false },
  ];

  // Sets which option is active
  const [active, setActive] = useState(answerType);

  // Sets the inputs of the days
  const [count, setCount] = useState(
    (initialDate && initialDate.split('-').length - 1) || 0
  );

  // Sets the date input
  const [year, setYear] = useState(
    initialDate.split('-')[0] || d.getFullYear()
  );
  const [month, setMonth] = useState('01');
  const [day, setDay] = useState('01');

  // Sets the radio buttons
  const [localOptions, setLocalOptions] = useState(options);

  const [defaultDate, setDefaultDate] = useState(initialDate);

  const [defaultYears, setDefaultYears] = useState(
    Array.from({ length: d.getFullYear() - minYear + 1 }, (_, i) => i + minYear)
      .reverse()
      .map((item, index) => ({
        value: item,
        label: item,
        checked: initialDate && Number(initialDate.split('-')[0]) === item,
      }))
  );

  const [defaultMonths, setDefaultMonths] = useState(
    strings && Object.keys(strings).length > 0
      ? strings?.approximation?.months &&
          Object.keys(strings?.approximation?.months).map((key, index) => ({
            value: key,
            position: index,
            label: strings.approximation.months[key],
            checked:
              initialDate &&
              initialDate.split('-')[1] &&
              Number(initialDate.split('-')[1] - 1 === index),
          }))
      : fallbackMonths
  );

  const daysArray = (dateString) => {
    const amountOfDays = new Date(
      Number(dateString.split('-')[0]),
      Number(dateString.split('-')[1]),
      0
    ).getDate();
    return !isNaN(amountOfDays)
      ? new Array(amountOfDays).fill(0).map((item, index) => ({
          value: (index + 1).toString().padStart(2, '0'),
          label: index + 1,
          checked:
            dateString &&
            dateString.split('-')[2] &&
            Number(dateString.split('-')[2]) === index + 1,
        }))
      : [{ value: 1, label: 1, checked: true }];
  };

  const [defaultDays, setDefaultDays] = useState(
    initialDate
      ? daysArray(initialDate)
      : [{ value: 1, label: 1, checked: true }]
  );

  // Sets the output from modal
  const [saveOutput, setSaveOutput] = useState(placeholder);
  const [output, setOutput] = useState(initialDate);
  const [backendSaveOutput, setBackendSaveOutput] = useState('');
  const [backendOutput, setBackendOutput] = useState(initialBackendDate);

  // Sets the modal view
  const [showModal, setShowModal] = useState(false);

  const classes = cx(
    {
      _self: true,
    },
    className
  );

  // Returns the current select value in the onChange props.
  const handleSelect = (e, index) => {
    const newOptions = elements[index].options;
    newOptions.map((option) => {
      option.checked = false;
      if (option.value === e.value) {
        option.checked = true;
      }
    });
    setLocalOptions(newOptions);
    const { value } = e;
    setBackendSaveOutput(value);
    setSaveOutput(e.label);
  };

  // Returns the current radio value in the onChange props.
  const handleRadio = (e, index) => {
    const newOptions = elements[index].options;
    newOptions.map((option) => {
      option.checked = false;
      if (option.value === e.target.value) {
        option.checked = true;
      }
    });
    setLocalOptions(newOptions);
    const { value } = e.target;
    setBackendSaveOutput(value);
    setSaveOutput(e.target.parentElement.lastElementChild.textContent);
  };

  // Handles the year state
  const handleYear = (e) => {
    count !== 2 && setCount(1);
    const newOptions = defaultYears;
    newOptions.map((option) => {
      option.checked = false;
      if (option.value === e.value) {
        option.checked = true;
      }
    });
    setYear(e.value);
    setDefaultYears(newOptions);
    triggerDateChange(e.value, month, day);
  };

  // Handles the month state
  const handleMonth = (e) => {
    setCount(2);
    const newOptions = defaultMonths;
    newOptions.map((option) => {
      option.checked = false;
      if (option.value === e.value) {
        option.checked = true;
      }
    });
    const monthNumber = (
      defaultMonths.findIndex((x) => x.value === e.value) + 1
    )
      .toString()
      .padStart(2, '0');
    setMonth(monthNumber);
    setDefaultMonths(newOptions);
    triggerDateChange(year, monthNumber, day);
  };

  // Handles the day state
  const handleDay = (e) => {
    const newOptions = defaultDays;
    newOptions.map((option) => {
      option.checked = false;
      if (option.value === e.value) {
        option.checked = true;
      }
    });
    setDay(e.value);
    setDefaultDays(newOptions);
    triggerDateChange(year, month, e.value);
  };

  const handleCount = (n) => {
    if (count > n) triggerDateChange(year, month, day, n);
    setCount(n);
  };

  const triggerDateChange = (y, m, d, number = null) => {
    let customString;
    const c = number !== null ? number : count;
    if (c === 0) {
      customString = `${y}`;
    } else if (c === 1) {
      customString = `${y}-${m}`;
    } else if (c === 2) {
      customString = `${y}-${m}-${d}`;
    }

    setDefaultDate(customString);
    setDefaultDays(daysArray(customString));
  };

  useEffect(() => {
    setBackendSaveOutput(defaultDate);
    setSaveOutput(defaultDate);
  }, [defaultDate]);

  // Component that containts the custom datepicker view
  const CustomDatepicker = () => (
    <div className={styles.customDatepicker}>
      <div className={styles.wrapper}>
        <Select
          id={'years'}
          onChange={handleYear}
          options={defaultYears}
          className={styles.dateSelect}
        />
        <Divider width={20} color={'transparent'} />
        {(count === 1 || count === 2) && (
          <Select
            id='months'
            onChange={handleMonth}
            options={defaultMonths}
            className={styles.dateSelect}
          />
        )}
        <Divider width={20} color={'transparent'} />
        {count === 2 && (
          <Select
            id='days'
            onChange={handleDay}
            options={defaultDays}
            className={styles.dateSelect}
          />
        )}
      </div>
      <Divider height={20} color={'transparent'} />
      {count === 1 && (
        <Button
          text={
            strings && Object.keys(strings).length > 0
              ? strings?.approximation?.forgot_month
              : 'Not sure of the month'
          }
          action={() => handleCount(0)}
        />
      )}
      {count === 2 && (
        <Button
          text={
            strings && Object.keys(strings).length > 0
              ? strings?.approximation?.forgot_day
              : 'Not sure of the day'
          }
          action={() => handleCount(1)}
        />
      )}
    </div>
  );

  // Decides which component to render based on the current elements props index
  const CustomComponent = ({ index, ...props }) => {
    if (index === 0) {
      return <CustomDatepicker />;
    }
    if (index === 1 && dependentVariableValue !== null) {
      return (
        <Select
          onChange={(e) => handleSelect(e, index)}
          options={localOptions}
          {...props}
        />
      );
    }
    if (index === 2) {
      return (
        <Group
          onChange={(e) => handleRadio(e, index)}
          options={localOptions}
          {...props}
        />
      );
    }
  };

  // Modal action
  const handleSave = () => {
    setShowModal(false);
    setBackendOutput(backendSaveOutput);
    setOutput(saveOutput);
    onChange && onChange(backendSaveOutput, 'switch');
  };

  const handleOpen = (event) => {
    setShowModal(true);
    event.preventDefault();
  };

  const header = {
    ...modal?.header,
    close: {
      action: () => setShowModal(false),
    },
  };

  const elementTrigger = {
    ...modal?.elementTrigger,
    button: {
      ...modal?.elementTrigger?.button,
      action: handleOpen,
    },
  };

  const footer = {
    ...modal?.footer,
    buttonsRight: [
      ...modal?.footer?.buttonsRight?.map((item) => ({
        ...item,
        action:
          item.action === 'save'
            ? handleSave
            : item.action === 'close'
            ? () => setShowModal(false)
            : item.action,
        disabled: item.action === 'save' && saveOutput === '',
      })),
    ],
  };

  const mutatedModal = {
    ...modal,
    header,
    elementTrigger,
    footer,
  };
  return (
    <AdvancedModal
      isOpen={showModal}
      elementTriggerValue={output}
      elementTriggerBackendValue={backendOutput}
      id={id}
      placeholder={
        saveOutput === backendOutput
          ? placeholder
          : backendOutput === undefined
          ? placeholder
          : saveOutput
      }
      {...mutatedModal}
    >
      <div className={classes}>
        {modalLabel && <Content size={'s'} {...modalLabel} />}
        {modalLabel && options && <Divider height={5} color={'transparent'} />}
        {options?.map((item, index) => {
          if (index !== 1 || (index === 1 && dependentVariableValue !== null)) {
            return (
              <Button
                key={index}
                outline={active !== index}
                className={styles.button}
                action={() => setActive(index)}
                {...item}
              />
            );
          }
        })}
        {options && elements && <Divider height={15} color={'transparent'} />}
        {elements && (
          <div className={styles.section}>
            {elements.map(
              (item, index) =>
                active === index && (
                  <div key={index}>
                    <CustomComponent index={index} {...item} />
                  </div>
                )
            )}
          </div>
        )}
      </div>
    </AdvancedModal>
  );
};

SwitchElement.propTypes = {
  /** To add extra custom classes */
  className: PropTypes.string,
  /** The label string */
  modalLabel: PropTypes.shape(Content.propTypes),
  /** The displayed option (tabs) */
  options: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** The displayed views for each option */
  elements: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  /** The minimum chosen year in the year options */
  minYear: PropTypes.number,
  /** The onChange for child-parent component communication */
  onChange: PropTypes.func,
  /** The initial date that is displayed */
  initialDate: PropTypes.string,
  modal: PropTypes.shape(AdvancedModal.propTypes).isRequired,
  strings: PropTypes.object,
  dependentVariableValue: PropTypes.string,
};

SwitchElement.defaultProps = {
  className: '',
  modalLabel: null,
  options: null,
  elements: null,
  minYear: 1950,
  onChange: null,
  initialDate: '',
  strings: null,
  modal: null,
  dependentVariableValue: null,
};

// Needed for Storybook
SwitchElement.displayName = 'SwitchElement';

export default SwitchElement;
