const activationModalConfig = (hide, update, remove, newLink) => {
  const config = {
    header: {
      title: {
        text: 'Edit activation condition',
      },
    },
    codeEditor: {},
    // status: {
    //   icon: {
    //     icon: 'warning',
    //   },
    //   title: {
    //     text: 'There seems to be a problem with the activation condition',
    //   },
    //   color: 'royal',
    // },
    footer: {
      buttonsRight: [
        {
          color: 'stable-500',
          text: 'Cancel',
          textColor: 'positive',
          outline: true,
          action: (e) => hide(e),
        },
        {
          text: 'Save changes',
          id: 'SAVE',
          action: (e) => update(e),
        },
      ],
    },
  };

  // if (newLink == undefined) {
  //   config.footer.buttonsLeft = [{
  //     color: 'stable-500',
  //     outline: true,
  //     icon: {
  //       icon: 'trash',
  //       color: 'assertive',
  //     },
  //     action: (e) => remove(e)
  //   }];
  // }

  return config;
};

export default activationModalConfig;
