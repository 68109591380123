const getValue = (data) => {
  const { elem, sort } = data;
  const inputElem = elem.target
    ? elem.target
    : elem.element
    ? elem.element
    : elem.currentTarget;
  const value =
    sort === 'select' ||
    sort === 'radio' ||
    sort === 'date' ||
    sort === 'reading' ||
    sort === 'switch' ||
    sort === 'checkbox' ||
    sort === 'check-group' ||
    sort === 'asynchronous-select'
      ? elem
      : !inputElem
      ? null
      : inputElem.type === 'checkbox'
      ? inputElem.checked
      : inputElem.value;
  return value;
};

export default getValue;
