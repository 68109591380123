import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { copy: String };
  static targets = ['copy', 'input'];

  copyToClipboard() {
    if (this.hasCopyTarget) {
      if (this.hasInputTarget) {
        this.copyValue = this.inputTarget.value;
      }
      navigator.clipboard.writeText(this.copyValue);
    }
  }
}
