import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Title from '@components/text/title';
import Button from '@components/button/button';
import CodeEditor from '@components/form/codeEditor';
import Element from '@components/form/element';
import Select from '@components/form/select';
import Divider from '@components/ui/divider';
import Content from '@components/text/content';
import ModalWrapper from '../modalWrapper';
import styles from './assignment.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => prevProps === nextProps;

const AssignmentModal = ({
  className,
  header,
  footer,
  data,
  addData,
  selectLabel,
  fieldLabel,
  isOpen,
  onClose,
  close,
  completions,
  functions,
  setAssignment,
}) => {
  const [rows, setRows] = useState();
  const [advancedComputation, setAdvancedComputation] = useState([]);
  const [inputType, setInputType] = useState();
  const [mutatedData, setMutatedData] = useState([]);
  const [hasRendered, setHasRendered] = useState(false);
  const classes = cx(
    {
      assignment: true,
    },
    className
  );

  useEffect(() => {
    if (data === null) return;
    setRows(data);
    // Prefills the code editor
    setAdvancedComputation(
      data?.map((item) => ({
        checked: !!(
          item.fields[1].condition !== null && item.fields[1].condition !== ''
        ),
        value: item.fields[1].condition,
      }))
    );
    setMutatedData(
      data?.map((item) => ({
        id: item.select.id,
        value: item.fields[1].value,
        condition: item.fields[1].condition,
        condition_id: item.fields[1].condition_id,
        operator: Object.keys(item.fields[1].possible_operators).filter(
          (x) => item.fields[1].possible_operators[x] === item.fields[0].value
        )[0],
        variable: item.fields[1].possible_variables.filter(
          (x) => x[1] === item.select.options[0].label
        )[0][0],
      }))
    );
    setInputType(
      data?.map((item) => ({
        id: item.fields[1].possible_variables.filter(
          (x) => x[1] === item.select.options[0].value
        )[0][0],
        type: item.fields[1].type.split('::')[1].toLowerCase(),
      }))
    );
    setHasRendered(true);
  }, [data]);

  const addVariable = () => {
    setRows([...rows, addData]);
    setAdvancedComputation([...advancedComputation, { checked: false }]);
  };

  const setContent = (e, type, id, index, variables) => {
    const dataToSend = [...mutatedData];
    const indexOfObject = dataToSend.findIndex((x) => x.id === id);
    if (type === 'editor') {
      dataToSend[indexOfObject].condition = e;
    }
    if (type === 'value') {
      dataToSend[indexOfObject].value = e.target.value;
    }
    if (type === 'radio') {
      dataToSend[indexOfObject].value = e.target.value;
    }
    if (type === 'operator') {
      dataToSend[indexOfObject].operator = e.value;
    }
    if (type === 'select') {
      dataToSend[indexOfObject].variable = e.value;
      const mutatedInputs = [...inputType];
      mutatedInputs[index] = {
        type: variables
          .filter((x) => x[0] === e.value)[0][2]
          .split('::')[1]
          .toLowerCase(),
        id: e.value,
      };
      setInputType(mutatedInputs);
    }
    setMutatedData(dataToSend);
    setAssignment(dataToSend);
  };

  const updateComputations = (i, val) => {
    const mutated = [...advancedComputation];
    mutated[i].checked = val;
    setAdvancedComputation(mutated);
  };
  return (
    <div className={classes}>
      <ModalWrapper
        header={header}
        footer={footer}
        isOpen={isOpen}
        onClose={onClose}
        close={close}
      >
        {data && (
          <div className={styles.wrapper}>
            {rows?.map((item, i) => {
              const itemIndex = i;
              return (
                <div key={i}>
                  <div key={i} className={styles.row}>
                    {item.select && (
                      <div className={styles.select}>
                        {selectLabel && itemIndex === 0 && (
                          <Content
                            className={styles.selectLabel}
                            {...selectLabel}
                          />
                        )}
                        <Select
                          options={item.fields[1]?.possible_variables?.map(
                            (x) => ({
                              value: x[0],
                              label: x[1],
                              checked: item.select.options[0].value === x[1],
                            })
                          )}
                          onChange={(e) => (
                            setContent(
                              e,
                              'select',
                              item.select.id,
                              i,
                              item.fields[1].possible_variables
                            ),
                            setRows(
                              [...rows],
                              (rows[i].select.options[0].value = e.label)
                            )
                          )}
                        />
                      </div>
                    )}
                    {item.fields[0].label === 'Operator' && (
                      <div className={styles.field}>
                        {fieldLabel && itemIndex === 0 && (
                          <Content
                            className={styles.selectLabel}
                            {...fieldLabel}
                          />
                        )}
                        <Select
                          options={Object.keys(
                            item.fields[1].possible_operators
                          ).map((x) => ({
                            value: x,
                            label: item.fields[1].possible_operators[x],
                            checked:
                              item.fields[1].possible_operators[x] ===
                              item.fields[0].value,
                          }))}
                          onChange={(e) => (
                            setContent(e, 'operator', item.select.id),
                            setRows(
                              [...rows],
                              (rows[i].fields[0].value = e.label)
                            )
                          )}
                        />
                      </div>
                    )}
                    {item.fields[1].label === 'Value' && (
                      <div className={styles.field}>
                        {fieldLabel && itemIndex === 0 && (
                          <Content
                            className={styles.selectLabel}
                            {...fieldLabel}
                          />
                        )}
                        {inputType[i].type === 'date' ? (
                          <Element
                            type={'date'}
                            value={item.fields[1]?.value}
                            onChange={(e) =>
                              setContent(e, 'value', item.select.id)
                            }
                          />
                        ) : inputType[i].type === 'number' ? (
                          <Element
                            type={'number'}
                            value={item.fields[1]?.value}
                            onChange={(e) =>
                              setContent(e, 'value', item.select.id)
                            }
                          />
                        ) : inputType[i].type === 'boolean' ? (
                          <Element
                            type={'radio'}
                            options={[
                              {
                                checked: mutatedData[i].value === 'true',
                                label: 'True',
                                name: `True${i}`,
                                value: 'true',
                              },
                              {
                                checked: mutatedData[i].value === 'false',
                                label: 'False',
                                name: `false${i}`,
                                value: 'false',
                              },
                            ]}
                            onChange={(e) =>
                              setContent(e, 'radio', item.select.id)
                            }
                          />
                        ) : inputType[i].type === 'collection' ? (
                          <Element
                            type={'text'}
                            value={item.fields[1]?.value}
                            onChange={(e) =>
                              setContent(e, 'value', item.select.id)
                            }
                          />
                        ) : inputType[i].type === 'tracking' ? (
                          <Element
                            type={'text'}
                            value={item.fields[1]?.value}
                            onChange={(e) =>
                              setContent(e, 'value', item.select.id)
                            }
                          />
                        ) : (
                          <Element
                            type={'text'}
                            value={item.fields[1]?.value}
                            onChange={(e) =>
                              setContent(e, 'value', item.select.id)
                            }
                          />
                        )}
                      </div>
                    )}

                    <div className={styles.removeButton}>
                      <Element
                        type={'checkbox'}
                        text={'Advanced computation'}
                        checked={advancedComputation[i]?.checked}
                        onChange={(e) =>
                          updateComputations(i, e.target.checked)
                        }
                      />
                    </div>
                    <div></div>
                  </div>
                  {advancedComputation[i]?.checked && (
                    <>
                      <CodeEditor
                        completions={completions}
                        functions={functions}
                        initialValue={advancedComputation[i]?.value}
                        setContent={(e) =>
                          setContent(e, 'editor', item.select.id)
                        }
                      />
                      <Divider height={10} color={'transparent'} />
                    </>
                  )}
                </div>
              );
            })}

            <div className={styles.addButton}>
              <Button
                action={addVariable}
                color={'stable-500'}
                textColor={'positive'}
                text={'Add variable'}
                outline
              />
            </div>
          </div>
        )}
      </ModalWrapper>
    </div>
  );
};

AssignmentModal.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** Initial data array */
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /** Updates the data array with a new object */
  addData: PropTypes.shape({}),
  /** Label above the select */
  selectLabel: PropTypes.shape(Content.propTypes),
  /** Label above the field */
  fieldLabel: PropTypes.shape(Content.propTypes),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Function to trigger when modal closes */
  onClose: PropTypes.func,
  /** The close button of the modal */
  close: PropTypes.bool,
};

AssignmentModal.defaultProps = {
  className: '',
  header: null,
  footer: null,
  data: null,
  addData: null,
  selectLabel: null,
  fieldLabel: null,
  isOpen: false,
  onClose: null,
  close: true,
};

// Needed for Storybook
AssignmentModal.displayName = 'AssignmentModal';

export default React.memo(AssignmentModal, checkEqual);
