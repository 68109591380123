import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Rails from '@rails/ujs';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import componentStyles from './draggableCollapsible.module.scss';
import styles from '../table/table.module.scss';
import { BodyRow, FlatRow, HeadRow } from '../_row';

const cx = classNames.bind(styles);
const cx2 = classNames.bind(componentStyles);

const getListStyle = (isDraggingOver) => ({
  backgroundColor: isDraggingOver && 'rgba(var(--color-stable-500),1)',
  display: 'table',
});

const getItems = (bodies) => {
  bodies.forEach((body, i) => {
    body[0].data[0] = {
      ...body[0].data[0],
      action: () => {},
      color: 'transparent',
      textColor: 'balanced-500',
      icon: { icon: 'drag-handle' },
      className: componentStyles.drag,
    };
    const mutatedBody = (body[0].id = `id${i}`);
    return mutatedBody;
  });
  return bodies;
};
const DraggableCollapsible = ({
  bodies,
  className,
  color,
  head,
  inverse,
  params,
  sizes,
  defaultOpen,
  originalId,
  collapsible
}) => {
  const columns = sizes.length;
  const classes = cx(
    {
      table: true,
      collapsed: true,
      tablewrap: true,
      inverse,
    },
    className,
    color
  );
  const [mutatedBodies, setMutatedBodies] = useState([]);

  useEffect(() => {
    setMutatedBodies(getItems(bodies));
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (
      bodies &&
      bodies[result.source.index] &&
      bodies[result.source.index][0] &&
      bodies[result.source.index][0].data[0]
    ) {
      const { url } = mutatedBodies[result.source.index][0].data[0];
      const { method } = mutatedBodies[result.source.index][0].data[0];
      const items = Array.from(mutatedBodies);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setMutatedBodies(items);

      const formData = new FormData();
      formData.append('sequence', result.destination.index);

      Rails.ajax({
        type: method,
        url,
        data: formData,
      });
    }
  };

  const _renderHead = (data) => {
    return (
      <tr>
        <HeadRow key={'drag'} size={5} />
        {data.map((column, i) => {
          return (
            <HeadRow
              key={i}
              column={column}
              params={params}
              size={i < sizes.length - 1 ? sizes[i] : 5}
            />
          );
        })}
      </tr>
    );
  };

  const _renderHiddenRow = (row, index, final) => {
    const rowClass = cx2({
      row: true,
      rowLast: final,
    });
    return (
      <tr key={index} className={rowClass}>
        {row.map((column, i) => {
          const columnspan =
            row.length < columns ? (i === 0 ? columns - row.length + 1 : 1) : 1;
          return (
            <FlatRow
              key={i}
              columnspan={columnspan}
              column={column}
              last={i === row.length - 1}
              final={final}
            />
          );
        })}
      </tr>
    );
  };

  const _renderRow = (row, index, provided, snapshot, hiddenRows) => {
    const [collapsed, setCollapsed] = useState(defaultOpen);
    const rowClass = cx2({
      row: true,
      rowMain: true,
      rowToggled: collapsed,
    });
    return (
      <>
        <tr className={rowClass}>
          {row.map((column, i) => {
            const columnspan =
              row.length < columns - 1
                ? i === 0
                  ? columns - row.length + 2
                  : 1
                : 1;
            return (
              <BodyRow
                key={i}
                index={index}
                columnspan={columnspan}
                column={column}
                size={i < sizes.length ? sizes[i] : 5}
              />
            );
          })}
          {collapsible && (
            hiddenRows.length > 0 ? (
              <BodyRow
                index={index}
                column={{
                  link: true,
                  color: 'transparent',
                  action: () => setCollapsed(!collapsed),
                  icon: {
                    icon: collapsed ? 'chevron-up' : 'chevron-down',
                    width: 10,
                    color: 'dark',
                  },
                  className: componentStyles.lastCellBtn,
                }}
                size={5}
              />
            ) : (
              <BodyRow index={index} column={false} size={5} />
            )
          )}
        </tr>
        {collapsible && collapsed &&
          hiddenRows.length > 0 &&
          hiddenRows.map((r, i) => {
            return _renderHiddenRow(r, i, i === hiddenRows.length - 1);
          })}
        <tr>
          <td className={componentStyles.hack} />
        </tr>
      </>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='tabletable'>
        {(droppableProvided, snapshotDroppable) => (
          <table
            className={classes}
            cellSpacing='0'
            cellPadding='0'
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={getListStyle(snapshotDroppable.isDraggingOver)}
          >
            {head && head.length > 0 && <thead>{_renderHead(head)}</thead>}
            {mutatedBodies.length > 0 &&
              mutatedBodies.map((body, index) => {
                const firstRow = body.slice(0, 1).map((r) => r.data);
                const hiddenRows = body
                  .slice(1, body.length)
                  .map((r) => r.data);
                return (
                  <Draggable
                    display={'table'}
                    draggableId={`table_${body[0].id}`}
                    key={body[0].id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tbody
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        {_renderRow(
                          firstRow[0],
                          index,
                          provided,
                          snapshot,
                          hiddenRows,
                          defaultOpen
                        )}
                      </tbody>
                    )}
                  </Draggable>
                );
              })}
            {droppableProvided.placeholder}
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableCollapsible.propTypes = {
  // All rows to be rendered
  bodies: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))).isRequired,
  // Custom classname
  className: PropTypes.string,
  // Color of rows
  color: PropTypes.string,
  // Table head
  head: PropTypes.arrayOf(PropTypes.shape({})),
  // Inverse the direction of the rows
  inverse: PropTypes.bool,
  // Parameters of head
  params: PropTypes.shape({}),
  // Size of table
  sizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  // Collapsible allowed or not
  collapsible: PropTypes.bool,
};

DraggableCollapsible.defaultProps = {
  className: '',
  color: '',
  head: [],
  inverse: false,
  params: {},
  defaultOpen: false,
  collapsible: true,
};

// Needed for Storybook
DraggableCollapsible.displayName = 'DraggableCollapsible';

export default DraggableCollapsible;
