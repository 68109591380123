import React from 'react';
import ActivationCard from '.';

export default {
  title: 'BRMS/flowchart/cards/ActivationCard',
  component: ActivationCard,
};

const Template = (args) => (
  <>
    <ActivationCard {...args} />{' '}
  </>
);

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Activation condition',
  },
  activation_rule: {
    rule: 'illness_3.surgery == false || illness_3.surgery_report != null',
  },
};

export const Diagram = () => (
  <>
    <ActivationCard
      {...Default.args}
      size={'xxs'}
      diagram
      color={'balanced-500'}
      title={{
        text: 'Activation condition',
      }}
      activation_rule={{
        rule: 'illness_3.surgery == false || illness_3.surgery_report != null',
      }}
      open={true}
    />
    <ActivationCard
      {...Default.args}
      size={'xxs'}
      diagram
      color={'balanced-500'}
      title={{
        text: 'Activation condition',
      }}
      activation_rule={{
        rule: 'illness_3.surgery == false || illness_3.surgery_report != null',
      }}
    />
  </>
);
