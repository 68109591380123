import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'included', 'ignored', // The row of the records that should swap visibility
        'markIgnoreField', 'destroyHiddenField', 'destroyCheckbox', 'selector', 'chevron',
        'hideWhenIgnores', 'hideWhenNoIncludes', 'hideWhenIncludes', 'hideWhenNoIgnores',
        'hideWhenOnlySubset' // Element with this will hide when there are no more included (excluding the subset)
    ];

    connect() {
        this.updateAllCheckboxes();
        this.updateRelatedElements();
    }

    closeAllChevrons() {
        this.chevronTargets.forEach((chevron) => {
            chevron.setAttribute('open', 'false');
        });
    }

    ignore(e) {
        this.includedTargets.forEach((record) => {
            if (e.params.id === record.getAttribute('data-subset-merge-id')){
                record.style.display = 'none';
                this.setRecordValues(record, false);
            }
        });
        this.ignoredTargets.forEach((record) => {
            if (e.params.id === record.getAttribute('data-subset-merge-id'))
                record.style.display = 'flex';
        });
        this.updateRelatedElements();
        this.updateTotalMerge();
    }

    include(e) {
        this.includedTargets.forEach((record) => {
            if (e.params.id === record.getAttribute('data-subset-merge-id')){
                record.style.display = 'flex';
                this.setRecordValues(record, true);
            }
        });
        this.ignoredTargets.forEach((record) => {
            if (e.params.id === record.getAttribute('data-subset-merge-id'))
                record.style.display = 'none';
        });
        this.updateRelatedElements();
        this.updateTotalMerge();
    }

    /**
     * calculates, and sets an attribute with the amount that will be merged.
     */
    updateTotalMerge(){
        const selector = this.getSelectorValue();
        const attrName = 'data-subset-merge-count';
        if (selector === 'no_changes')
            this.element.setAttribute(attrName, 'No changes');
        else if (selector === 'all_changes'){
            // If one selected all_changes, it is still very possible that all changes are ignored
            // meaning that there are no changes, so it should also display 'No changes'
            let hasChanges = false;
            this.includedTargets.forEach((record) => {
                if (record.style.display !== 'none') hasChanges = true;
            });
            if (!hasChanges) this.element.setAttribute(attrName, 'No changes');
            else this.element.setAttribute(attrName, 'All');
        }
        else {
            let count = 0;
            this.destroyCheckboxTargets.forEach((checkbox) => {
                if (!checkbox.checked) return;
                this.includedTargets.forEach((record) => {
                    if (record.getAttribute('data-subset-merge-id') !== checkbox.getAttribute('data-subset-merge-id')) return
                    if (record.style.display !== 'none') count++;
                });
            });
            if (count <= 0) this.element.setAttribute(attrName, 'No changes');
            else this.element.setAttribute(attrName, count);
        }

        this.dispatch('updateTotalMerge', { detail: { id: this.element.getAttribute('data-mirror-id') }} );
    }

    updateAllCheckboxes() {
        const selection = this.getSelectorValue();
        const isChecked = selection === 'all_changes';
        const isDisabled = selection !== 'selection_of_changes';

        this.destroyCheckboxTargets.forEach((checkbox) => {
            checkbox.checked = isChecked;
            checkbox.disabled = isDisabled;
        });

        this.includedTargets.forEach((record) => {
            let currently_disabled =  record.style.display === 'none';
            this.setRecordValues(record, !currently_disabled, selection);
        });
        this.updateTotalMerge();
    }

    /**
     * Sets the values for ignore and destroy fields for a given record based on its
     * inclusion status and the current selection state.
     * @param {Element} record The row (DOM element) representing the record whose values are to be set.
     * @param {boolean} included whether the record is included or ignored.
     * @param {"all_changes" | "no_changes" | "selection_of_changes"} selection The current selection state
     */
    setRecordValues(record, included, selection = this.getSelectorValue()) {
        const recordId = record.getAttribute('data-subset-merge-id')
        const destroyField = this.destroyHiddenFieldTargets.find((destroyField) => {
            return recordId === destroyField.getAttribute('data-subset-merge-id');
        });
        const ignoreField = this.markIgnoreFieldTargets.find((markIgnoreField) => {
            return recordId === markIgnoreField.getAttribute('data-subset-merge-id');
        });

        if (!included) {
            ignoreField.value = 1;
            destroyField.value = 0;
        } else if (selection === 'all_changes') {
            ignoreField.value = 0;
            destroyField.value = 0;
        } else {
            ignoreField.value = 0;
            destroyField.value = 1;
        }
    }

    /**
     * Retrieves the value of the currently selected radio button.
     * @returns {"all_changes" | "no_changes" | "selection_of_changes"}
     */
    getSelectorValue() {
        const checked_option = this.selectorTargets.find(selector => selector.checked) || this.selectorTargets[0];
        checked_option.checked = true; // Ensure the first selector is checked if none were initially checked
        return checked_option.value;
    }

    updateRelatedElements(){
        let anyInclude = this.includedTargets.some(record => record.style.display !== 'none');
        let anyIgnored = this.ignoredTargets.some(record => record.style.display !== 'none');
        let onlySubset = false;
        if (anyInclude)
            onlySubset = this.includedTargets.every(record => {
                return record.style.display === 'none' || record.getAttribute('data-subset-merge-subset') === 'true';
            });

        this.hideWhenNoIncludesTargets.forEach(e => e.style.display = anyInclude ? 'flex' : 'none');
        this.hideWhenNoIgnoresTargets.forEach(e => e.style.display = anyIgnored ? 'flex' : 'none');
        this.hideWhenIncludesTargets.forEach(e => e.style.display = anyInclude ? 'none' : 'flex');
        this.hideWhenIgnoresTargets.forEach(e => e.style.display = anyIgnored ? 'none' : 'flex');
        this.hideWhenOnlySubsetTargets.forEach(e => e.style.display = onlySubset ? 'none' : 'flex');
    }
}
