import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactSelect from 'react-select';
import Rails from '@rails/ujs';
import IconRight from '@components/button/icon-right';
import Button from '@components/button/button';
import Badge from '@components/badge/badge';
import Content from '@components/text/content';
import Divider from '@components/ui/divider';
import Blanket from './partials/blanket';
import Menu from './partials/menu';
import MenuItem from './partials/menuItem';

import styles from './ajaxselect.module.scss';

const cx = classNames.bind(styles);

const selectStyles = {
  control: (provided) => ({
    ...provided,
    margin: 20,
    fontFamily: 'var(--font-stack)',
    fontSize: 14,
  }),
  menu: () => ({ marginTop: 10 }),
  menuList: (provided) => ({ ...provided, maxHeight: 340 }),
  noOptionsMessage: () => ({
    color: 'var(--color-dark)',
    fontFamily: 'var(--font-stack)',
    fontSize: 'var(--font-size-m)',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 'var(--spacing-xs)',
  }),
};
// The complete dropdown, where the select functionality is nested
const Dropdown = ({ children, isOpen, target, onClose, className }) => (
  <div className={className}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

Dropdown.propTypes = {
  // Fill the dropdown with items
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // Add custom class to the dropdown
  className: PropTypes.string.isRequired,
  // Trigger function to close the dropdown when clicked outside
  onClose: PropTypes.func.isRequired,
  // Boolean to track the state of the menu
  isOpen: PropTypes.bool.isRequired,
  // Interface to activate the dropdown
  target: PropTypes.shape({}),
};

Dropdown.defaultProps = {
  target: {},
};

// Custom React Select functionality
const AjaxSelect = ({
  description,
  method,
  name,
  placeholder,
  url,
  defaultItem,
  errors,
  data,
  onSelect,
  open,
  label,
}) => {
  const [isOpen, setOpen] = useState(open);
  const [value, setValue] = useState({});
  const [items, setItems] = useState();
  const [search, setSearch] = useState('');

  const timeoutId = useRef();

  const searchDB = () => {
    const callback = {
      url: url + search,
      method,
    };
    Rails.ajax({
      type: callback.method,
      url: callback.url,
      success(response) {
        setItems(response);
      },
    });
  };

  useEffect(() => {
    if (Object.keys(defaultItem).length > 0) setSearch(defaultItem.value);
  }, [defaultItem]);

  useEffect(() => {
    if (defaultItem.value === search) return;
    clearTimeout(timeoutId.current);
    console.log(search)
    if (!search || !search.toString().trim()) {
      setItems([]);
      return;
    }
    timeoutId.current = setTimeout(() => {
      searchDB();
    }, 300);
  }, [search]);

  const classes = cx({
    select: true,
  });

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const onSelectChange = (v) => {
    toggleOpen();
    setValue(v);
    setSearch(v.label || v.value);
    onSelect && onSelect(v);
  };

  const onChange = (e, { action }) => {
    if (action === 'input-change') {
      setSearch(e);
    }
  };

  const MenuToggle = ({ action, selectedValue, removeAction }) => {
    const toggleClasses = cx({
      menutoggle: true,
      isopen: isOpen,
      errorstate: errors && errors.length > 0,
    });
    return (
      <>
        {errors && errors.length > 0 && (
          <div className={styles.badgewrapper}>
            {errors.map((error, index) => (
              <Badge
                key={index}
                color={'assertive'}
                size={'s'}
                className={styles.badge}
                content={
                  typeof error === 'object'
                    ? error
                    : {
                        text: error,
                      }
                }
              />
            ))}
          </div>
        )}
        <div className={styles.wrapper}>
          <IconRight
            action={action}
            className={toggleClasses}
            text={
              selectedValue.label || selectedValue.value ||
              (defaultItem && (defaultItem.label || defaultItem.value)) ||
              description
            }
            icon={{ icon: 'chevron-up', width: 10, height: 10 }}
            outline
            textColor={'dark'}
            color={'stable-500'}
            rotatable={{
              transform: isOpen && 'rotate(180deg)',
            }}
          />
          <Button
            className={isOpen ? styles.removeOpen : styles.remove}
            color={'stable-500'}
            icon={{ icon: 'close', color: 'assertive' }}
            action={removeAction}
            outline
          />
        </div>
        <input
          type='hidden'
          name={name || ''}
          value={selectedValue.value || selectedValue.id || (defaultItem && (defaultItem.value || defaultItem.id))}
        />
      </>
    );
  };

  MenuToggle.propTypes = {
    action: PropTypes.func.isRequired,
    selectedValue: PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.number,
    }),
  };

  MenuToggle.defaultProps = {
    selectedValue: {},
  };

  return (
    <>
      {label && (
        <>
          <Content text={label} size='s' />
          <Divider height={5} color={'transparent'} />
        </>
      )}
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        classNamePrefix={'select'}
        className={classes}
        target={
          <MenuToggle
            action={toggleOpen}
            removeAction={() => setValue({ value: description, id: ' ' })}
            selectedValue={value}
          />
        }
      >
        <ReactSelect
          autoFocus
          backspaceRemovesValue={false}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
            Option: MenuItem,
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          inputValue={search}
          isClearable={false}
          menuIsOpen
          onChange={onSelectChange}
          onInputChange={onChange}
          onSelectResetsInput={false}
          options={data || items}
          placeholder={placeholder}
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
          filterOption={() => true}
        />
      </Dropdown>
    </>
  );
};

AjaxSelect.propTypes = {
  description: PropTypes.string,
  // Placeholder for the input field
  placeholder: PropTypes.string,
  // Url for requests
  url: PropTypes.string,
  // Method of call
  method: PropTypes.string,
  // Name of input field
  name: PropTypes.string,
  // Set a item as default
  defaultItem: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
  }),
  // Error state
  errors: PropTypes.arrayOf(PropTypes.string),
  // Prefill the list (for Storybook)
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /** Action to take on select item */
  onSelect: PropTypes.func,
  /** Whether the select should be opened by default */
  open: PropTypes.bool,
};

AjaxSelect.defaultProps = {
  defaultItem: {},
  description: 'Select a rule',
  errors: [],
  method: 'GET',
  name: '',
  placeholder: 'Search',
  url: '/admin/rules.json?q[name_cont]=',
  data: null,
  onSelect: null,
  open: false,
};

AjaxSelect.displayName = 'AjaxSelect';

export default AjaxSelect;
