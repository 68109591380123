import React from 'react';
import PropTypes from 'prop-types';
import Content from '@components/text/content';
import Title from '@components/text/title';
import Button from '@components/button/button';
import Divider from '@components/ui/divider';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '../wrapper';
import styles from './variable.module.scss';

const cx = classNames.bind(styles);

const VariableCard = ({
  size,
  action,
  color,
  title,
  content,
  className,
  buttons,
}) => {
  const cardClasses = cx({ wrapper: true }, className);
  return (
    <Wrapper size={size} action={action} color={color} className={cardClasses}>
      <div className={styles.contentWrapper}>
        {title && <Title size={'h3'} color={'dark'} {...title} />}
        {content && (
          <div className={styles.content}>
            {content.map((c, i) => (
              <div className={styles.innerContent} key={i}>
                <Divider height={5} color={'transparent'} />
                <Content color={'balanced-500'} size={'s'} text={c.label} />
                <Divider height={5} color={'transparent'} />
                <Content color={'dark'} size={'m'} text={c.text} />
              </div>
            ))}
          </div>
        )}
      </div>
      {buttons &&
        buttons.map((button, index) => (
          <Button key={index} size={'s'} link {...button} />
        ))}
    </Wrapper>
  );
};

VariableCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** Action of the card */
  action: PropTypes.string,
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes).isRequired,
  /** The list items of the card */
  content: PropTypes.arrayOf(PropTypes.shape(Content.propTypes)),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Action of the card */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
};

VariableCard.defaultProps = {
  color: 'light',
  size: 'm',
  action: '',
  className: '',
  content: [],
  buttons: [],
};

// Needed for Storybook
VariableCard.displayName = 'VariableCard';

export default VariableCard;
