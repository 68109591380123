import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Content from '@components/text/content';
import Title from '@components/text/title';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '@components/card/wrapper';
import styles from './activation.module.scss';

import ToolTip from '../../tooltip';

const cx = classNames.bind(styles);

const ActivationCard = ({
  link,
  size,
  color,
  title,
  activation_rule,
  className,
  diagram,
  buttons,
  open,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const cardClasses = cx({ wrapper: true, diagram }, className);

  // Number to letter (1=A, 27=AA, 28=AB, etc.)
  // https://stackoverflow.com/a/60033403
  const printToLetter = (number) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = ''; 
    let charIndex = number % alphabet.length;
    let quotient = number / alphabet.length;
    
    if (charIndex-1 == -1) {
      charIndex = alphabet.length
      quotient--;
    }
    
    result = alphabet.charAt(charIndex-1) + result;
    
    if (quotient >= 1) {
      result = printToLetter(parseInt(quotient)) + result;
    }

    return result;
  }

  const renderWrapper = () => (
    <Wrapper size={size} color={color} className={cardClasses}>
      <div
        className={styles.contentWrapper}
        onClick={(e) => {
          isOpen !== 'hidden' ? setIsOpen(!isOpen) : null;
        }}
      >
        <div className={styles.header}>
          <Content
            color={'light'}
            size={'s'}
            text={printToLetter(link.replace('link_', ''))}
            className={styles.ref}
          />
          <Content
            color={'light'}
            size={'s'}
            text={title.text}
            className={styles.title}
          />
        </div>
        {isOpen && (
          <div className={styles.content}>
            <Content
              className={styles.text}
              color={'light'}
              size={'s'}
              text={activation_rule.rule
                .replace('||', '|| \n')
                .replace('&&', '&& \n')}
            />
          </div>
        )}
      </div>
    </Wrapper>
  );
  return !disabled && buttons && buttons.length > 0 ? (
    <ToolTip buttons={buttons}>{renderWrapper()}</ToolTip>
  ) : (
    renderWrapper()
  );
};

ActivationCard.propTypes = {
  /** The link id of the node */
  link: PropTypes.string,
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The activation rule of the card (string for now) */
  activation_rule: PropTypes.shape({
    rule: PropTypes.string,
  }),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Automatically added when the card is used inside the diagram view. Sets the card on a fixed width */
  diagram: PropTypes.bool,
  /** Disables card interaction */
  disabled: PropTypes.bool, 
};

ActivationCard.defaultProps = {
  id: null,
  color: 'balanced-500',
  size: 'xxs',
  className: '',
  title: '',
  activation_rule: [],
  diagram: false,
  disabled: false,
};

// Needed for Storybook
ActivationCard.displayName = 'ActivationCard';

export default ActivationCard;
