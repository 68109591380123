import React from 'react';
import ModalWrapper from '.';

export default {
  title: 'BRMS/flowchart/modals/ModalWrapper',
  component: ModalWrapper,
};

const Template = (args) => <ModalWrapper isOpen {...args} />;

export const Default = Template.bind({});

Default.args = {
  isOpen: true,
  header: {
    title: {
      text: 'Modal Wrapper',
    },
  },
  footer: {
    buttonsLeft: [
      {
        color: 'stable-500',
        outline: true,
        icon: {
          name: 'trash',
          color: 'assertive',
        },
      },
    ],
    buttonsRight: [
      {
        color: 'stable-500',
        text: 'Cancel',
        textColor: 'positive',
        outline: true,
      },
      {
        text: 'Save changes',
      },
    ],
  },
};
