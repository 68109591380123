const tooltipConfig = (editNode, deleteNode) => ({
  buttons: [
    {
      outline: false,
      color: 'transparent',
      customData: { target: '_blank' },
      icon: {
        name: 'edit',
        color: 'positive',
        width: 22,
        height: 22,
      },
      action: editNode,
    },
    {
      outline: false,
      color: 'transparent',
      icon: {
        name: 'trash',
        color: 'assertive',
        width: 22,
        height: 22,
      },
      action: (e) => deleteNode(e),
    },
  ],
});

export default tooltipConfig;
