import React from 'react';
import { Divider } from '@components/ui';
import QuestionCard from '.';

export default {
  title: 'BRMS/flowchart/cards/QuestionCard',
  component: QuestionCard,
};

const Template = (args) => (
  <>
    <QuestionCard {...args} />{' '}
  </>
);

export const Default = Template.bind({});

Default.args = {
  label: {
    text: 'Question (bool)',
  },
  title: {
    text: '1. Do you have any symptoms?',
  },
  content: [
    {
      text: 'Symptoms',
    },
  ],
};

export const Start = () => (
  <QuestionCard
    {...Default.args}
    start
    title={{
      text: 'Are you waiting for tests or the results of tests on connection with this condition?',
    }}
    color={'positive-100'}
    label={{
      text: 'Question (bool)',
      color: 'positive',
    }}
    content={[
      {
        text: 'Symptoms',
        color: 'positive',
      },
    ]}
  />
);

export const End = () => (
  <QuestionCard
    {...Default.args}
    end
    color={'energized'}
    title={{
      text: 'What is your gender?',
    }}
    label={{
      text: 'Question (bool)',
      color: 'energized',
    }}
    content={[
      {
        text: 'Gender',
        color: 'energized',
      },
    ]}
  />
);

export const Unreachable = () => (
  <QuestionCard
    {...Default.args}
    unreachable
    color={'royal'}
    tooltipText={'This is unreachable'}
    title={{
      text: 'Was the anaemia caused by pregnancy or abnormal periods/menstruation?',
    }}
    label={{
      text: 'Question (bool)',
      color: 'royal',
    }}
    content={[
      {
        text: 'Reason',
        color: 'royal',
      },
    ]}
  />
);

export const Sizes = () => (
  <>
    <QuestionCard
      {...Default.args}
      diagram
      title={{
        text: 'Do you have or are you being treated for irondeficiency anaemia now?',
      }}
      content={[
        {
          text: 'Symptoms',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      {...Default.args}
      title={{
        text: 'Do you have or are you being treated for irondeficiency anaemia now?',
      }}
      content={[
        {
          text: 'Symptoms',
        },
      ]}
    />
  </>
);

export const Variants = () => (
  <>
    <QuestionCard
      {...Default.args}
      title={{
        text: 'Do you have or are you being treated for irondeficiency anaemia now?',
      }}
      content={[
        {
          text: 'Symptoms',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      {...Default.args}
      start
      title={{
        text: 'Are you waiting for tests or the results of tests on connection with this condition?',
      }}
      color={'positive-100'}
      label={{
        text: 'Question (bool)',
        color: 'positive',
      }}
      content={[
        {
          text: 'Symptoms',
          color: 'positive',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      {...Default.args}
      end
      color={'energized'}
      title={{
        text: 'What is your gender?',
      }}
      label={{
        text: 'Question (bool)',
        color: 'energized',
      }}
      content={[
        {
          text: 'Gender',
          color: 'energized',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      {...Default.args}
      unreachable
      color={'royal'}
      tooltipText={'This is unreachable'}
      title={{
        text: 'Was the anaemia caused by pregnancy or abnormal periods/menstruation?',
      }}
      label={{
        text: 'Question (bool)',
        color: 'royal',
      }}
      content={[
        {
          text: 'Reason',
          color: 'royal',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      title={{
        text: 'Empty',
      }}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      title={{
        text: 'Only first content',
      }}
      content={[
        {
          text: 'Symptons',
        },
      ]}
    />
  </>
);
