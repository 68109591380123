import { Controller } from '@hotwired/stimulus';
import turboFetch from 'lib/turbo_fetch';

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    super.connect();
    this._risk_factor = null;
    this._product = null;
  }

  get risk_factor() {
    return this._risk_factor;
  }

  set risk_factor(newRiskFactor) {
    this._risk_factor = newRiskFactor;
  }

  get product() {
    return this._product;
  }

  set product(newProduct) {
    this._product = newProduct;
  }

  // Triggers when a risk factor is chosen
  changeInRiskFactor(event) {
    this.risk_factor = event.detail.value;
    this.updateSet();
  }

  changeInProduct(event) {
    this.product = event.detail.value;
    this.updateSet();
  }

  updateSet() {
    if (
      this.risk_factor !== null &&
      this.product !== null &&
      this.hasUrlValue
    ) {
      turboFetch(
        `${this.urlValue}?risk_factor_id=${this.risk_factor}&product_id=${this.product}`
      );
    } else {
      console.warn(
        'Some values appear to be missing for your fetch request, please check your form.'
      );
    }
  }
}
