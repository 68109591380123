import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import CodeEditor from '@components/form/codeEditor';
import Title from '@components/text/title';
import Button from '@components/button/button';
import Status from '@components/text/status';
import Divider from '@components/ui/divider';
import ModalWrapper from '../modalWrapper';
import styles from './activation.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => (
  prevProps === nextProps
)

const ActivationModal = ({
  className,
  header,
  footer,
  status,
  codeEditor,
  isOpen,
  onClose,
  completions,
  functions,
  activeActivation,
  subsetId,
  setActivation,
  close,
  title,
}) => {
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const [saveMode, setSaveMode] = useState(true);
  const classes = cx(
    {
      activation: true,
    },
    className
  );

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const setContent = (e) => {
    setActivation(e);
  };

  return (
    <div className={classes}>
      <ModalWrapper
        header={header}
        footer={footer}
        isOpen={isOpen}
        onClose={onClose}
        close={close}
      >
        {status && <Status {...status} />}
        {status && codeEditor && <Divider height={10} color={'transparent'} />}
        {codeEditor && (
          <CodeEditor
            completions={completions}
            functions={functions}
            initialValue={activeActivation?.content?.text}
            setContent={setContent}
            setValidity={setSaveMode}
            subsetId={subsetId}
            {...codeEditor}
          />
        )}
      </ModalWrapper>
    </div>
  );
};

ActivationModal.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** Status */
  status: PropTypes.shape(Status.propTypes),
  /** Code editor */
  codeEditor: PropTypes.shape(CodeEditor.propTypes),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Function to trigger when modal closes */
  onClose: PropTypes.func,
  /** The close button of the modal */
  close: PropTypes.bool,
  /** Completions for the editor */
  completions: PropTypes.arrayOf(PropTypes.shape({})),
  /** Functions for the editor */
  functions: PropTypes.arrayOf(PropTypes.shape({})),
  /** Current selected card */
  activeActivation: PropTypes.shape({}),
  /** Sets the correct activation */
  setActivation: PropTypes.func,
};

ActivationModal.defaultProps = {
  className: '',
  header: null,
  footer: null,
  status: null,
  codeEditor: null,
  isOpen: false,
  onClose: null,
  close: true,
  completions: [],
  functions: [],
  activeActivation: {},
  setActivation: null,
};

// Needed for Storybook
ActivationModal.displayName = 'ActivationModal';

export default React.memo(ActivationModal, checkEqual);
