import React, { useState, useEffect } from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames/bind';
import Flatpickr from 'react-flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import Icon from '@components/ui/icon';
import styles from './datepicker.module.scss';

const cx = classNames.bind(styles);

const Datepicker = ({ options, monthSelect, ref, ...props }) => {
  const [locale, setLocale] = useState({});
  const classes = cx(
    {
      datepicker: true,
    },
    props.className
  );
  const innerStyles = cx(
    {
      inner: true,
    },
    props.color
  );

  const _customDataToAttr = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value]));
  const attributes = props.customData
    ? _customDataToAttr(props.customData)
    : [];

  useEffect(() => {
    if (options.locale) {
      const isoLocale =
        options.locale && options.locale != 'en' ? options.locale : 'default';
      if (isoLocale !== 'default') {
        const Locale = require(`flatpickr/dist/l10n/${isoLocale}.js`).default[
          isoLocale
        ];
        setLocale(Locale);
      }
    }
  }, [options]);
  return (
    <Flatpickr
      ref={ref}
      defaultValue={props.placeholder}
      id={props.id}
      name={props.name}
      className={classes}
      value={props.value}
      disabled={props.disabled}
      readOnly={props.readonly}
      options={{
        plugins: monthSelect ? [
          new monthSelectPlugin({
            shorthand: false,
            dateFormat: "Y-m",
            altFormat: "F Y",    
          })
        ] : [],
        ...options,
        locale,
      }}      
      onChange={(data, value, config) => {
        props.onChange && props.onChange(config, value);
      }}
      render={({ defaultValue, value, ...props }, ref) => (
        <div className={innerStyles}>
          <input
            {...props}
            defaultValue={defaultValue}
            ref={ref}
            {...attributes}
          />
          <Icon icon={'calendar'} color={props.color} className={styles.icon} />
        </div>
      )}
    />
  );
};

Datepicker.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['datepicker']).isRequired,
  /** The name of the input */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** Whether the input is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** All Flatpickr options (https://flatpickr.js.org/options/) */
  options: PropTypes.object,
   /** Enables the month view */
  monthSelect: PropTypes.bool,
};

Datepicker.defaultProps = {
  type: 'datepicker',
  name: 'datepicker',
  options: {},
  monthSelect: false,
};

// Needed for Storybook
Datepicker.displayName = 'Datepicker';

export default Datepicker;
