import React from 'react';
import { Divider } from '@components/ui';
import { Title } from '@components/text';
import Select from '.';

const customOptions = require('./customOptions.json');

export default {
  title: 'BRMS/form/Select',
  component: Select,
  parameters: {
    componentSubtitle:
      'Uses React-Select (https://github.com/JedWatson/react-select) under the hood',
  },
};

const Template = (args) => (
  <>
    <Select {...args} />
    <Divider height={100} color={'transparent'} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  id: 'select',
  options: [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ],
};

export const Variants = (args) => (
  <>
    <Title text={'Disabled'} size={'label'} />
    <Select {...Default.args} disabled />
    <Divider height={20} color={'transparent'} />
    <Title text={'Multiple'} size={'label'} />
    <Select {...Default.args} multi />
    <Divider height={20} color={'transparent'} />
    <Title text={'Multiple without remove'} size={'label'} />
    <Select
      {...Default.args}
      multi
      options={[
        {
          value: 'chocolate',
          label: 'Chocolate',
          checked: true,
          isFixed: true,
        },
        {
          value: 'strawberry',
          label: 'Strawberry',
          checked: true,
          isFixed: true,
        },
        { value: 'vanilla', label: 'Vanilla' },
      ]}
    />
    <Divider height={100} color={'transparent'} />
  </>
);

export const limited = () => (
  <>
    <Title text={'Limited to 5 options'} size={'label'} />
    <Select id={'selecty'} options={customOptions} limit={5} />
    <Divider height={50} color={'transparent'} />
  </>
);

export const defaultOption = () => (
  <>
    <Select
      id={'defaultOption'}
      defaultOption={'other'}
      options={[
        {
          value: 'chocolate',
          label: 'Chocolate',
        },
        {
          value: 'strawberry',
          label: 'Strawberry',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ]}
    />
    <Divider height={50} color={'transparent'} />
  </>
);

export const AlwaysShowOptions = () => (
  <>
    <Select
      id={'defaultOption'}
      defaultOption={'other'}
      options={[
        {
          value: 'chocolate',
          label: 'Chocolate',
        },
        {
          value: 'strawberry',
          label: 'Strawberry',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ]}
      hideSelectedOptions={false}
    />
    <Divider height={50} color={'transparent'} />
  </>
);
