import React from 'react';
import ToggleElement from '.';

export default {
  title: 'BRMS/form/ToggleElement',
  component: ToggleElement,
};

const Template = (args) => (
  <>
    <ToggleElement {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  toggle: {
    options: [{ text: 'Option 1' }, { text: 'Option 2' }],
  },
  firstOption: [
    {
      label: 'date',
      type: 'datepicker',
    },
  ],
  secondOption: [
    {
      type: 'text',
      label: 'years',
      name: 'years',
    },
    {
      type: 'text',
      label: 'months',
      name: 'months',
    },
    {
      type: 'text',
      label: 'days',
      name: 'days',
    },
  ],
};
