import React from 'react';
import SwitchElement from '.';

export default {
  title: 'BRMS/form/SwitchElement',
  component: SwitchElement,
};

const Template = (args) => (
  <>
    <SwitchElement {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  modal: {
    id: 'SwitchElementModal',
    elementTrigger: {
      content: {
        text: 'When was this diagnosed?',
      },
      button: {
        icon: {
          icon: 'edit',
        },
      },
      input: {
        id: 'switchElementInput',
        name: 'input',
      },
    },
    header: {
      title: {
        text: 'Modal Wrapper',
      },
    },
    footer: {
      buttonsRight: [
        {
          text: 'Cancel',
          outline: true,
          action: 'close',
        },
        {
          text: 'Save changes',
          action: 'save',
        },
      ],
    },
  },
  label: {
    text: 'Select one of the following options',
  },
  options: [
    { text: 'I know when it was' },
    { text: 'I know how old I was' },
    { text: 'I am not sure' },
  ],
  elements: [
    {
      label: 'Select a date',
      type: 'datepicker',
      name: 'datepicker',
      id: 'datepicker#1',
    },
    {
      label: 'Select one of the options',
      type: 'select',
      name: 'select',
      id: 'select',
      options: [
        {
          value: 'option_1',
          label: 'Option 1',
          name: 'option[name]',
          checked: true,
        },
        {
          value: 'option_2',
          name: 'option[name]',
          label: 'Option 2',
        },
      ],
    },
    {
      label: 'Select one of the options',
      type: 'radio',
      name: 'radio',
      column: true,
      options: [
        {
          text: 'Option 1',
          name: 'option_1',
          value: 'option_1',
          checked: true,
        },
        {
          text: 'Option 2',
          name: 'option_2',
          value: 'option_2',
        },
        {
          text: 'Option 3',
          name: 'option_3',
          value: 'option_3',
        },
      ],
    },
  ],
};

export const withInititalDate = () => (
  <SwitchElement initialDate={'2020-05-20'} {...Default.args} />
);
