import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './list.module.scss';
import Content from '../content';
import Button from '@components/button/button';
import Badge from '@components/badge/badge';

const cx = classNames.bind(styles);

const List = ({ className, items, max, showMoreText, showLessText, separatedValue, showSeparatedValue, row, children }) => {
  const [show, setShow] = useState(true);
  const childrenArray = Children.toArray(children);
  const classes = cx(
    {
      list: true,
      row,
    }, 
    className
  );

  const handleShow = () => {
    setShow(!show);
  }

  // Separates the content.text string into an array based on a separatedValue props.
  let values;
  if(items && separatedValue) {
    values = items[0]?.content?.text.split(separatedValue);
  }

  const separatedValuesArray = values?.map((item, index) => {
    return {
      content: {
        text: item + `${showSeparatedValue && index !== (values.length - 1) ? separatedValue : ''}`
      }
    }
  });

  // ArrayTypes based on certain props:
  // 1. separatedValuesArray: 'alice, bo' => ['alice', 'bob']; Based on the separatedValue props. (i.e. ',')
  // 2. childrenArray: <List><child/></List>; Used for wrapping the List Component around children on react level.
  // 3. items: Default array of objects.
  const arrayType = separatedValue ? separatedValuesArray : children ? childrenArray : items;

  return (
    <ul className={classes}>
      {arrayType?.slice(0, max && show ? max : arrayType.length).map((item, index) => (
        <li key={index} className={styles.item}>
          {!children ? (
            item.button ? <Button link {...item.button} /> : 
            (item.badge ? <Badge {...item.badge} /> : 
            item.content && <Content color={'dark'} {...item.content} />)   
          ) : (
            children && item
          )}        
        </li>
      ))}
      {max && (arrayType?.length > max) && (
        <li>
          <Button link text={show ? showMoreText : showLessText} action={handleShow} className={styles.show} />
        </li>
      )}   
    </ul>
  );
};

List.propTypes = {
  /** An array for the list items */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(Content.propTypes),
      PropTypes.shape(Button.propTypes),
      PropTypes.shape(Badge.propTypes)
    ])
  ),
  /** Show a maximum amount of list items (also triggers the collapse view) */
  max: PropTypes.number,
  /** Custom string for the show more text */
  showMoreText: PropTypes.string,
  /** Custom string for the show less text */
  showLessText: PropTypes.string,
  /** Adds custom styles to the component. Mostly passed when used in another component */
  className: PropTypes.string,
  /** Using separated values such as (',' or '+') */
  separatedValue: PropTypes.string,
  /** Show the separated value */
  showSeparatedValue: PropTypes.bool,
  /** Turn the list into a row */
  row: PropTypes.bool,
  /** Represents the content between the opening and the closing tags of the List component (children) */
  children: PropTypes.node,
};

List.defaultProps = {
  items: null,
  max: null,
  showMoreText: 'Show more',
  showLessText: 'Show less',
  className: '',
  separatedValue: '',
  showSeparatedValue: true,
  row: false,
  children: null,
};

// Needed for Storybook
List.displayName = 'List';

export default List;
