import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import ReactModal from 'react-modal';
import Wrapper from '@components/card/wrapper';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Button from '@components/button/button';
import Divider from '@components/ui/divider';
import Simple from '@components/form/simple';
import styles from './advancedModal.module.scss';

const cx = classNames.bind(styles);

const AdvancedModal = ({
  isOpen,
  header,
  footer,
  color,
  size,
  className,
  trigger,
  elementTrigger,
  elementTriggerValue,
  elementTriggerBackendValue,
  id,
  children,
  placeholder,
}) => {
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const classes = cx(
    {
      advancedModal: true,
    },
    color,
    size,
    className
  );

  const overlayClasses = cx({
    overlay: true,
  });

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {trigger && !elementTrigger && (
        <Button
          className={styles.trigger}
          link
          action={toggleModal}
          {...trigger}
        />
      )}
      {elementTrigger && (
        <div className={styles.elementTrigger}>
          {elementTrigger.content && (
            <Content size={'s'} {...elementTrigger.content} />
          )}
          {elementTrigger.content &&
            (elementTrigger.input || elementTrigger.button) && (
              <Divider height={5} color={'transparent'} />
            )}
          {(elementTrigger.input || elementTrigger.button) && (
            <div className={styles.elementTriggerWrapper}>
              {elementTrigger.input && (
                <Simple
                  className={styles.input}
                  disabled
                  type={'text'}
                  value={placeholder}
                  id={id}
                  {...elementTrigger.input}
                />
              )}
              {elementTrigger.input && (
                <Simple
                  className={styles.input}
                  hidden
                  type={'text'}
                  value={elementTriggerBackendValue || ''}
                  id={id}
                  {...elementTrigger.input}
                />
              )}
              {elementTrigger.button && (
                <Button
                  className={styles.elementTriggerButton}
                  action={() => setIsOpen(true)}
                  link
                  {...elementTrigger.button}
                />
              )}
            </div>
          )}
        </div>
      )}
      <ReactModal
        isOpen={isOpen || modalIsOpen}
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
        portalClassName={'portal-node'}
      >
        <Divider height={20} color={'transparent'} />
        <Wrapper size={header || footer ? 'no-p' : size}>
          {header && (
            <div className={styles.header}>
              {(header.title || header.content) && (
                <div className={styles.headerLeft}>
                  {header.title && <Title size={'h2'} {...header.title} />}
                  {header.content && (
                    <div className={styles.headerContent}>
                      {header.content && (
                        <Content size={'m'} {...header.content} />
                      )}
                    </div>
                  )}
                </div>
              )}
              {header.close && (
                <Button
                  color={'dark'}
                  className={styles.remove}
                  link
                  text={false}
                  icon={{ icon: 'close', width: 28, height: 28 }}
                  action={() => setIsOpen(false)}
                  {...header.close}
                />
              )}
            </div>
          )}
          {children && <div className={styles.body}>{children}</div>}
          {footer && (
            <div className={styles.footer}>
              {footer.buttonsLeft && (
                <div className={styles.buttonsLeft}>
                  {footer.buttonsLeft.map((item, index) => (
                    <Button key={index} className={styles.button} {...item} />
                  ))}
                </div>
              )}
              {footer.buttonsRight && (
                <div className={styles.buttonsRight}>
                  {footer.buttonsRight.map((item, index) => (
                    <Button key={index} className={styles.button} {...item} />
                  ))}
                </div>
              )}
            </div>
          )}
        </Wrapper>
        <Divider height={20} color={'transparent'} />
      </ReactModal>
    </>
  );
};

AdvancedModal.propTypes = {
  /** The color variant of the modal */
  color: PropTypes.oneOf(Colors),
  /** To size of the modal (spacing between modal & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The data for the trigger button */
  trigger: PropTypes.shape(Button.propTypes),
  /** The title inside the modal */
  title: PropTypes.shape(Title.propTypes),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Adds a custom class to the button */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
    content: PropTypes.shape(Content.propTypes),
    close: PropTypes.shape(Button.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
};

AdvancedModal.defaultProps = {
  color: 'light',
  size: 'xl',
  trigger: null,
  isOpen: false,
  className: '',
  header: null,
  footer: null,
};

// Needed for Storybook
AdvancedModal.displayName = 'AdvancedModal';

export default AdvancedModal;
