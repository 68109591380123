import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['orderParam'];

  order(event) {
    event.preventDefault();
    const button = event.currentTarget;
    const column = button.dataset.orderColumn;
    const currentDirection = button.dataset.orderDirection || 'default';

    const directions = ['default', 'asc', 'desc'];
    const nextDirection = directions[(directions.indexOf(currentDirection) + 1) % directions.length];
    button.dataset.orderDirection = nextDirection;

    this.orderParamTarget.value = nextDirection === 'default' ? 'default' : `${column} ${nextDirection}`;
    this.orderParamTarget.form.requestSubmit();
  }
}