import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Element from '@components/form/element';
import styles from './toggleElement.module.scss';

const cx = classNames.bind(styles);

const ToggleElement = ({ className, toggle, firstOption, secondOption }) => {
  const [active, setActive] = useState(false);

  const classes = cx(
    {
      ToggleElement: true,
    },
    className
  );

  const handleClick = () => setActive(!active);

  return (
    <div className={classes}>
      {toggle && <Element action={handleClick} type={'toggle'} {...toggle} />}
      {toggle && <Divider height={10} color={'transparent'} />}
      <div className={styles.section}>
        {active
          ? firstOption?.map((item, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Divider width={10} color={'transparent'} />}
                <Element {...item} />
              </React.Fragment>
            ))
          : secondOption?.map((item, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Divider width={10} color={'transparent'} />}
                <Element {...item} />
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};

ToggleElement.propTypes = {
  className: PropTypes.string,
  toggle: PropTypes.shape({}),
  firstOption: PropTypes.arrayOf(PropTypes.shape({})),
  secondOption: PropTypes.arrayOf(PropTypes.shape({})),
};

ToggleElement.defaultProps = {
  className: '',
  toggle: false,
  firstOption: false,
  secondOption: false,
};

// Needed for Storybook
ToggleElement.displayName = 'ToggleElement';

export default ToggleElement;
