import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './radioSelect.module.scss';
import Group from '../group';

const cx = classNames.bind(styles);

const RadioSelect = (props, { className }) => {
  const classes = cx(
    {
      radioSelect: true,
    },
    className
  );

  return (
    <div className={classes}>
      <Group noWrap type={'radio'} button {...props} />
    </div>
  );
};

RadioSelect.propTypes = {
  /** Adds a custom class */
  className: PropTypes.string,
};

RadioSelect.defaultProps = {
  className: '',
};

// Needed for Storybook
RadioSelect.displayName = 'RadioSelect';

export default RadioSelect;
