import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.scss';
import Detail from '@components/text/detail';

const cx = classNames.bind(styles);

const ExportOverlay = ({ detail, relative, className }) => {
  const classes = cx({
    exportOverlay: true,
    relative,  
  }, className);

  return (
    <Detail
      className={classes}
      {...detail}                    
    />
  );
};

ExportOverlay.propTypes = {
  detail: PropTypes.shape(Detail.propTypes),
  relative: PropTypes.bool,
  className: PropTypes.string,
};

ExportOverlay.defaultProps = {
  detail: null,
  relative: false,
  className: ''
};

export default ExportOverlay;
