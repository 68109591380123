import { Controller } from '@hotwired/stimulus';

// TODO: should get imports for Diff2html from remark-compponents
// https://github.com/rtfpessoa/diff2html?tab=readme-ov-file#stimulusjs-with-typescript

export default class extends Controller {
  static values = { string: String };

  connect() {
    // https://github.com/rtfpessoa/diff2html/blob/master/src/templates/line-by-line-numbers.mustache
    const line_by_line_numbers = `
<div class="line-num2">{{newNumber}}</div>
`;

    // https://github.com/rtfpessoa/diff2html/blob/master/src/templates/line-by-line-file-diff.mustache
    const line_by_line_file_diff = `
<div id="{{fileHtmlId}}" class="d2h-file-wrapper" data-lang="{{file.language}}">
    <div class="d2h-file-diff">
        <div class="d2h-code-wrapper">
            <table class="d2h-diff-table">
                <tbody class="d2h-diff-tbody">
                {{{diffs}}}
                </tbody>
            </table>
        </div>
    </div>
</div>
    `;

    // https://github.com/rtfpessoa/diff2html/blob/master/src/templates/generic-line.mustache
    const generic_line = `
<tr>
    <td class="{{lineClass}} {{type}}">
      {{{lineNumber}}}
    </td>
    <td class="{{type}}">
        <div class="{{contentClass}}">
        {{#prefix}}
            <span class="d2h-code-line-prefix">{{{prefix}}}</span>
        {{/prefix}}
        {{^prefix}}
            <span class="d2h-code-line-prefix">&nbsp;</span>
        {{/prefix}}
        {{#content}}
            <span class="d2h-code-line-ctn">{{{content}}}</span>
        {{/content}}
        {{^content}}
            <span class="d2h-code-line-ctn"><br></span>
        {{/content}}
        </div>
    </td>
</tr>
  `;
    // https://github.com/rtfpessoa/diff2html/blob/master/src/templates/generic-block-header.mustache
    const generic_block_header = ``;

    const configuration = {
      drawFileList: false,
      fileListToggle: false,
      fileListStartVisible: false,
      fileContentToggle: false,
      matching: 'lines',
      outputFormat: 'line-by-line',
      synchronisedScroll: true,
      highlight: true,
      renderNothingWhenEmpty: false,
      rawTemplates: {
        // https://github.com/rtfpessoa/diff2html/tree/master/src/templates
        'line-by-line-numbers': line_by_line_numbers,
        'line-by-line-file-diff': line_by_line_file_diff,
        'generic-line': generic_line,
        'generic-block-header': generic_block_header,
      },
    };

    var diff2htmlUi = new Diff2HtmlUI(
      this.element,
      this.stringValue,
      configuration
    );
    diff2htmlUi.draw();
    diff2htmlUi.highlightCode();
  }
}
