import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import deburr from 'lodash.deburr';
import styles from './asynchronousSelect.module.scss';
import callApi from '../../helpers/api';
import Rails from '@rails/ujs';

const cx = classNames.bind(styles);

const AsynchronousSelect = ({
  options,
  id,
  type,
  name,
  disabled,
  placeholder,
  multi,
  attributes,
  data,
  input,
  onChange,
  noClose,
  value,
  endpoint
}) => {
  const [prevRequest, setPrevRequest] = useState(false);
  const [localValue, setValue] = useState(
    options.filter((prop) => prop.checked)
  );
  const classes = cx({
    'asynchronous-select': true,
  });

  const filterOptions = (inputValue) =>
    options.filter((i) =>
      deburr(i.label.toLowerCase()).includes(deburr(inputValue.toLowerCase()))
    );

  const loadOptions = (inputValue, callback) => {
    if (prevRequest) {
      prevRequest.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;
    
    const res = callApi(endpoint.replace('%query%', inputValue), { signal })
      .then((res) => res.json())
      .then(data => data.answers.filter(item => !item.checked));
    
    setPrevRequest(controller);
    return res;
  };

  const setOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 300);
  };

  const changeValue = (action) => {
    setValue(action);
    if (input && typeof input === 'function') input();
    if (data && Object.entries(data).length > 0) {
      const elem = document.querySelector(`[name="${action.name}"]`);
      elem.setAttribute('value', action.value);
      Object.entries(data).forEach((entry) => {
        elem.setAttribute(`data-${entry[0]}`, entry[1]);
      });
      Rails.fire(elem, 'change');
    }
    if (typeof onChange === 'function') {
      onChange(action, 'asynchronous-select');
    }
  };

  // const onClose = () => {
  //   if (multi && onChange && typeof onChange === 'function') {
  //     onChange(localValue, 'asynchronous-select');
  //   }
  // };

  useEffect(() => {
    setValue(options.filter((prop) => prop.checked));
  }, [options]);

  const shouldIClose = noClose ? false : !attributes || !attributes.form;

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      id={id}
      className={classes}
      classNamePrefix={'asynchronous-select'}
      defaultValue={localValue}
      captureMenuScroll={false}
      type={type}
      name={name}
      isDisabled={disabled}
      placeholder={placeholder}
      options={options}
      isMulti={multi}
      value={localValue}
      form={attributes ? attributes.form : ''}
      closeMenuOnSelect={shouldIClose}
      // onMenuClose={onClose}
      loadOptions={endpoint ? loadOptions : setOptions}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        }),
      }}
      menuPlacement={'auto'}
      onChange={(action, actionType) => {
        changeValue(action, actionType);
      }}
      noOptionsMessage={(e) =>
        e.inputValue == '' ? 'Start typing...' : 'No options'
      }
    />
  );
};

AsynchronousSelect.propTypes = {
  /** The input type */
  type: PropTypes.oneOf(['asynchronous-select']),
  /** The name of the input */
  name: PropTypes.string,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the input is disabled */
  disabled: PropTypes.bool,
  /** A unique id for the input */
  id: PropTypes.string.isRequired,
  /** Ability to close */
  noClose: PropTypes.bool,
  /** Options to chose from */
  options: PropTypes.arrayOf(PropTypes.shape({})),
  /** Allows multiple answers */
  multi: PropTypes.bool,
  /** Allows extra attributes */
  attributes: PropTypes.arrayOf(PropTypes.shape({})),
  /** Additional data */
  data: PropTypes.shape({}),
  /** Input funcitonality */
  input: PropTypes.func,
  /** onChange functionality */
  onChange: PropTypes.func,
  /** Endpoint for retreiving options */
  endpoint: PropTypes.string,
};

AsynchronousSelect.defaultProps = {
  attributes: [{}],
  data: null,
  disabled: false,
  input: null,
  multi: false,
  name: 'asynchronous-select',
  noClose: false,
  onChange: null,
  options: [],
  placeholder: '',
  type: 'asynchronous-select',
  value: '',
  endpoint: false
};

// Needed for Storybook
// AsynchronousSelect.displayName = 'Select';

export default AsynchronousSelect;
