import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { getDirectional } from '@mrblenny/react-flow-chart';
import Badge from '@components/badge/badge';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import styles from './styles.scss';
import { ActivationCard } from '../cards';

const cx = classNames.bind(styles);

const checkEqual = (prev, next) =>
  JSON.stringify(prev) === JSON.stringify(next);

const findCenter = (points) => {
  const coords = points.replace('M', '').split(' ');
  const start = coords[0].split(',');
  const end = coords[coords.length - 1].split(',');

  const centerPosition = {
    x: parseInt(start[0]) + parseInt((end[0] - start[0]) / 2),
    y: parseInt(start[1]) + parseInt((end[1] - start[1]) / 2),
  };
  return centerPosition;
};
/* curve function picked from mrblenny & edited with more logic */
const generateCurvePath = (startPos, endPos, type) => {
  const _a = getDirectional(startPos, endPos);
  const { width, height, leftToRight, topToBottom, isHorizontal } = _a;
  let start;
  let end;
  /* start & end is based on position of cards & position of ports */
  if (isHorizontal && !type === 'bottom') {
    start = leftToRight ? startPos : endPos;
    end = leftToRight ? endPos : startPos;
  } else {
    start = topToBottom ? startPos : endPos;
    end = topToBottom ? endPos : startPos;
  }
  /* trial and error for nice curve */
  const curveBottom = width / 4;
  const curveRight = height / 3;
  const curveY = 0;

  if (type === 'bottom') {
    /* difference is the + & - of the curve calculation */
    if (startPos.y > endPos.y) {
      return `M${start.x},${start.y} C ${start.x + curveY},${
        start.y - curveBottom
      } ${end.x - curveY},${end.y + curveBottom} ${end.x},${end.y}`;
    }
    return `M${start.x},${start.y} C ${start.x + curveY},${
      start.y + curveBottom
    } ${end.x - curveY},${end.y - curveBottom} ${end.x},${end.y}`;
  }
  if (startPos.y > endPos.y) {
    /* difference is the + & - of the curve calculation */
    return `M${start.x},${start.y} C ${start.x - curveRight},${
      start.y + curveY
    } ${end.x + curveRight},${end.y - curveY} ${end.x},${end.y}`;
  }

  return `M${start.x},${start.y} C ${start.x + curveRight},${
    start.y + curveY
  } ${end.x - curveRight},${end.y - curveY} ${end.x},${end.y}`;
};

const CustomLink = inject('data')(
  observer(
    React.forwardRef(
      (
        {
          link,
          config,
          startPos,
          endPos,
          fromPort,
          toPort,
          matrix,
          isHovered,
          isSelected,
          onLinkMouseEnter,
          onLinkMouseLeave,
          onLinkClick,
          data,
        },
        ref
      ) => {
        const points = generateCurvePath(startPos, endPos, fromPort.type);
        const linkColor =
          (toPort && toPort.properties && toPort.properties.linkColor) ||
          'cornflowerblue';
        const selectedColor =
          toJS(data.chart.selected.id) === link.from.nodeId ||
          toJS(data.chart.selected.id) === link.to.nodeId
            ? 'rgba(var(--color-positive)'
            : 'rgba(var(--color-balanced-500)';
        const dashArray =
          (toPort && toPort.properties && toPort.properties.dashArray) || '0px';
        const strokeWidth =
          (toPort && toPort.properties && toPort.properties.strokeWidth) ||
          '2px';
        const center = findCenter(points);
        const linkClick = (e) => {
          // onLinkClick({ config, linkId: link.id });
          e.stopPropagation();
        };
        const linkEnter = (e) => {
          onLinkMouseEnter({ config, linkId: link.id });
        };
        const linkLeave = (e) => {
          onLinkMouseLeave({ config, linkId: link.id });
        };
        return (
          <>
            <svg
              ref={ref}
              style={{
                overflow: 'visible',
                position: 'absolute',
                cursor: 'pointer',
                left: 0,
                right: 0,
              }}
              className={styles.line}
            >
              <circle r='4' cx={startPos.x} cy={startPos.y} fill={linkColor} />
              {/* Main line */}
              <path
                d={points}
                stroke={selectedColor}
                strokeWidth={strokeWidth}
                fill='none'
                strokeDasharray={dashArray}
                strokeLinejoin='round'
                strokeLinecap='round'
              />
              {/* Thick line to make selection easier */}
              <path
                d={points}
                stroke={linkColor}
                strokeWidth='20'
                fill='none'
                strokeLinejoin='round'
                strokeLinecap='round'
                strokeOpacity={isHovered || isSelected ? 0.1 : 0}
                onMouseEnter={(e) => {
                  linkEnter(e);
                }}
                onMouseLeave={(e) => {
                  linkLeave(e);
                }}
              />
              <circle r='4' cx={endPos.x} cy={endPos.y} fill={linkColor} />
            </svg>
            {toPort &&
              toPort.properties &&
              toPort.properties.label &&
              toPort.properties.label.text && (
                <div
                  style={{
                    left: `${center.x}px`,
                    top: `${center.y}px`,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    boxShadow:
                      isHovered || isSelected
                        ? '0 0 0 10px rgba(var(--color-balanced-100), 0.25)'
                        : 'none',
                    borderRadius: 'var(--radius-xs)',
                    cursor: 'pointer',
                  }}
                  className={styles.linkBadge}
                  onMouseEnter={(e) => {
                    linkEnter(e);
                  }}
                  onMouseLeave={(e) => {
                    linkLeave(e);
                  }}
                >
                  {toPort.properties.label.simple ? (
                    <Badge
                      content={{ text: toPort.properties.label.text }}
                      color={'balanced-500'}
                    />
                  ) : (
                    <ActivationCard
                      link={link.id}
                      size={'xxs'}
                      diagram
                      color={'balanced-500'}
                      title={{
                        text: link?.to?.nodeId?.includes('assignment')
                          ? 'Execution condition'
                          : 'Activation condition',
                      }}
                      activation_rule={{
                        rule: toPort.properties.label.text,
                      }}
                      open={false}
                      disabled={config.readonly}
                      buttons={[
                        {
                          outline: false,
                          color: 'transparent',
                          action: () => {
                            onLinkClick({ config, linkId: link.id });
                          },
                          icon: {
                            name: 'edit',
                            color: 'positive',
                            width: 22,
                            height: 22,
                          },
                        },
                        {
                          outline: false,
                          color: 'transparent',
                          action: () => config.deleteNode(link, 'link'),
                          icon: {
                            name: 'trash',
                            color: 'assertive',
                            width: 22,
                            height: 22,
                          },
                        },
                      ]}
                    />
                  )}
                </div>
              )}
          </>
        );
      }
    )
  )
);

CustomLink.propTypes = {
  config: PropTypes.shape({
    deleteNode: PropTypes.func,
  }),
  endPos: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  fromPort: PropTypes.shape({
    type: PropTypes.string,
  }),
  isHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  link: PropTypes.shape({
    id: PropTypes.string,
  }),
  matrix: PropTypes.shape({}),
  onLinkClick: PropTypes.func,
  onLinkMouseEnter: PropTypes.func,
  onLinkMouseLeave: PropTypes.func,
  startPos: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  toPort: PropTypes.shape({
    properties: PropTypes.shape({
      dashArray: PropTypes.string,
      label: PropTypes.shape({
        simple: PropTypes.bool,
        text: PropTypes.string,
      }),
      linkColor: PropTypes.string,
      strokeWidth: PropTypes.number,
    }),
  }),
};

CustomLink.defaultProps = {
  config: {},
  endPos: {},
  fromPort: {},
  isHovered: false,
  isSelected: false,
  link: {},
  matrix: {},
  onLinkClick: null,
  onLinkMouseEnter: null,
  onLinkMouseLeave: null,
  startPos: {},
  toPort: {},
};

export default React.memo(CustomLink, checkEqual);
