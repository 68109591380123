import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import Floating from '.';

export default {
  title: 'BRMS/button/Floating',
  component: Floating,
};

const Template = (args) => <Floating {...args} />;

export const Default = Template.bind({});

Default.args = {
  buttons: [
    {
      text: 'button',
      size: 'm',
    },
    {
      text: 'button',
      size: 'm',
    },
    {
      text: 'button',
      size: 'm',
    },
  ],
};

export const NonSticky = () => (
  <>
    <Floating
      noSticky
      buttons={[{ text: 'No more' }, { text: 'sticky stuff' }]}
    />
  </>
);

export const Filled = () => (
  <>
    <Floating
      noSticky
      filled
      buttons={[{ text: 'No more' }, { text: 'sticky stuff' }]}
    />
  </>
);

const sizes = ['s', 'm', 'l'];

export const Sizes = () => (
  <>
    <Floating buttons={sizes.map((size) => ({ size, text: `${size} size` }))} />
  </>
);

export const Colors = () => (
  <>
    <Floating
      buttons={ColorNames.map((color) => ({
        color,
        text: color.charAt(0).toUpperCase() + color.slice(1),
      }))}
    />
  </>
);

export const IconLeft = () => (
  <>
    <Floating
      buttons={ColorNames.map((color) => ({
        color,
        text: color.charAt(0).toUpperCase() + color.slice(1),
        icon: { icon: 'empty' },
      }))}
    />
  </>
);

export const iconRight = () => (
  <>
    <Floating
      buttons={ColorNames.map((color) => ({
        color,
        text: color.charAt(0).toUpperCase() + color.slice(1),
        icon: { icon: 'empty' },
        iconRight: true,
      }))}
    />
  </>
);
