import React from 'react';
import { Divider } from '@components/ui';
import AssignmentCard from '.';

export default {
  title: 'BRMS/flowchart/cards/AssignmentCard',
  component: AssignmentCard,
};

const Template = (args) => <AssignmentCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Assignment',
  },
  assignment_rows: [
    {
      variable: 'CT',
      operator: '=',
      value: 'true',
    },
    {
      variable: 'CT',
      operator: '=',
      value: 'true',
    },
  ],
};
