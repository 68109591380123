import React from 'react';
import PropTypes from 'prop-types';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '@components/card/wrapper';
import { Button } from '../../../../../packs/rc_v1/main.js';
import styles from './tooltip.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => prevProps === nextProps;

const ToolTip = ({ size, color, className, buttons, children }) => {
  const tooltipClasses = cx({ wrapper: true }, className);
  return (
    <div className={styles.tooltip}>
      <Wrapper size={size} color={color} className={tooltipClasses}>
        {buttons?.map((button, index) => (
          <Button key={index} className={styles.button} {...button} />
        ))}
      </Wrapper>
      {children}
    </div>
  );
};

ToolTip.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The width of the icons */
  icons: PropTypes.arrayOf(PropTypes.string),
  /** The width of the icons */
  iconWidth: PropTypes.number,
  /** The height of the icons */
  iconHeight: PropTypes.number,
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Parent Element, react ref */
  parentElement: PropTypes.element,
  /** Buttons for the tooltip */
  buttons: PropTypes.arrayOf(PropTypes.shape({})),
  /** Additional children */
  children: PropTypes.node,
};

ToolTip.defaultProps = {
  color: 'stable-300',
  size: 'no-p',
  buttons: null,
  className: '',
  children: null,
  parentElement: null,
  iconWidth: 20,
  iconHeight: 20,
  icons: [],
};

// Needed for Storybook
ToolTip.displayName = 'ToolTip';

export default React.memo(ToolTip, checkEqual);
