import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import ActionBranch from './_actionbranch';

import styles from './actiontree.module.scss';

const cx = classNames.bind(styles);

const ActionTree = (data) => {
  const classes = cx({
    actiontree: true,
  });
  return (
    <ul className={classes}>
      <li>
        <ActionBranch {...data} />
      </li>
    </ul>
  );
};

ActionTree.propTypes = {
  /** The action of an item in the tree */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Whether the subtree should be visible on init */
  active: PropTypes.bool,
  /** The text of the item in the tree */
  text: PropTypes.string.isRequired,
  /** All actions in the tree. Each action can contain the url, text, active state & again an array of children */
  childs: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      active: PropTypes.bool,
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({})),
      ]).isRequired,
      childs: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
};

ActionTree.defaultProps = {
  action: null,
  active: false,
  childs: [],
};

// Needed for Storybook
ActionTree.displayName = 'ActionTree';

export default ActionTree;
