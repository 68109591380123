import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Title from '@components/text/title';
import Button from '@components/button/button';
import AjaxSelect from '@components/form/ajaxSelect';
import ModalWrapper from '../modalWrapper';
import styles from './rule.module.scss';

const cx = classNames.bind(styles);

const checkEqual = (prevProps, nextProps) => (
  prevProps === nextProps
)

const RuleModal = ({
  className,
  header,
  footer,
  data,
  isOpen,
  onClose,
  onSelect,
  close,
  url,
}) => {
  const classes = cx(
    {
      activation: true,
    },
    className
  );

  return (
    <div className={classes}>
      <ModalWrapper
        header={header}
        footer={footer}
        isOpen={isOpen}
        onClose={onClose}
        close={close}
      >
        {data && (
          <AjaxSelect placeholder={'Search...'} onSelect={onSelect} url={url} />
        )}
      </ModalWrapper>
    </div>
  );
};

RuleModal.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  /** Header of the modal */
  header: PropTypes.shape({
    title: PropTypes.shape(Title.propTypes),
  }),
  /** Footer of the modal */
  footer: PropTypes.shape({
    buttonsLeft: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    buttonsRight: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  }),
  /** Options for select */
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /** Should the modal be shown on init */
  isOpen: PropTypes.bool,
  /** Function to trigger when modal closes */
  onClose: PropTypes.func,
  /** The close button of the modal */
  close: PropTypes.bool,
  /** Push selected item to top */
  onSelect: PropTypes.func,
};

RuleModal.defaultProps = {
  className: '',
  header: null,
  footer: null,
  isOpen: false,
  onClose: null,
  close: true,
  data: [],
  onSelect: null,
};

// Needed for Storybook
RuleModal.displayName = 'RuleModal';

export default React.memo(RuleModal, checkEqual);
