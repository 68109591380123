export const comparable = (value) => {
  if (value == null) {
    return '';
  }
  let cValue = value.toString();
  cValue =
    {
      true: '1',
      false: '0',
    }[cValue] || cValue;
  return cValue;
};

export const equal = (val1, val2) => {
  return comparable(val1) == comparable(val2);
};
