import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Icon, Divider } from '@components/ui';
import { Content } from '@components/text';
import { Button } from '@components/button';
import styles from './head.module.scss';

const cx = classNames.bind(styles);

const HeadRow = ({ column, params, size, simple }) => {
  const className = cx({
    cell: true,
    simple,
  });
  const iconSortedClass = cx({
    icon: true,
    sorted:
      column.sortable &&
      params &&
      params.s &&
      new RegExp(`\\b${column.sortable}\\b`).test(params.s),
  });
  return (
    <th width={`${size}%`} className={className}>
      <div className={styles.cellcontent}>
        {column.action && (
          <Button color={'balanced-500'} link emphasis={false} {...column} />
        )}
        {!column.action && column.text && (
          <Content color={simple ? 'light' : 'balanced-500'} {...column} />
        )}

        {column.action && (
          <a href={column.action} className={iconSortedClass}>
            <Icon
              icon={'order-up'}
              color={
                params &&
                params.s &&
                params.s.includes(column.sortable) &&
                params.s.includes('asc')
                  ? 'positive'
                  : 'balanced-500'
              }
              width={8}
              height={5}
            />
            <Divider height={1} color={'transparent'} />
            <Icon
              icon={'order-down'}
              color={
                params &&
                params.s &&
                params.s.includes(column.sortable) &&
                params.s.includes('desc')
                  ? 'positive'
                  : 'balanced-500'
              }
              width={8}
              height={5}
            />
          </a>
        )}
      </div>
    </th>
  );
};

HeadRow.propTypes = {
  // Contents of the column
  column: PropTypes.shape({
    sortable: PropTypes.bool,
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    text: PropTypes.string,
  }).isRequired,
  // Add extra parameters
  params: PropTypes.shape({}),
  // Size of the columns
  size: PropTypes.number.isRequired,
  // Simple specific styling
  simple: PropTypes.bool,
};

HeadRow.defaultProps = {
  params: {},
  simple: false,
};

export default HeadRow;
