import React from 'react';
import { Title } from '@components/text';
import AdvancedModal from '.';

export default {
  title: 'BRMS/modal/AdvancedModal',
  component: AdvancedModal,
};

const Template = (args) => <AdvancedModal {...args} />;

export const Default = Template.bind({});

Default.args = {
  trigger: {
    text: 'Open Modal',
  },
  header: {
    title: {
      text: 'AdvancedModal Wrapper',
    },
    close: {
      color: 'dark',
    },
  },
  footer: {
    buttonsLeft: [
      {
        color: 'stable-500',
        outline: true,
        icon: {
          icon: 'trash',
          color: 'assertive',
        },
      },
    ],
    buttonsRight: [
      {
        color: 'stable-500',
        text: 'Cancel',
        textColor: 'positive',
        outline: true,
      },
      {
        text: 'Save changes',
      },
    ],
  },
};

export const WithElementTrigger = () => (
  <AdvancedModal
    elementTrigger={{
      content: {
        text: 'This is a label',
      },
      button: {
        icon: {
          icon: 'edit',
        },
      },
      input: {
        id: 'switchElementInput',
        name: 'input',
      },
    }}
    {...Default.args}
  />
);
