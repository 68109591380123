import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import RadioSelect from '@components/form/radioSelect';
import Element from '@components/form/element';
import Divider from '@components/ui/divider';
import ElementGroup from '@components/form/elementGroup';
import Tooltip from '@components/ui/tooltip';
import styles from './elementValues.module.scss';

const cx = classNames.bind(styles);

const ElementValues = ({
  className,
  select,
  assignableValue,
  assignableErrors,
  radioSelectName,
  radioSelectValue,
  radioSelectErrors,
  min,
  max,
  minMaxLabels,
  elementGroup,
  assignableOptions,
  assignableData,
  disabled,
}) => {
  const currentType = assignableOptions.filter(
    (item) => item.value === assignableValue
  )[0]?.type;

  const currentTooltip = assignableOptions.filter(
    (item) => item.value === assignableValue
  )[0]?.tooltip;

  const [active, setActive] = useState(radioSelectValue === 'values');
  const [elementType, setElementType] = useState(currentType || 'text');
  const [elementTooltip, setElementTooltip] = useState(currentTooltip);
  const [selectedAssignable, setSelectedAssignable] = useState(assignableValue);

  const classes = cx(
    {
      elementValues: true,
    },
    className
  );

  const handleSelect = (e) => {
    setElementType(e.type);
    setSelectedAssignable(e.value);
    setElementTooltip(e?.tooltip);
  };

  const minMaxType = () => {
    if (elementType === 'date') {
      return 'datepicker';
    }
    return 'text';
  };

  const minLabel = minMaxLabels[elementType]?.min;
  const maxLabel = minMaxLabels[elementType]?.max;

  const elementGroupOptions = () => {
    if (elementType === 'multi-select') {
      return assignableData[selectedAssignable]?.options.map((item) => ({
        ...item,
        isFixed: true,
      }));
    }
    return assignableData[selectedAssignable]?.options;
  };

  return (
    <div className={classes}>
      <div className={styles.wrapper}>
        {select && (
          <>
            <div className={styles.selectWrapper}>
              <div className={styles.select}>
                <Element
                  onChange={handleSelect}
                  type={'select'}
                  {...select}
                  options={assignableOptions}
                  value={assignableValue}
                  disabled={disabled}
                  errors={assignableErrors}
                />              
              </div>                          
              <Divider width={10} color={'transparent'} />
            </div>
            {elementTooltip && (
              <Tooltip
                button={{
                  text: '!',
                  rounded: true,
                  size: 'xs',
                  color: 'royal',
                  className: styles.tooltip,
                }}
                trigger={'mouseenter'}
                simple
                {...elementTooltip}
              />
            )}
          </>
        )}
        {radioSelectName &&
          !['select', 'boolean', 'radio', 'switch-element'].includes(elementType) && (
            <div className={styles.radioSelect}>
              <RadioSelect
                options={[
                  {
                    onChange: () => setActive(!active),
                    label: 'Range',
                    name: radioSelectName,
                    value: 'range',
                    checked: radioSelectValue === 'range',
                    disabled,
                  },
                  {
                    onChange: () => setActive(!active),
                    label: 'Values',
                    name: radioSelectName,
                    value: 'values',
                    checked: radioSelectValue === 'values',
                    disabled,
                  },
                ]}
                errors={radioSelectErrors}
              />
            </div>
          )}
        {!active && !['select', 'boolean', 'radio', 'switch-element'].includes(elementType) && (
          <div className={styles.range}>
            {min && (
              <div className={styles.minWrapper}>
                <Divider width={10} color={'transparent'} />
                <div className={styles.min}>
                  <Element
                    {...min}
                    type={minMaxType()}
                    label={minLabel}
                    value={
                      isNaN(assignableData[selectedAssignable]?.min)
                        ? min.value
                        : assignableData[selectedAssignable]?.min
                    }
                    disabled={disabled}
                  />
                </div>
              </div>
            )}
            {max && (
              <div className={styles.maxWrapper}>
                <Divider width={10} color={'transparent'} />
                <div className={styles.max}>
                  <Element
                    {...max}
                    type={minMaxType()}
                    label={maxLabel}
                    value={
                      isNaN(assignableData[selectedAssignable]?.max)
                        ? max.value
                        : assignableData[selectedAssignable]?.max
                    }
                    disabled={disabled}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {elementGroup &&
        (active ||
          ['select', 'boolean', 'radio', 'switch-element'].includes(elementType)) && (
          <>
            <Divider height={10} color={'transparent'} />
            <ElementGroup
              type={elementType}
              {...elementGroup}
              options={elementGroupOptions()}
              disabled={disabled}
              remove={elementType !== 'radio'}
              multi={elementType === 'multi-select'}
            />
          </>
        )}
    </div>
  );
};

ElementValues.propTypes = {
  /** Custom classes */
  className: PropTypes.string,
  radioSelectName: PropTypes.string,
  select: PropTypes.shape(Element.propTypes),
  min: PropTypes.shape(Element.propTypes),
  max: PropTypes.shape(Element.propTypes),
  elementGroup: PropTypes.shape(ElementGroup.propTypes),
  assignableOptions: PropTypes.arrayOf(PropTypes.shape({})),
  assignableData: PropTypes.shape({}),
  radioSelectValue: PropTypes.string,
  minMaxLabels: PropTypes.shape({}),
  assignableValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assignableErrors: PropTypes.arrayOf(PropTypes.shape({})),
  radioSelectErrors: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
};

ElementValues.defaultProps = {
  className: '',
  select: {
    type: 'select',
    name: 'assignable_id',
    label: 'Rule/Variable',
  },
  radioSelectName: 'kind',
  min: {
    type: 'text',
    name: 'min',
    label: 'Minimum',
    value: null,
  },
  max: {
    type: 'text',
    name: 'max',
    label: 'Maximum',
    value: null,
  },
  elementGroup: null,
  assignableOptions: null,
  assignableData: null,
  radioSelectValue: 'range',
  minMaxLabels: null,
  assignableValue: 0,
  assignableErrors: null,
  radioSelectErrors: null,
  disabled: false,
};

// Needed for Storybook
ElementValues.displayName = 'ElementValues';

export default ElementValues;
