import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tooltip'];
  toggle() {
    this.element.classList.toggle('collapsed');
    const tooltipEnabled = this.element.classList.contains('collapsed');
    if (this.hasTooltipTarget) {
      this.tooltipTargets.forEach((target) => {
        if (tooltipEnabled) {
          target.removeAttribute('disabled');
        } else {
          target.setAttribute('disabled', 'true');
        }
      });
    }
  }
}
