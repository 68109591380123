import React from 'react';
import Collapsible from '.';

export default {
  title: 'BRMS/table/Collapsible',
  component: Collapsible,
};

const Template = (args) => (
  <>
    <Collapsible {...args} />
  </>
);

export const Default = Template.bind({});
const row = [
  {
    data: [
      {
        action: '#',
        text: 'First lane',
      },
      {
        text: 'Ben Smith',
      },
      {
        text: '14-07-2020',
      },
      {
        action: 'url',
        color: 'transparent',
        textColor: 'assertive',
        icon: { icon: 'trash' },
      },
    ],
  },
  {
    data: [
      {
        action: '#',
        text: 'Sick leave',
        badges: [
          {
            text: 'Illness',
            type: 'text',
          },
          {
            text: '==',
            type: 'operator',
          },
          {
            text: 'True',
            type: 'boolean',
          },
        ],
      },
      {
        action: '#',
        text: 'Edit',
        link: false,
        size: 'xs',
      },
    ],
  },
  {
    data: [
      {
        action: '#',
        text: 'Broken bones',
        badges: [
          {
            text: 'with_illness',
            type: 'text',
          },
          {
            text: '==',
            type: 'operator',
          },
          {
            text: 'broken_bones',
            type: 'text',
          },
        ],
      },
      {
        action: '#',
        text: 'Edit',
        link: false,
        size: 'xs',
      },
    ],
  },
];
Default.args = {
  sizes: [50, 20, 20, 5, 5],
  head: [
    {
      action: '?q=x&y=z',
      text: 'Rules',
    },
    {
      action: '?q=x&y=z',
      text: 'Kind',
    },
    {
      action: '?q=x&y=z',
      text: 'Category',
    },
    {},
  ],
  bodies: [
    [
      {
        data: [
          {
            action: '/admin/rulesets/2/subsets/12',
            text: 'Subset with subset activation rule',
          },
          { text: '3 rules' },
          {
            action: '/admin/subset_rules/2.12',
            customData: { 'data-method': 'delete' },
            color: 'transparent',
            textColor: 'assertive',
            icon: { icon: 'trash' },
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/198/edit',
            text: 'subset_activation_question_1',
            badges: [],
          },
          {
            action: '/admin/subset_rules/198/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/199/edit',
            text: 'normal_question_1',
            badges: [
              {
                text: 'subset_with_subset_activation_rule.subset_activation_question_1',
                type: 'text',
              },
              { text: '!=', type: 'operator' },
              { text: 'null', type: 'boolean' },
            ],
          },
          {
            action: '/admin/subset_rules/199/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/200/edit',
            text: 'subset_activation_question_2',
            badges: [
              {
                text: 'subset_with_subset_activation_rule.normal_question_1',
                type: 'text',
              },
              { text: '!=', type: 'operator' },
              { text: 'null', type: 'boolean' },
            ],
          },
          {
            action: '/admin/subset_rules/200/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
    ],
    [
      {
        data: [
          {
            action: '/admin/rulesets/2/subsets/13',
            text: 'Normal second subset',
          },
          { text: '1 rules' },
          {
            action: '/admin/subset_rules/2.13',
            customData: { 'data-method': 'delete' },
            color: 'transparent',
            textColor: 'assertive',
            icon: { icon: 'trash' },
          },
        ],
      },
      {
        data: [
          {
            action: '/admin/subset_rules/201/edit',
            text: 'normal_question_2',
            badges: [],
          },
          {
            action: '/admin/subset_rules/201/edit',
            text: 'Edit',
            link: false,
            size: 'xs',
          },
        ],
      },
    ],
  ],
};
