import React from 'react';
import { Divider } from '@components/ui';
import CollapsibleCard from '.';

export default {
  title: 'BRMS/card/CollapsibleCard',
  component: CollapsibleCard,
};

const Template = (args) => <CollapsibleCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  open: false,
  items: [
    {
      text: 'Basic questionaire',
    },
    {
      text: 'Life insurance',
      action: '#',
    },
    {
      text: 'Health insurance',
      action: '#',
    },
  ],
};

export const Variants = () => (
  <>
    <CollapsibleCard
      open={false}
      items={[
        {
          text: 'Without actions',
        },
        {
          text: 'Life insurance',
        },
        {
          text: 'Health insurance',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <CollapsibleCard
      open
      items={[
        {
          text: 'Without actions & open',
        },
        {
          text: 'Life insurance',
        },
        {
          text: 'Health insurance',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <CollapsibleCard
      open
      items={[
        {
          text: 'Mixed actions',
        },
        {
          text: 'Life insurance',
          action: '#',
        },
        {
          text: 'Health insurance',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <CollapsibleCard
      open
      items={[
        {
          text: 'Collapse activated by action',
          action: '#',
        },
        {
          text: 'Life insurance',
          action: '#',
        },
        {
          text: 'Health insurance',
        },
      ]}
    />
  </>
);
