import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    completedUrl: String
  };

  connect() {
    document.addEventListener('rmv-widget:completed', (e) => {
      get(this.completedUrlValue, {
        responseKind: 'turbo-stream',
      });
    });
  }
}
