import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './drag-and-drop.module.scss';

const cx = classNames.bind(styles);
const DragAndDrop = ({
  className,
  children,
  selector,
  orderField,
  ...props
}) => {
  const divWrapper = useRef();
  const [items, setItems] = useState(children);
  const [newItems, setNewItems] = useState([]);
  const [inputRefs, setInputRefs] = useState([]);
  const [targetUndraggable, setTargetUndraggable] = useState(false);

  const classes = cx(
    {
      _self: true,
    },
    className
  );

  useEffect(() => {
    // select the parent div of which the children should be draggable
    const identifier = selector.startsWith('#') ? selector : `#${selector}`;
    const targetNode = document.querySelector(identifier);
    let observer = null;

    if (targetNode) {
      const childs = Array.from(targetNode.children).filter(
        (item) => item.tagName.toLowerCase() === 'div'
      );
      setInputRefs(childs.map(() => React.createRef()));
      setItems(childs);

      // add an observer to listen for newly added children to above selected parent
      const config = { attributes: false, childList: true, subtree: false };
      const callback = (mutationsList, obs) => {
        const newChilds = Array.from(
          document.querySelector(identifier).children
        ).filter((item) => item.tagName.toLowerCase() === 'div');
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'childList') {
            if (newChilds.length > 0) {
              setNewItems(newChilds);
            }
          }
        });
      };
      observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [selector]);

  useEffect(() => {
    if (newItems.length > 0) {
      const finalItems = [...items, ...newItems];
      setInputRefs(finalItems.map(() => React.createRef()));
      setItems(finalItems);
    }
  }, [newItems]);

  useEffect(() => {
    if (inputRefs.length == items.length) {
      items.forEach((item, i) => {
        inputRefs[i].current.innerHTML = '';
        inputRefs[i].current.appendChild(item);
        if (orderField && inputRefs[i].current.querySelector(orderField)) {
          inputRefs[i].current.querySelector(orderField).value = i;
        }
        if (i === inputRefs.length - 1 && divWrapper && divWrapper.current) {
          divWrapper.current.style.height = 'auto';
        }
      });
    }
  }, [items]);

  const onDragEnd = (result) => {
    if (divWrapper && divWrapper.current) {
      divWrapper.current.style.height = `${divWrapper.current.offsetHeight}px`;
    }
    if (!result.destination) return;
    const mutated = Array.from(items);
    const [reordered] = mutated.splice(result.source.index, 1);
    mutated.splice(result.destination.index, 0, reordered);
    inputRefs.forEach((ref, index) => {
      inputRefs[index].current.innerHTML = '';
    });
    setItems(mutated);
  };

  const renderItem = (item, index) => <div ref={inputRefs[index]} />;

  const onPointerDown = (event) => {
    if (event.target.classList.contains('ace_content')) {
      setTargetUndraggable(true);
    } else {
      setTargetUndraggable(false);
    }
  };

  const onPointerUp = () => {
    setTargetUndraggable(false);
  };

  return (
    <div
      ref={divWrapper}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      <DragDropContext className={classes} onDragEnd={onDragEnd}>
        <Droppable droppableId='dnd_droppable'>
          {(droppableProvided, droppableSnapshot) => {
            const droppableClasses = cx({
              droppable: true,
              dragging: droppableSnapshot.isDraggingOver,
            });
            return (
              <div
                ref={droppableProvided.innerRef}
                className={droppableClasses}
                {...droppableProvided.droppableProps}
              >
                {items.length > 0 &&
                  items.map((item, index) => {
                    const draggableClasses = cx({
                      draggable: true,
                    });
                    return (
                      <Draggable
                        key={`item_${index}`}
                        draggableId={`key_${index}`}
                        index={index}
                        isDragDisabled={targetUndraggable}
                      >
                        {(provided, snapshot) =>
                          useMemo(() => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.draggableWrapper}
                            >
                              <div className={draggableClasses}>
                                {renderItem(item, index)}
                              </div>
                            </div>
                          ))
                        }
                      </Draggable>
                    );
                  })}
                {droppableProvided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

DragAndDrop.propTypes = {
  /** The items to drag & drop */
  children: PropTypes.arrayOf(PropTypes.shape({})),
  /** Classname to add to the wrapper */
  className: PropTypes.string,
  /** The id of the parent div, containing all draggable childs */
  selector: PropTypes.string,
  /** The selector of the field the order is stored in, normally a hidden input */
  orderField: PropTypes.string,
};

DragAndDrop.defaultProps = {
  children: [],
  className: null,
  selector: null,
  orderField: null,
};

// Needed for Storybook
DragAndDrop.displayName = 'DragAndDrop';

export default DragAndDrop;
