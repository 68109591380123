import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './styles.scss';

const cx = classNames.bind(styles);

// If true, don't trigger rerender
const checkEqual = (prev, next) => prev === next;

const CustomNode = React.forwardRef(({ children, ...otherProps }, ref) => {
  if (otherProps.node.hidden) {
    return <div />;
  }

  const muteableProps = otherProps;
  const nodeClasses = cx({
    node: true,
    'react-draggable': true,
    nodeSelected: muteableProps.isSelected,
    questionCard: otherProps.node.component === 'QuestionCard',
  });
  delete muteableProps.isSelected;
  delete muteableProps.config;
  delete muteableProps.node;
  return (
    <div
      ref={ref}
      {...muteableProps}
      className={nodeClasses}
      aria-hidden='true'
    >
      {children}
    </div>
  );
});

CustomNode.propTypes = {
  children: PropTypes.node,
};

CustomNode.defaultProps = {
  children: null,
};

export default React.memo(CustomNode, checkEqual);
