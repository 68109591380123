import React from 'react';
import { Colors as ColorNames } from '@components/variables';
import { Content } from '@components/text';
import Icon from '.';

const iconNames = require('./icons.json');

export default {
  title: 'BRMS/ui/Icon',
  component: Icon,
};

const Template = (args) => <Icon {...args} />;

export const Default = Template.bind({});
Default.args = {
  icon: 'empty',
};

export const Colors = () => (
  <>
    {ColorNames.map((color, index) => (
      <Icon key={index} icon={'empty'} color={color} />
    ))}
  </>
);

export const Available = () => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {iconNames.children.map((icon, index) => {
      const name = icon.name.replace('.svg', '');
      const w = 22;
      const h = 22;
      return (
        <div key={index}>
          <div
            style={{
              width: 100,
              height: 100,
              margin: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid rgba(var(--color-stable-500), 1)',
            }}
          >
            <Icon icon={name} width={w} height={h} />
          </div>
          <Content text={name} align={'center'} size={'s'} />
        </div>
      );
    })}
  </div>
);

export const iconTitle = () => (
  <div>
    <Icon icon={'checkmark'} title={{ text: 'checkmark' }} color={'positive'} />
  </div>
);

export const iconTitleAndBackground = () => (
  <div>
    <Icon
      icon={'checkmark'}
      title={{ text: 'checkmark' }}
      color={'positive'}
      background
    />
  </div>
);

export const inlineSvg = () => (
  <div>
    <Icon
      inlineSvg={
        '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.938 48.438c6.903 0 12.5-5.597 12.5-12.5 0-6.904-5.597-12.5-12.5-12.5-6.904 0-12.5 5.596-12.5 12.5 0 6.903 5.596 12.5 12.5 12.5zm0-18.75v12.5m-6.25-6.25h12.5" stroke="currentColor" stroke-width="3.125" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.188 42.188h-12.5a3.125 3.125 0 01-3.125-3.125V4.688a3.125 3.125 0 013.125-3.125H26.83c.829 0 1.623.329 2.209.914l5.983 5.984c.585.585.914 1.38.914 2.208v6.519" stroke="currentColor" stroke-width="3.125" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      }
    />
  </div>
);
