import React from 'react';
import List from '.';

export default {
  title: 'BRMS/text/List',
  component: List,
};

const Template = (args) => <List {...args} />;

export const Default = Template.bind({});
Default.args = {
  items: [
    {
      content: {
        text: 'first',
      },
    },
    {
      content: {
        text: 'second',
      },
    },
    {
      content: {
        text: '3rd',
      },
    },
    {
      content: {
        text: 'fourth',
      },
    },
  ],
};

export const Children = () => (
  <>
    <List max={2}>
      <p>Child 1</p>
      <p>Child 2</p>
      <p>Child 3</p>
    </List>
  </>
);

export const ContentAndMax = () => (
  <>
    <List max={2} {...Default.args} />
  </>
);

export const ButtonAndMax = () => (
  <>
    <List
      max={2}
      {...Default.args}
      items={[
        {
          button: {
            text: 'first',
          },
        },
        {
          button: {
            text: 'second',
          },
        },
        {
          button: {
            text: '3rd',
          },
        },
        {
          button: {
            text: 'fourth',
          },
        },
      ]}
    />
  </>
);

export const BadgeAndMax = () => (
  <>
    <List
      max={2}
      {...Default.args}
      items={[
        {
          badge: {
            content: {
              text: 'first',
            },
          },
        },
        {
          badge: {
            content: {
              text: 'second',
            },
          },
        },
        {
          badge: {
            content: {
              text: '3rd',
            },
          },
        },
        {
          badge: {
            content: {
              text: 'fourth',
            },
          },
        },
      ]}
    />
  </>
);

export const ShowMoreAndShowLessText = () => (
  <>
    <List
      max={2}
      showMoreText={'Show'}
      showLessText={'Hide'}
      {...Default.args}
    />
  </>
);

export const separatedValue = () => (
  <>
    <List
      max={2}
      separatedValue={','}
      {...Default.args}
      items={[
        {
          content: {
            text: 'first, second, third, fourth',
          },
        },
      ]}
    />
  </>
);

export const separatedValueHidden = () => (
  <>
    <List
      max={2}
      separatedValue={','}
      showSeparatedValue={false}
      {...Default.args}
      items={[
        {
          content: {
            text: 'first, second, third, fourth',
          },
        },
      ]}
    />
  </>
);

export const separatedValueRow = () => (
  <>
    <List
      row
      max={2}
      separatedValue={','}
      {...Default.args}
      items={[
        {
          content: {
            text: 'first, second, third, fourth',
          },
        },
      ]}
    />
  </>
);
