import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../table/table.module.scss';
import { BodyRow, HeadRow } from '../_row';

const cx = classNames.bind(styles);

const getListStyle = (isDraggingOver) => ({
  backgroundColor: isDraggingOver && 'rgba(var(--color-stable-500),1)',
});

const getItems = (rows) =>
  rows.map((row, i) => {
    row.data.unshift({
      action: 'url',
      color: 'transparent',
      textColor: 'balanced-500',
      icon: { icon: 'drag-handle' },
    });
    return {
      id: `item-${i}`,
      ...row,
    };
  });

const DraggableTable = ({
  body,
  className,
  color,
  head,
  inverse,
  params,
  sizes,
  orderEndpoint
}) => {
  const classes = cx(color, className, {
    table: true,
    inverse,
    draggable: true
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(getItems(body));
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const newIndex = result.destination.index
    items.splice(newIndex, 0, reorderedItem);
    setRows(items);

    if (orderEndpoint) {
      fetch(orderEndpoint, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: items.map(row => row.id) })
      });
    }
  };

  const _renderHead = (data) => {
    return (
      <tr>
        <HeadRow
          key={'drag'}
          size={5}
          column={{
            sortable: false,
            action: '',
            text: ''
          }}
        />
        {data.map((column, i) => {
          return (
            <HeadRow
              key={i}
              column={column}
              params={params}
              size={i < sizes.length - 1 ? sizes[i] : 5}
            />
          );
        })}
      </tr>
    );
  };

  const _renderRow = (row, index, provided, snapshot) => {
    return (
      <tr
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={snapshot.isDragging ? styles.dragging : undefined}
      >
        {row.data.map((cell, i) => {
          return (
            <BodyRow
              key={i}
              index={index}
              column={cell}
              tooltipText={row.tooltipText}
              unreachable={row.unreachable}
              size={i == 0 ? 5 : i - 1 < sizes.length ? sizes[i - 1] : 5}
            />
          );
        })}
      </tr>
    );
  };

  return (
    <table className={classes} cellSpacing='0' cellPadding='0'>
      {head && <thead>{_renderHead(head)}</thead>}
      {body && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='tabletable'>
            {(droppableProvided, snapshotDroppable) => (
              <tbody
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                style={getListStyle(snapshotDroppable.isDraggingOver)}
              >
                {rows.map((row, index) => (
                  <Draggable
                    display={'table'}
                    draggableId={`table_${row.id}`}
                    key={row.id}
                    index={index}
                  >
                    {(provided, snapshot) =>
                      _renderRow(row, index, provided, snapshot)
                    }
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </table>
  );
};

DraggableTable.propTypes = {
  body: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  head: PropTypes.arrayOf(PropTypes.shape({})),
  inverse: PropTypes.bool,
  params: PropTypes.shape({}),
  sizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  orderEndpoint: PropTypes.string
};

DraggableTable.defaultProps = {
  className: '',
  color: '',
  head: [],
  inverse: false,
  params: {},
  orderEndpoint: false
};

// Needed for Storybook
DraggableTable.displayName = 'DraggableTable';

export default DraggableTable;
