import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import { Colors } from '@components/variables';
import classNames from 'classnames/bind';
import styles from './purify.module.scss';

const cx = classNames.bind(styles);

const Purify = ({ html, className, size, color }) => {
  const classes = cx(
    {
      _self: true,
    },
    color,
    size,
    className
  );

  const clean = DOMPurify.sanitize(html);
  return (
    <div className={classes} dangerouslySetInnerHTML={{ __html: clean }} />
  );
};

Purify.propTypes = {
  /** String with html in it */
  html: PropTypes.string,
  /** The color of the text */
  color: PropTypes.oneOf(Colors),
  /** The size of the text */
  size: PropTypes.oneOf(['body', 'label']),
  /** Overhauling className */
  className: PropTypes.string,
};

Purify.defaultProps = {
  html: '',
  className: null,
  size: null,
  color: null,
};

// Needed for Storybook
Purify.displayName = 'Purify';

export default Purify;
