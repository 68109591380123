import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hiddenField', 'folder', 'confirmation', 'foldersList']

  initialize() {
    if (!this.element.hasAttribute('hidden')) {
      this.element.click();
    }
    
  }

  hideModal() {
    const frame = this.element.closest('turbo-frame[id="modal"]');

    //remove the src
    frame.removeAttribute('src');

    // Remove each child element inside the turbo-frame
    Array.from(frame.children).forEach((childElement) => childElement.remove());
  }

  // hide modal when ESC is pressed
  closeWithKeyboard(e) {
    if (e.code == 'Escape') {
      this.hideModal();
    }
  }

  // hide modal when background is clicked
  closeBackground(e) {
    if (e && this.element.contains(e.target)) {
      return;
    }
    this.hideModal();
  } 

  selectFolder(event) {
    const folderId = event.currentTarget.getAttribute('data-folder-id');
     // This line
    this.hiddenFieldTarget.value = folderId;
    this.folderTargets.forEach(folder => {
      folder.classList.remove('selected');
    });
    event.currentTarget.classList.add('selected'); 
    
  }
      }