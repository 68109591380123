import React from 'react';
import Markdown from '.';

export default {
  title: 'BRMS/text/Markdown',
  component: Markdown,
  parameters: {
    componentSubtitle:
      'Uses React-Markdown (https://github.com/remarkjs/react-markdown) under the hood',
  },
};

const Template = (args) => <Markdown {...args} />;

export const Default = Template.bind({});
Default.args = {
  body: `A paragraph with *emphasis* and **strong importance**`,
};

const variants = `A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a [link](https://www.remarkgroup.com).

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
`;

export const Variants = () => (
  <>
    <Markdown body={variants} />
  </>
);
