import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Title, Content } from '@components/text';
import Icon from '../icon';
import Divider from '../divider';
import styles from './blank.module.scss';

const cx = classNames.bind(styles);

const Blank = ({ className, icon, title, content }) => {
  const classes = cx(
    {
      _self: true,
    },
    className
  );

  return (
    <div className={classes}>
      {icon && (
        <div className={styles.icon}>
          <Icon color={'balanced-500'} width={40} height={40} {...icon} />
        </div>
      )}
      {title && icon && <Divider height={20} color={'transparent'} />}
      {title && <Title align="center" className={styles.title} color={'balanced-500'} {...title} />}
      {title && content && <Divider height={5} color={'transparent'} />}
      {content && <Content emphasis align="center" className={styles.content} {...content} />}
    </div>
  );
};

Blank.propTypes = {
  /** The Icon of the blank slate */
  icon: PropTypes.shape(Icon.propTypes),
  /** The Title of the blank slate */
  title: PropTypes.shape(Title.propTypes),
  /** The Contetn of the blank slate */
  content: PropTypes.shape(Content.propTypes),
  /** Overhauling className */
  className: PropTypes.string,
};

Blank.defaultProps = {
  icon: PropTypes.shape(Icon.propTypes),
  title: PropTypes.shape(Title.propTypes),
  content: PropTypes.shape(Content.propTypes),
  className: null,
};

// Needed for Storybook
Blank.displayName = 'Blank';

export default Blank;
