import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Alignments, Sizes } from '@components/variables';
import styles from './content.module.scss';

const cx = classNames.bind(styles);

const Content = ({
  emphasis,
  size,
  color,
  align,
  className,
  text,
  children,
  ...props
}) => {
  const classes = cx(
    {
      content: true,
      emphasis,
      [`font-size-${size}`]: size,
    },
    color,
    align,
    className
  );

  return (
    <p className={classes} {...props}>
      {text}
      {children}
    </p>
  );
};

Content.propTypes = {
  /** The text of the content */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The color of the content */
  color: PropTypes.oneOf(Colors),
  /** The alignment of the content */
  align: PropTypes.oneOf(Alignments),
  /** The size of the content */
  size: PropTypes.oneOf(Sizes),
  /** Whether the font-weight should be 500 instead of 400 */
  emphasis: PropTypes.bool,
  /** Overhauling className */
  className: PropTypes.string,
  /** Represents the content between the opening and the closing tags (children) */
  children: PropTypes.node,
};

Content.defaultProps = {
  text: '',
  align: 'left',
  color: 'balanced-500',
  size: 'm',
  emphasis: false,
  className: '',
};

// Needed for Storybook
Content.displayName = 'Content';

export default Content;
