import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    disableToggle() {
        // Note that this is a quick fix for the manual answers table.
        // Currently whenever you toggle between draggable and alphabetic sorting, the whole page will reload,
        // and thus everything you just changed/added/removed will be reset.
        // This should be fixed whenever the `C.lib_element` gets replaced with a turbo-frame. (the radio button to toggle between them)
        // but for now we disabled this toggle to reduce the damage this reload can cause
        const dragRadio = document.getElementById('sort_toggle draggable');
        const AlphabeticRadio = document.getElementById('sort_toggle alphabetically');
        dragRadio.disabled = true;
        AlphabeticRadio.disabled = true;
    }
}
