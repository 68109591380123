import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'button'];

  regenerateApiKey() {
    // Generate a random hexadecimal string (example: 32 characters long)
    const newKey = [...Array(32)]
      .map(() => Math.floor(Math.random() * 32).toString(32))
      .join('');
    const submissionInput = this.inputTargets;
    if (submissionInput.length > 0) {
      submissionInput.forEach((input) => {
        input.value = newKey;
      });
    }
    const copyButton = this.buttonTarget;
    if (copyButton) {
      copyButton.setAttribute('value', newKey);
    }
  }
}
