import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Content from '@components/text/content';
import styles from './conversion.module.scss';

const cx = classNames.bind(styles);

const Conversion = (
  {
    value,
    onChange,
    readonly,
    className,
    attributes,
    quantity,
    strings,
    placeholder,
    min,
    max,
    errorValues,
  },
  ...props
) => {
  // All possible conversions, set the origin value always as index 0
  const possibleConversions = {
    length: [
      { unit: 'cm', factor: 1, value: 0, reverseFactor: 2.54 },
      { unit: 'feet', factor: 0.393701, value: 0 },
      { unit: 'inch', factor: 12, value: 0 },
    ],
    weight: [
      { unit: 'kg', factor: 1, value: 0, reverseFactor: 0.45359237 },
      { unit: 'stone', factor: 2.20462262, value: 0 },
      { unit: 'pounds', factor: 14, value: 0 },
    ],
    amount_of_substance: [
      { unit: 'mmol', factor: 1, value: 0, reverseFactor: 0.0555 },
      { unit: 'mg_dl', factor: 18.018, value: 0 },
    ],
  };

  const calculateTransformed = (n, first, second = 1) => {
    // First get the total value
    const total = (n * first) / second;
    // Get the largest factor
    const large = Math.floor(total);
    // Get the smallest factor
    const small = (total - large) * second;
    return { large, small };
  };

  const calculateOrigin = (n, table) => {
    // Reduce large number to small
    let transformed = table[1].value * (table[2]?.factor || 1);
    // Add small number to large
    transformed += Number(table[2]?.value || 0);
    // Convert to origin
    transformed *= Number(table[0].reverseFactor);
    return transformed;
  };

  const [conversionTable, setConversionTable] = useState(
    possibleConversions[quantity]
  );

  const classes = cx(
    {
      input: true,
    },
    className
  );

  const convertValues = (v, option) => {
    if (min || max) {
      if (conversionTable.length > 2) {
        const transformedMinValues = calculateTransformed(
          min,
          conversionTable[1]?.factor,
          conversionTable[2]?.factor
        );
        const transformedMaxValues = calculateTransformed(
          max,
          conversionTable[1]?.factor,
          conversionTable[2]?.factor
        );

        errorValues({
          convertedMin:
            option.factor === 1
              ? min * option.factor
              : `${transformedMinValues?.large}\`${
                  Math.round(transformedMinValues?.small * 100) / 100
                }`,
          convertedMax:
            option.factor === 1
              ? max * option.factor
              : `${transformedMaxValues?.large}\`${
                  Math.round(transformedMaxValues?.small * 100) / 100
                }`,
        });
      } else {
        const transformedMinValues = calculateTransformed(
          min,
          option.factor === 1 ? option.factor : conversionTable[1]?.factor
        );
        const transformedMaxValues = calculateTransformed(
          max,
          option.factor === 1 ? option.factor : conversionTable[1]?.factor
        );

        errorValues({
          convertedMin: transformedMinValues?.large,
          convertedMax: transformedMaxValues?.large,
        });
      }
    }
    onChange({
      target: {
        value: v,
      },
    });
  };

  const updateTable = (option, e) => {
    const mutatedTable = conversionTable.map((o) => {
      if (o.unit === option.unit) {
        return {
          ...o,
          value: Number(e.target.value),
        };
      }
      // All other inputs
      return { ...o };
    });
    if (mutatedTable.length > 2) {
      // When the origin value is entered, other values need to be converted
      if (option.factor === 1) {
        const values = calculateTransformed(
          Number(e.target.value),
          conversionTable[1].factor,
          conversionTable[2].factor
        );
        mutatedTable[1].value = values.large;
        mutatedTable[2].value = values.small;
      }
      // When other values are added, origin value needs to be calculated
      else {
        const v = calculateOrigin(e.target.value, mutatedTable);
        mutatedTable[0].value = v;
      }
      // If the converted option only has 1 opponent
    } else if (option.factor === 1) {
      const values = calculateTransformed(
        Number(e.target.value),
        conversionTable[1].factor
      );
      mutatedTable[1].value = values.large;
    } else {
      const v = calculateOrigin(e.target.value, mutatedTable);
      mutatedTable[0].value = v;
    }
    if (onChange) {
      convertValues(mutatedTable[0].value, option);
    }
    setConversionTable(mutatedTable);
  };

  useEffect(() => {
    if (value !== null) {
      updateTable(conversionTable[0], {
        target: {
          value,
        },
      });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      {conversionTable.length > 0 &&
        conversionTable?.map((option, i) => (
          <div className={styles.wrapper} key={i}>
            <div className={styles.block}>
              <input
                name={''}
                readOnly={readonly}
                type={'number'}
                className={classes}
                value={
                  (option?.value && Math.round(option.value * 100) / 100) || ''
                }
                onChange={(e) => {
                  updateTable(option, e, i);
                }}
                placeholder={placeholder}
                {...props}
                {...attributes}
              />
              <Divider height={5} color={'transparent'} />
              <Content
                text={(strings && strings[option?.unit]) || option?.unit}
                size={'s'}
              />
            </div>
            {i !== conversionTable.length - 1 && (
              <Content
                text={i > 0 ? strings.and : strings.or}
                className={styles.content}
              />
            )}
          </div>
        ))}
    </div>
  );
};

Conversion.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorValues: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  quantity: PropTypes.string,
  readonly: PropTypes.bool,
  strings: PropTypes.shape({
    and: PropTypes.string,
    or: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Conversion.defaultProps = {
  strings: {
    or: 'or',
    and: 'and',
  },
  attributes: [],
  value: null,
  placeholder: 'Please enter a value',
  disabled: false,
  readonly: false,
  onChange: null,
  className: '',
  min: null,
  max: null,
  errorValues: null,
  quantity: null,
};

// Needed for Storybook
Conversion.displayName = 'Conversion';

export default Conversion;
