import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Content from '@components/text/content';
import styles from './toggle.module.scss';

const cx = classNames.bind(styles);

const Toggle = ({ options, input, action }) => {
  const classes = cx({
    _self: true,
  });

  return (
    <label className={classes}>
      <input type='checkbox' className={styles.input} {...input} />
      {options.map((option, i) => {
        const Component = option.action ? 'a' : 'div';
        return (
          <Component
            onClick={action}
            href={option.action}
            className={styles.option}
            key={i}
          >
            <Content
              className={styles.text}
              color={'stable-900'}
              formatting={'important'}
              {...option}
            />
          </Component>
        );
      })}
    </label>
  );
};

Toggle.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  input: PropTypes.shape({}),
};

Toggle.defaultProps = {
  input: {},
};

// Needed for Storybook
Toggle.displayName = 'Toggle';

export default Toggle;
