/* eslint-disable no-new */
import { observable, makeObservable, action, toJS, reaction } from 'mobx';
import { actions } from '@mrblenny/react-flow-chart';
import { toPng } from 'html-to-image';
import callApi from './api';
import {
  nodeTransform,
  linkTransform,
  cardSettings,
  dataToPost,
} from './transformers';

export default class Store {
  @observable nodes = [];

  @observable modals = [];

  @observable strings = {
    error: {
      export: 'Could not create export!',
      fatal: 'Something went wrong',
      link_start:
        'You can only start activations from the top or bottom port of a question',
      link_end:
        'You can only create activations from the top to the bottom of a question (or the other way around)',
    },
    success: {
      assignment_updated: 'Assignment has been updated!',
      activation_updated: 'Activation has been updated!',
      activation_removed: 'Activation has been removed!',
      chart_exported: 'Chart has been exported!',
      chart_unlocked: 'Chart has been unlocked!',
      position_saved: 'Position saved!',
    },
    warning: {
      chart_locked: 'Chart has been locked!',
    },
  };

  // Basic settings of the chart
  @observable chart = {
    scale: 1,
    nodes: {},
    links: {},
    offset: { x: -840, y: -860 },
    selected: {},
    hovered: {},
    viewport: {},
  };

  @observable offset = { x: 0, y: 0 };

  @observable loading = true;

  // Forces the diagram to be locked from outside
  @observable forceLock = false;

  // Sets the default readonly state
  @observable readonly = true;

  // When a new link is created, this is the link
  @observable sketchLink = {};

  // When a link excists + is clicked, this is the link
  @observable activeActivation = {};

  @observable activeAssigment = {};

  @observable selected = {};

  // Sets hovered state (maybe deprecated)
  @observable hovered = {};

  // Update various snackbars
  @observable snackbars = [];

  // Track if canvas is being clicked
  @observable canvasClicked = [];

  // Track is a thumbnail is being selected
  @observable thumbnail = false;

  // Allow functions to be added
  @observable functions = [];

  // Allow completions to be added
  @observable completions = [];

  // Store a temporary activation for line dragging
  @observable temporaryActivation = '';

  // Store a temporary assignment for updating
  @observable temporaryAssignment = '';

  // Store a temporary node for TO line dragging
  @observable temporaryToNode = {};

  @observable activeAssigmentId = null;

  // Store a temporary node for FROM line dragging
  @observable temporaryFromNode = {};

  // Store which type of items should be removed
  @observable itemToBeRemoved = '';

  @observable childToBeRemoved = {};

  constructor(data, paths, completions, rules, subset, forceLock) {
    let parsedData = {};
    makeObservable(this);

    if (data) {
      parsedData = JSON.parse(data);
      this.updateNodes(parsedData.subset_rules);
      this.initializeCompletions(completions);
      this.initializeFunctions(parsedData.functions);
      this.setSubset(subset);
      this.forceLock = forceLock;
      callApi(paths.offset.replace('/-1', `/${parsedData.id}`))
        .then((response) => response.json())
        .then((resp) => {
          const mapped = dataToPost.reduce((o, cur) => {
            // Get the index of the key-value pair.
            let occurs = o.reduce(function (n, item, i) {
              return item.id === cur.id ? i : n;
            }, -1);
            // If the object is already there,
            if (occurs >= 0) {
              // If the object contains assignments
              if ('assignments_attributes' in cur) {
                o[occurs].assignments_attributes = o[
                  occurs
                ].assignments_attributes.concat(cur.assignments_attributes);
              }
              // If the object contains subsets
              if ('activations_attributes' in cur) {
                o[occurs].activations_attributes = o[
                  occurs
                ].activations_attributes.concat(cur.activations_attributes);
              }
            } else {
              //Otherwise add it to the excisting array
              var obj = {
                id: cur.id,
                x: cur.x,
                y: cur.y,
                assignments_attributes: [],
                activations_attributes: [],
              };
              o = o.concat([obj]);
            }
            return o;
          }, []);
          // If we need to store position, call the api
          if (mapped.length > 0) {
            callApi(
              this.paths.offset.replace('/-1', `/${parsedData.id}`),
              {
                subset: {
                  subset_rules_attributes: mapped,
                },
              },
              'PATCH'
            )
              .then((resp) => {
                this.setLoading(false);
                this.setView(
                  resp.x || -5000 + this.chart.viewport.width / 2,
                  resp.y || 0,
                  resp.scale || 1
                );
                if (this.readonly) {
                  this.updateSnackbar(
                    this.strings.warning.chart_locked,
                    'warning',
                    3000
                  );
                }
              })
              .catch((error) => {
                this.setLoading(false);
                this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
              });
          } else {
            this.setLoading(false);
            this.setView(
              resp.x || -5000 + this.chart.viewport.width / 2,
              resp.y || 0,
              resp.scale || 1
            );
            if (this.readonly) {
              this.updateSnackbar(
                this.strings.warning.chart_locked,
                'warning',
                3000
              );
            }
          }
        })
        .catch((error) => {
          this.setLoading(false);
          this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
        });
    } else {
      this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
    }
    this.updateNodeStructure(parsedData.id);

    // Main reaction to trigger rerenders based on the nodes
    reaction(
      () => [this.nodes],
      () => {
        this.updateNodeStructure();
      }
    );

    this.paths = paths;
    this.rules = rules;
    this.incommingData = parsedData;
    // Map all incomming actions to usabele chunks
    this.flowChartActions = {};
    Object.keys(actions).forEach((_action, i) => {
      if (['onNodeMouseEnter', 'onNodeMouseLeave'].includes(_action)) {
        this.flowChartActions[_action] = () => false;
      } else {
        this.flowChartActions[_action] = (e, x) => {
          // only allow dragging from top or bottom
          if (
            ['onLinkStart', 'onLinkMove'].includes(_action) &&
            (e.fromPortId.includes('left') || e.fromPortId.includes('right'))
          ) {
            if (_action === 'onLinkStart') {
              this.updateSnackbar(this.strings.error.link_start, 'error', 3000);
            }
            return false;
          }
          if (_action === 'onDragCanvas') {
            this.updateCanvasPosition(toJS(e));
          }
          if (_action === 'onCanvasClick') {
            this.updateCanvasClick(e);
          }
          if (_action === 'onZoomCanvas') {
            this.updateUnplacedNodes({
              x: e.data.positionX,
              y: e.data.positionY,
              scale: e.data.scale,
            });
          }
          if (_action === 'onDragCanvasStop') {
            this.setView(
              e.data.positionX,
              e.data.positionY,
              Number(e.data.scale),
              paths.offset.replace('/-1', `/${19}`)
            );
          }

          if (_action === 'onNodeClick') {
            if (
              e.nodeId.includes('subset_activation') ||
              e.nodeId.includes('assignment')
            ) {
              this.itemToBeRemoved = e.nodeId;
            }
          }
          // when link is completed
          if (_action === 'onLinkComplete') {
            if (this.readonly) return;
            const from = e.fromPortId;
            const to = e.toPortId;

            // only allow links from top->bottom or bottom->top
            if (
              to.includes('left') ||
              to.includes('right') ||
              (to.includes('bottom') && from.includes('bottom')) ||
              (to.includes('top') && from.includes('top'))
            ) {
              this.updateSnackbar(this.strings.error.link_end, 'error', 4000);
              // delete temp link from store
              delete this.chart.links[e.linkId];

              return false;
            }

            // set all vars for activation condition modal
            const jsChart = toJS(this.chart);

            // If no activation condition exists yet:
            const selectedContent = `${
              jsChart.nodes[e.fromNodeId].props.title.text
            } != null`;

            // If a condition already excists:
            const condition =
              jsChart.nodes[e.toNodeId].ports[e.toPortId].properties.label;
            const activeActivation = {
              content: {
                text: condition.text || selectedContent,
              },
              originNode: e.toPortId,
              toNodeId: e.toNodeId,
              linkId: e.linkId,
              newLink: true,
            };
            this.setTemporaryFromNode(
              toJS(this.nodes).filter(
                (x) => x.id === Number(e.fromNodeId.split('_')[1])
              )[0]
            );
            this.setTemporaryToNode(
              toJS(this.nodes).filter(
                (x) => x.id === Number(e.toNodeId.split('_')[1])
              )[0]
            );
            this.showModal('updateActivation', activeActivation);
            // open activation condition modal
          }
          if (_action === 'onLinkComplete') {
            this.updateSketchLink(e);
          }

          // only allow dragging to top or bottom
          const data = actions[_action](e)(toJS(this.chart));
          if (data) {
            if (_action === 'onDragNodeStop') {
              const id = e.id.split('_')[1];
              this.updateNodePosition(id, e.data.x, e.data.y, e.id);
            }
            if (_action === 'onLinkClick') {
              const jsChart = toJS(this.chart);
              const clickedLink = jsChart?.links[e.linkId];
              const activeActivation = {
                content:
                  jsChart.nodes[clickedLink.to.nodeId].ports[
                    clickedLink.to.portId
                  ].properties.label,
                originNode:
                  jsChart.nodes[clickedLink.to.nodeId].ports[
                    clickedLink.to.portId
                  ].id,
                toNodeId: clickedLink.to.nodeId,
                linkId: e.linkId,
              };
              this.showModal('updateActivation', activeActivation);
            }
            this.updateNodes(null, data);
          }
        };
      }
    });
  }

  @action updateNodeStructure = (id) => {
    const connections = [];
    this.nodes.forEach((x) => {
      if (x.activation.from_ids) {
        toJS(x.activation.from_ids).forEach((n) => {
          if (connections[`node_${n}`]) {
            connections[`node_${n}`].push(x.id);
          } else {
            connections[`node_${n}`] = [x.id];
          }
        });
      }
    });

    // We need to count how many nodes that have not yet been rendered,
    // on each itteration, we skip a few pixels in the y pos to create a
    // better overview
    let hasAlreadyBeenRendered = [];
    this.nodes.map((node) => {
      if (!node.x || !node.y) {
        hasAlreadyBeenRendered.push({
          id: node.id,
          increment: hasAlreadyBeenRendered.length,
        });
      }
      if (node.assignments) {
        node.assignments.map((assignment) => {
          if (!assignment.x || !assignment.y) {
            hasAlreadyBeenRendered.push({
              id: assignment.id,
              increment: hasAlreadyBeenRendered.length,
            });
          }
        });
      }
      if (node.subset_activations) {
        node.subset_activations.map((subset) => {
          if (!subset.x || !subset.y) {
            hasAlreadyBeenRendered.push({
              id: subset.id,
              increment: hasAlreadyBeenRendered.length,
            });
          }
        });
      }
    });

    let mostLeft = 99999;
    let mostRight = 0;
    let mostTop = 99999;
    let mostBottom = 0;

    // The most types of cards user are simple question cards, therefor
    // default to node.
    let mostRightType = 'node';

    Object.values(toJS(this.nodes)).map((node) => {
      if (node.x < mostLeft) mostLeft = node.x;
      if (node.x > mostRight) {
        mostRight = node.x;
        mostRightType = 'node';
      }
      if (node.y < mostTop) mostTop = node.y;
      if (node.y > mostBottom) mostBottom = node.y;
      if (node.subset_activations) {
        node.subset_activations.map((subset) => {
          if (subset.x < mostLeft) mostLeft = subset.x;
          if (subset.x > mostRight) {
            mostRight = subset.x;
            mostRightType = 'subset';
          }
          if (subset.y < mostTop) mostTop = subset.y;
          if (subset.y > mostBottom) mostBottom = subset.y;
        });
      }
      if (node.assignments) {
        node.assignments.map((assignment) => {
          if (assignment.x < mostLeft) mostLeft = assignment.x;
          if (assignment.x > mostRight) {
            mostRight = assignment.x;
            mostRightType = 'assignment';
          }
          if (assignment.y < mostTop) mostTop = assignment.y;
          if (assignment.y > mostBottom) mostBottom = assignment.y;
        });
      }
    });

    // The y pos of the card is based on the full height of the diagram splitted in 2,
    // so that the cards appear in the centered view/diagram.
    const newMiddleNodePos = mostTop + (mostBottom - mostTop) / 2;

    // The most rightPos is based on the left corner of the card, therefor
    // its more logic to base the new pos on the most left type of cardwidth
    const xPos =
      mostRight +
      (mostRightType === 'subset'
        ? 200
        : mostRightType === 'assignment'
        ? 300
        : 600);

    const newNodes = this.nodes.map((x, i) =>
      // Based on the type of card that is on the most right position we determine
      // how for the not yet transformed card should be positioned
      nodeTransform(x, i, connections, id, hasAlreadyBeenRendered, {
        x: xPos,
        y: newMiddleNodePos,
      })
    );
    this.initializeNodes(Object.assign({}, ...newNodes), 'update');
    this.updateLinks({ ...linkTransform(this.nodes) });
  };

  @action setSubset = (val) => {
    this.subset = val;
  };

  @action setView = (x, y, scale, endpoint = false) => {
    if (endpoint) {
      const postData = {
        x,
        y,
        scale: Number(scale),
      };

      callApi(endpoint, postData, 'PATCH')
        .then((response) => response.json())
        .catch((error) => {
          this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
        });
    } else {
      this.chart.offset = { x, y };
      this.updateUnplacedNodes(this.chart.offset);
      this.chart.scale = parseFloat(scale) || 1;
    }
  };

  // Action to place every node which is new to a fixed position on the canvas
  @action updateUnplacedNodes = (chart) => {
    const oldNodes = toJS(this.chart.nodes);

    const chartNodes = toJS(this.nodes);
    const unplacedNodes = Object.keys(oldNodes)
      .map((old, i) => {
        if (oldNodes[old].hasNotYetBeenDragged) {
          return oldNodes[old];
        }
      })
      .filter((item) => item !== undefined);
    if (unplacedNodes.length > 0) {
      toJS(this.nodes).map((node, i) => {
        unplacedNodes.filter((unplacedNode, x) => {
          if (Number(unplacedNode?.id?.split('_')[1]) === node.id) {
            chartNodes[i].x =
              Math.abs(toJS(chart.x)) * Math.abs(toJS(chart.scale));
            chartNodes[i].y =
              Math.abs(toJS(chart.y)) * Math.abs(toJS(chart.scale));
          }
        });
      });
      this.nodes = chartNodes;
    }
  };

  @action updateCanvasPosition(e) {
    const object = toJS(e);
    this.offset = {
      positionX: object.data.positionX,
      positionY: object.data.positionY,
    };
  }

  @action thumbnailSnap = () => {
    const values = toJS(this.canvasClicked);
    const getDif = (val_1, val_2) => val_2 - val_1;
    const val_x = getDif(values[0].x, values[1].x);
    const val_y = getDif(values[0].y, values[1].y);
    const scale = (number, inMin, inMax, outMin, outMax) => {
      return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    };
    const newVal = val_x * toJS(this.chart.scale);
    const temp = scale(newVal, 0, toJS(this.chart.viewport).width, 0, 2);
    this.canvasClicked = [];
    this.thumbnail = false;
    const newScale = temp;
    let leftPos = values[0].x * newScale;
    let topPos = values[0].y * newScale;
    this.setView(-Math.abs(leftPos), -Math.abs(topPos), newScale);
  };

  @action setThumbnail = (value) => {
    this.thumbnail = value;
  };

  @action updateCanvasClick = (e) => {
    if (this.thumbnail) {
      this.canvasClicked.push({
        x: e.nativeEvent.offsetX,
        y: e.nativeEvent.offsetY,
      });
      if (this.canvasClicked.length > 1) {
        this.thumbnailSnap();
      }
    }
  };

  @action updateSnackbar = (title, status, autoclose = 0) => {
    const id = `snackbar_${Math.random()}`;
    this.snackbars.push({
      id,
      type: status || 'warning',
      title: {
        text: title || this.strings.error.fatal,
      },
      dismissable: autoclose === 0,
    });
    if (autoclose > 0) {
      setTimeout(() => {
        this.removeSnackbar(id);
      }, autoclose);
    }
  };

  @action updateNodes = (newData = null, chart = null) => {
    if (newData) this.nodes = newData;
    if (chart && JSON.stringify(chart) !== JSON.stringify(this.chart))
      this.chart = chart;
  };

  @action removeSnackbar = (id) => {
    this.snackbars = this.snackbars.filter((s) => s.id !== id);
  };

  @action setSelectedRule = (rule) => {
    this.selectedRule = rule;
  };

  @action updateNodePosition = (id, x, y, type = '') => {
    let postData = {
      x,
      y,
      diagram: true,
    };
    if (type?.includes('subset')) {
      postData = {
        diagram: true,
        activations_attributes: [
          {
            id: toJS(this.nodes).filter((p) => p.id === Number(id))[0]
              .subset_activations[type.split('_')[4]].id,
            x,
            y,
          },
        ],
      };
    }
    if (type?.includes('assignment')) {
      postData = {
        diagram: true,
        assignments_attributes: [
          {
            id: toJS(this.nodes).filter((p) => p.id === Number(id))[0]
              .assignments[type.split('_')[3]].id,
            x,
            y,
          },
        ],
      };
    }
    callApi(
      this.paths.save_position.replace('/-1', `/${id}`),
      postData,
      'PATCH',
      true
    )
      .then((resp) => {
        this.updateSnackbar(
          this.strings.success.position_saved,
          'success',
          1500
        );
      })
      .catch((error) => {
        this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
      });
  };

  // Removes either a subset or a assigment
  @action removeChild = () => {
    const item = toJS(this.childToBeRemoved);
    let postData = {};
    const id = item.id.split('_')[1];
    if (item.id.includes('assignment')) {
      postData = {
        diagram: true,
        assignments_attributes: [
          {
            id: item.props.title.id,
            _destroy: true,
          },
        ],
      };
    }
    if (item.id.includes('subset')) {
      postData = {
        diagram: true,
        activations_attributes: [
          {
            id: toJS(this.nodes)
              .filter((x) => x.id === Number(id))[0]
              .subset_activations.filter(
                (y) => y.subset.id === item.props.title.id
              )[0].id,
            _destroy: true,
          },
        ],
      };
    }
    return new Promise((resolve, reject) => {
      callApi(
        `${this.paths.save_position.replace('/-1', `/${id}`)}`,
        postData,
        'PATCH'
      )
        .then((response) => response.json())
        .then((resp) => {
          this.updateNodes(resp);
          this.updateSnackbar(
            this.strings.success.activation_updated,
            'success',
            1500
          );
          resolve(true);
        })
        .catch((error) => {
          this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
          reject(error);
        });
    });
  };

  @action addRule = (data, type) => {
    if (data && type) {
      // place new rule in center of viewport
      // possible improvement; take size of card into account
      const zoomFactor = 1 / data.scale;
      const offsetXScaleBased = data.offset.x * zoomFactor;
      const offsetYScaleBased = data.offset.y * zoomFactor;
      const viewportXScaleBased = data.viewport.width * zoomFactor;
      const viewportYScaleBased = data.viewport.height * zoomFactor;

      // offset needs to be inversed (* -1), because the translate() makes it negative
      // viewport is divided by 2 to get the center
      const newNodeX = offsetXScaleBased * -1 + viewportXScaleBased / 2;
      const newNodeY = offsetYScaleBased * -1 + viewportYScaleBased / 2;

      this.setLoading(true);
      callApi(
        this.paths.add_rule,
        {
          x: newNodeX,
          y: newNodeY,
          rule: this.selectedRule,
          rule_id: this.selectedRule.id,
          subset_id: this.incommingData.id,
        },
        'POST'
      )
        .then((response) => response.json())
        .then(
          (resp) =>
            new Promise((resolve, reject) => {
              const mutatedNodes = resp.nodes.map((node) => {
                if (
                  toJS(this.nodes).findIndex((x) => x.id === node.id) === -1
                ) {
                  node.x = newNodeX;
                  node.y = newNodeY;
                }

                return node;
              });
              this.setLoading(false);
              this.updateNodes(mutatedNodes);
              this.updateSnackbar('Rule has been added!', 'success', 1500);
              this.hideModal(type);

              // set new completions with added rule included
              this.initializeCompletions(resp.completions);

              resolve(true);
            })
        )
        .catch((error) => {
          this.setLoading(false);
          this.updateSnackbar('Failed to add Rule!', 'warning', 1500);
        });
    }
  };

  @action editAssignment = (id, node) => {
    this.activeAssigment = node.id.split('_')[1];
    this.activeAssigmentId = node.props.title.id;
    this.showModal('updateAssignment', null);
  };

  // Trigger conformation modal + set selected node/activation
  @action deleteNode = (node, type) => {
    if (type === 'link') {
      const activeActivation = {
        toNodeId: node.to.nodeId,
      };
      this.setTemporaryActivation({
        content: toJS(this.nodes).filter(
          (x) => x.id === Number(node.to.nodeId.split('_')[1])
        )[0].activation.condition,
        from: node.from,
      });
      this.showModal('removeItem', activeActivation, 'activation');
    } else {
      this.setTemporaryActivation(node);
      this.childToBeRemoved = type;
      this.showModal('removeItem', null);
    }
  };

  @action removeNode = (data) =>
    new Promise((resolve, reject) => {
      this.setLoading(true);
      callApi(
        this.paths.delete_rule.replace(
          '/-1',
          `/${this.temporaryActivation.split('_')[1]}`
        ),
        {},
        'DELETE'
      )
        .then((response) => response.json())
        .then((resp) => {
          this.updateNodes(resp.nodes);
          this.initializeCompletions(resp.completions);
          this.updateSnackbar(
            this.strings.success.position_saved,
            'success',
            1500
          );
          this.setLoading(false);
        })
        .catch((error) => {
          this.setLoading(false);
          this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
        });
      resolve(true);
    });

  @action initializeCompletions = (completions) => {
    this.completions = completions;
  };

  @action initializeFunctions = (functions) => {
    this.functions = functions;
  };

  @action setLoading = (val) => {
    this.loading = val;
  };

  @action setReadonly = (val) => {
    this.readonly = val;

    if (this.readonly) {
      this.updateSnackbar(this.strings.warning.chart_locked, 'warning', 1500);
    } else {
      this.updateSnackbar(this.strings.success.chart_unlocked, 'success', 1500);
    }
  };

  @action setViewport = (ref) => {
    this.chart.viewport = {
      width: ref.offsetWidth,
      height: window.innerHeight - ref.offsetTop,
    };
  };

  @action initializeNodes = (nodes, type = 'update') => {
    if (type === 'init') {
      const firstCardX = nodes[Object.keys(nodes)[0]].position.x;
      const firstCardY = nodes[Object.keys(nodes)[0]].position.y;
      const viewOffset = this.chart.viewport.width / 2 || 0;

      this.chart = {
        ...this.chart,
        nodes,
        scale: 1,
        offset: {
          x:
            -1 * firstCardX + viewOffset - cardSettings.question.width / 2 ||
            viewOffset,
          y: -1 * firstCardY + 20 || 0,
        },
      };
    } else {
      this.chart = {
        ...this.chart,
        nodes,
      };
    }
  };

  @action updateLinks = (links) => {
    this.chart = {
      ...this.chart,
      links,
    };
  };

  @action showModal = (type, activation = null, filter = '') => {
    if (!this.modals.includes(type)) {
      this.modals.push(type);
    }
    if (activation) {
      this.activeActivation = activation;
    }
    if (filter === 'activation') {
      this.itemToBeRemoved = filter;
    }
  };

  @action hideModal = (type) => {
    this.modals = this.modals.filter((t) => t !== type);
    this.temporaryActivation = '';
    this.activeActivation = {};
  };

  @action updateAssignment = (v) => {
    const newData = toJS(this.temporaryAssignment);
    if (!newData) return;
    const id = this.activeAssigment;
    new Promise((resolve, reject) => {
      const postData = {
        diagram: true,
        assignments_attributes: {
          id: this.activeAssigmentId,
          assignment_rows_attributes: newData.map((item) => ({
            id: item.id,
            variable_id: item.variable,
            value: item.value,
            operator: item.operator,
            has_evaluation:
              item.condition !== null && item.condition !== '' ? true : false,
            value_expression_string: item.condition,
          })),
        },
      };
      callApi(
        `${this.paths.save_position.replace('/-1', `/${id}`)}`,
        postData,
        'PATCH'
      )
        .then((response) => response.json())
        .then((resp) => {
          this.hideModal('updateAssignment');
          this.updateNodes(resp);
          resolve(true);
          this.setLoading(false);
        })
        .catch((error) => {
          this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
          reject();
          this.setLoading(false);
        });
      this.updateSnackbar(
        this.strings.success.assignment_updated,
        'success',
        1500
      );
    });
  };
  // Draw the line as sketch, so Blenny knows whats up
  @action updateSketchLink = (link) => {
    this.sketchLink = link;
  };

  // Sets the text entered in the text editor
  @action setTemporaryActivation = (data) => {
    if (data !== '') {
      this.temporaryActivation = data;
    }
  };

  @action setTemporaryAssignment = (data) => {
    if (data) {
      this.temporaryAssignment = data;
    }
  };

  // Sets the node to where the line is drawn
  @action setTemporaryToNode = (data) => {
    this.temporaryToNode = data;
  };

  // Sets the node from where the line is drawn
  @action setTemporaryFromNode = (data) => {
    this.temporaryFromNode = data;
  };

  // Updates the activation condition between nodes
  @action updateActivation = (data) =>
    new Promise((resolve, reject) => {
      this.setLoading(true);
      let newActivationCondition = toJS(this.temporaryActivation);
      if (data === 'empty') {
        const conditionFrom = toJS(this.nodes).filter(
          (x) =>
            x.id === Number(newActivationCondition.from.nodeId.split('_')[1])
        )[0].title;
        const conditionToBeRemoved = newActivationCondition.content
          .split(/\|\|/)
          .filter((x) => !x.includes(conditionFrom))
          .map((item) => item.trimStart().trimEnd())
          .join(' || ');
        newActivationCondition = conditionToBeRemoved;
      }
      let fakeLink = toJS(this.sketchLink);
      if (Object.keys(fakeLink).length === 0) {
        fakeLink = toJS(this.activeActivation);
      }
      const id = fakeLink.toNodeId.replace('node_', '');
      const postData = {
        diagram: true,
        subset_rule: {
          subset_id: this.incommingData.id,
          condition_expression_string: newActivationCondition,
        },
      };
      callApi(
        `${this.paths.save_position.replace('/-1', `/${id}`)}`,
        postData,
        'PATCH'
      )
        .then((response) => response.json())
        .then((resp) => {
          this.updateNodes(resp);
          this.setLoading(false);
        })
        .catch((error) => {
          this.setLoading(false);
          this.updateSnackbar(this.strings.error.fatal, 'error', 1500);
        });
      this.updateSnackbar(
        this.strings.success.activation_updated,
        'success',
        1500
      );
      this.itemToBeRemoved = '';
      resolve(true);
    });

  @action zoomOut = (ref) => {
    let mostLeft = 99999;
    let mostRight = 0;
    let mostTop = 99999;
    let mostBottom = 0;

    Object.values(toJS(this.chart.nodes)).map((node) => {
      if (node.position.x < mostLeft) mostLeft = node.position.x;
      if (node.position.x > mostRight) mostRight = node.position.x;
      if (node.position.y < mostTop) mostTop = node.position.y;
      if (node.position.y > mostBottom) mostBottom = node.position.y;
    });
    // breedte meest rechter node
    mostRight += 400;
    // hoogte meest bottom node
    mostBottom += 250;

    const diagramWidth = mostRight - mostLeft;
    const diagramHeight = mostBottom - mostTop;
    // Size of canvas is default to 10000 x 10000

    // 30 moeten we uit ref kunnen halen
    const aspectRatioViewPort = (ref.clientHeight - 30) / ref.clientWidth;
    const aspectRatioDiagram = diagramHeight / diagramWidth;
    const scale =
      aspectRatioViewPort < aspectRatioDiagram
        ? (ref.clientHeight - 30) / diagramHeight
        : ref.clientWidth / diagramWidth;

    this.chart = {
      ...this.chart,
      scale,
      offset: {
        x: 50 - mostLeft * scale,
        y: 50 - mostTop * scale,
      },
    };
  };

  @action setExporting = (val) => {
    this.exporting = val;
  };

  @action exportChart = (ref) => {
    return new Promise((resolveTop, rejectTop) => {
      const previousChartState = toJS(this.chart);
      const space = 50;
      const exportChartToPng = new Promise((resolve, reject) => {
        const timestamp = new Date().toLocaleString();
        const diagram_and_legenda = ref.querySelector(
          '[data-export-container]'
        );
        const diagram = ref.querySelector('[data-chart]');
        const legenda = ref.querySelector('[data-legenda]');
        this.zoomOut(ref.querySelector('[data-chart]'));
        this.setExporting(true);
        toPng(diagram, {
          quality: 1,
          backgroundColor: '#FFFFFF',
          cacheBust: true,
          canvasWidth: (diagram.clientWidth + space) * (1 / this.chart.scale),
          canvasHeight: (diagram.clientHeight + space) * (1 / this.chart.scale),
        }).then((dataUrl) => {
          try {
            const link = document.createElement('a');
            // Current name of chart
            link.download = `export-${timestamp}-chart.png`;
            link.href = dataUrl;
            link.click();
            resolve(true);
          } catch (err) {
            reject();
            throw err;
          }
        });
        toPng(legenda, {
          quality: 1,
          backgroundColor: '#FFFFFF',
          cacheBust: true,
          canvasWidth: legenda.clientWidth,
          canvasHeight: legenda.clientHeight,
        }).then((dataUrl) => {
          try {
            const link = document.createElement('a');
            // Current name of chart
            link.download = `export-${timestamp}-legenda.png`;
            link.href = dataUrl;
            link.click();
            resolve(true);
          } catch (err) {
            reject();
            throw err;
          }
        });

        toPng(diagram_and_legenda, {
          quality: 1,
          backgroundColor: '#FFFFFF',
          cacheBust: true,
          canvasWidth:
            (diagram_and_legenda.clientWidth + space) * (1 / this.chart.scale),
          canvasHeight:
            (diagram_and_legenda.clientHeight + space) * (1 / this.chart.scale),
        }).then((dataUrl) => {
          try {
            const link = document.createElement('a');
            // Current name of chart
            link.download = `export-${timestamp}.png`;
            link.href = dataUrl;
            link.click();
            resolve(true);
          } catch (err) {
            reject();
            throw err;
          }
        });
      });

      exportChartToPng
        .then(() => {
          new Promise((resolve) => {
            this.chart = {
              ...this.chart,
              scale: previousChartState.scale,
              offset: previousChartState.offset,
            };
            this.setLoading(false);
            this.setExporting(false);
            resolve(true);
            resolveTop(true);
          });
          this.updateSnackbar(
            this.strings.success.chart_exported,
            'success',
            2500
          );
        })
        .catch((e) => {
          this.setLoading(false);
          this.setExporting(false);
          this.updateSnackbar(this.strings.error.export, 'warning', 2500);
          resolveTop(true);
        });
    });
  };

  get customConfig() {
    return {
      snap: true,
      exporting: this.exporting,
      subset: this.subset,
      readonly: this.readonly,
      offset: this.offset,
      zoom: {
        minScale: 0.1,
        maxScale: 2,
        currentZoom: this.chart.scale,
      },
      deleteNode: this.deleteNode,
    };
  }

  get funnelActions() {
    return {
      ...this.flowChartActions,
    };
  }
}
