import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Content, Status } from '@components/text';
import { Badge } from '@components/badge';
import Option from '@components/form/option';
import { Button } from '@components/button';
import { Divider, Tooltip, Icon } from '@components/ui';
import styles from './body.module.scss';

const cx = classNames.bind(styles);

const BodyRow = ({
  className,
  columnspan,
  column,
  contentClassName,
  index,
  size,
  style,
  unreachable,
  tooltipText,
}) => {
  const columnClasses = cx(
    {
      cell: true,
      iconOnly:
        column &&
        !Object.keys(column).includes('text') &&
        !column.content &&
        !column.status,
      odd: index % 2 !== 0,
      even: index % 2 === 0,
      content: column.content,
      warning: unreachable,
    },
    className
  );

  const contentClasses = cx(
    {
      cellcontent: true,
    },
    contentClassName
  );

  return (
    <td
      className={columnClasses}
      width={`${size}%`}
      colSpan={columnspan}
      style={style}
    >
      <div className={contentClasses}>
        {column.children && (
          <div dangerouslySetInnerHTML={{ __html: column.children }} />
        )}
        {column.action ? (
          <>
            {unreachable && !column.icon && (
              <Tooltip
                trigger={'mouseenter'}
                position={'top-start'}
                content={{
                  text:
                    tooltipText || 'Warning, there might be something wrong',
                }}
                simple
              >
                <Status color={'royal'} icon={{ icon: 'warning' }} />
              </Tooltip>
            )}
            <Button
              link
              className={styles.button}
              color={unreachable ? 'royal' : column.color}
              {...column}
            />
            {column.originalId && (
              <>
                <Divider width={20} color={'transparent'} />
                <Tooltip
                  trigger={'mouseenter'}
                  content={{
                    text: `Original master id: ${column.originalId}`,
                  }}
                  simple
                >
                  <Icon color={'positive'} icon={'crown'} />
                </Tooltip>
              </>
            )}
          </>
        ) : Object.keys(column).includes('text') ? (
          column.text == 'true' ? (
            <Badge
              type={'boolean'}
              size={'s'}
              content={{ text: column.text }}
              lighten
            />
          ) : column.text == 'false' ? (
            <Badge
              type={'!boolean'}
              size={'s'}
              content={{ text: column.text }}
              lighten
            />
          ) : (
            <Content color={'dark'} {...column} />
          )
        ) : (
          ''
        )}
        {column && column.badges && (
          <div className={styles.badgesWrapper}>
            {column.badges.map((badge, index) => (
              <React.Fragment key={index}>
                <div className={styles.badges}>
                  {index > 0 && <Divider width={5} color={'transparent'} />}
                  <Badge
                    color={unreachable ? 'royal' : badge.color}
                    key={index}
                    type={badge.type}
                    size={'s'}
                    content={badge}
                    lighten
                  />
                </div>
                {(badge.text.replace(/\s/g, '') === '&&' ||
                  badge.text.replace(/\s/g, '') === '||') && (
                  <div className={styles.break} />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {column.content && (
          <Content color={'balanced-500'} size={'s'} text={column.content} />
        )}
        {column.status && <Status {...column.status} />}
      </div>
    </td>
  );
};

BodyRow.propTypes = {
  className: PropTypes.string,
  columnspan: PropTypes.number,
  column: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  contentClassName: PropTypes.string,
  index: PropTypes.number,
  size: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  unreachable: PropTypes.bool,
  tooltipText: PropTypes.string,
};

BodyRow.defaultProps = {
  className: '',
  columnspan: 1,
  column: {},
  contentClassName: '',
  index: 0,
  style: {},
  unreachable: false,
};

export default BodyRow;
