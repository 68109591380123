import React from 'react';
import Reading from '.';

export default {
  title: 'BRMS/form/Reading',
  component: Reading,
};

const Template = (args) => (
  <>
    <Reading {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  inputLabel: {
    text: 'How many?',
  },
  group: {
    options: [
      {
        checked: false,
        label: '1',
        name: 'name[v]',
        value: '1',
      },
      {
        checked: false,
        label: '2',
        name: 'name[v]',
        value: '2',
      },
      {
        checked: false,
        label: '3',
        name: 'name[v]',
        value: '3',
      },
    ],
  },
  input: {
    name: 'input',
  },
  value: {
    rule_struct: true,
    readings: {
      Diastolic: [10, 20],
      Systolic: [30, 40],
    },
    aggregated: {
      Diastolic: null,
      Systolic: null,
    },
    aggregation_method: {
      Diastolic: 'min',
      Systolic: 'avg',
    },
  },
  title: {
    text: 'Reading',
  },
  newCard: {
    title: {
      text: 'Reading',
    },
    variables: [
      [
        {
          label: {
            text: 'Diastolic',
          },
          input: {
            value: '',
          },
        },
        {
          label: {
            text: 'Systolic',
          },
          input: {
            value: '',
          },
        },
      ],
    ],
  },
  submit: {
    text: 'Submit',
  },
  onChange: (e) => console.log(e),
};
