import { Controller } from '@hotwired/stimulus';

// This controller assumes you have set the disabled state of inputs based on excisting logic and
// will remove the occuring disabled state if a previous element has been filled in.
export default class extends Controller {
  // Target the inputs you want to update
  static targets = ['input'];
  // Target the type of input you want to update
  static values = { type: String };
  connect() {
    super.connect();
    this.update();
  }

  update(e) {
    let enableNextInput = true;
    setTimeout(() => {
      this.inputTargets.forEach((input) => {
        const parent = input.closest(`brms-${this.typeValue}`);
        if (
          enableNextInput ||
          input.options[input.selectedIndex].value !== ''
        ) {
          parent.removeAttribute('disabled');
        }
        enableNextInput = input.id === e?.target?.id && e?.detail?.value !== '';
      });
    }, 1);
  }
}
