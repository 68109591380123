import React from 'react';
import Purify from '.';
import DOMPurify from 'isomorphic-dompurify';

export default {
  title: 'BRMS/ui/Purify',
  component: Purify,
};

const Template = (args) => {
  const clean = DOMPurify.sanitize(Default.args.html);
  return(
    <>
      <p>This component makes unsafe html strings safe and outputs it as React components.</p>
      <p>
        Input:<br />
        <textarea readonly="readonly" cols="100" rows="10">
          {Default.args.html}
        </textarea>
      </p>

      <p>
        Output:<br />
        <textarea readonly="readonly" cols="100" rows="10">
          {clean}
        </textarea>
      </p>

      <div>
        HTML:<br />
        <Purify {...args} />
      </div>
    </>
  )
};

export const Default = Template.bind({});

Default.args = {
  html: `<ul>
    <li>Item 1</li>
    <li>Item 2</li>
    <li>
      <a href="javascript:window.alert(\'YAY\');">XSS? No!</a>
    </li>
</ul>`,
};
