import React from 'react';
import { Divider } from '@components/ui';
import Toolbar from '.';

export default {
  title: 'BRMS/flowchart/ui/Toolbar',
  component: Toolbar,
};

const Template = (args) => <Toolbar {...args} />;

export const Default = Template.bind({});

Default.args = {
  buttons: [
    {
      outline: true,
      text: 'Export',
      icon: {
        name: 'download',
      },
    },
  ],
  tooltips: [
    {
      legend: true,
      simple: false,
      button: {
        outline: true,
        icon: {
          name: 'info',
          width: 20,
          height: 18,
        },
        color: 'positive',
      },
      content: [
        {
          title: {
            text: 'Start',
          },
          sign: {
            icon: 'circle',
            color: 'positive',
          },
        },
        {
          title: {
            text: 'End',
          },
          sign: {
            icon: 'circle',
            color: 'energized',
          },
        },
        {
          title: {
            text: 'Error',
          },
          sign: {
            icon: 'circle',
            color: 'royal',
          },
        },
        {
          title: {
            text: 'Activation Condition',
          },
          sign: {
            icon: 'square',
          },
        },
        {
          title: {
            text: 'Rule',
          },
          sign: {
            icon: 'border',
          },
        },
        {
          title: {
            text: 'Assignment',
          },
          sign: {
            icon: 'dashed',
          },
        },
        {
          title: {
            text: 'Subset',
          },
          sign: {
            icon: 'dashed',
            color: 'positive',
            rotate: 'true',
          },
        },
      ],
    },
    {
      simple: false,
      actions: [
        {
          text: 'Add rule',
          action: '#',
        },
        {
          text: 'Logout',
          action: '#',
        },
        {
          text: 'Remove account',
          action: '#',
        },
      ],
      button: {
        text: 'Add item',
        icon: {
          name: 'plus',
          width: 20,
          height: 18,
        },
        color: 'positive',
      },
    },
  ],
};

export const ToolbarTooltips = () => (
  <Toolbar
    tooltips={[
      {
        legend: true,
        simple: false,
        button: {
          outline: true,
          icon: {
            name: 'info',
            width: 20,
            height: 18,
          },
          color: 'positive',
        },
        content: [
          {
            title: {
              text: 'Start',
            },
            sign: {
              icon: 'circle',
              color: 'positive',
            },
          },
          {
            title: {
              text: 'End',
            },
            sign: {
              icon: 'circle',
              color: 'energized',
            },
          },
        ],
      },
      {
        simple: false,
        actions: [
          {
            text: 'Add rule',
            action: '#',
          },
          {
            text: 'Logout',
            action: '#',
          },
          {
            text: 'Remove account',
            action: '#',
          },
        ],
        button: {
          text: 'Add item',
          icon: {
            name: 'plus',
            width: 20,
            height: 18,
          },
          color: 'positive',
        },
      },
    ]}
  />
);

export const ToolbarButtons = () => (
  <Toolbar
    buttons={[
      {
        outline: true,
        text: 'Export',
        icon: {
          name: 'download',
        },
      },
      {
        text: 'Drop database',
        icon: {
          name: 'close',
        },
      },
    ]}
  />
);
