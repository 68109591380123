import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
import styles from './icon.module.scss';
import IconModule from './partials/iconModule';
const cx = classNames.bind(styles);
const Icon = ({
  color,
  className,
  width,
  height,
  icon,
  iconModule,
  inlineSvg,
  ...props
}) => {
  const classes = cx(
    {
      icon: true,
      iconModule,
    },
    color,
    className
  );
  return (
    <>
      {inlineSvg && <div dangerouslySetInnerHTML={{__html: inlineSvg}}></div>}
      {iconModule ? (  
        <div 
          className={styles.iconModuleWrapper}  
          style={{
            width,
            height,
          }}
        >
          <IconModule
            className={classes}              
            icon={icon}
            {...props}
          />   
        </div>     
      ) : (
        icon && <svg
          className={classes}
          viewBox={`0 0 ${width} ${height}`}
          preserveAspectRatio='xMidYMid meet'
          style={{
            width,
            height,
          }}
          {...props}
        >
          <use xlinkHref={`#symbol-${icon}`} />
        </svg>
      )}
    </>
  );
};

Icon.propTypes = {
  /** The color of the icon */
  color: PropTypes.oneOf(Colors),
  /** The name of the icon to embed (from the generated sprite) */
  icon: PropTypes.string,
  /** The width of the icon */
  width: PropTypes.number,
  /** The height of the icon */
  height: PropTypes.number,
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  iconModule: PropTypes.bool,
  inlineSvg: PropTypes.string
};

Icon.defaultProps = {
  color: 'dark',
  icon: '',
  width: 20,
  height: 20,
  className: '',
  iconModule: false,
  inlineSvg: null,
};

// Needed for Storybook
Icon.displayName = 'Icon';

export default Icon;
