import React, { useState, useEffect } from 'react';
import Title from '@components/text/title';
import consumer from 'channels/consumer';
import Divider from '@components/ui/divider';
import Button from '@components/button/button';
import styles from './widgetWrapper.module.scss';

const WidgetWrapper = ({
  deploymentId,
  status,
  src_url,
  url,
  username,
  password,
  rulesetId,
  locale,
  sessionId,
  sessionToken,
  mode,
  railsEnv
}) => {
  let widgetHash = {};

  const widgetMessages = {
    unknown: 'Your request has been scheduled, please wait',
    building: 'Building BRMS image',
    synchronizing_deployment: 'Synchronizing deployment with cluster',
    starting_infrastructure: 'Starting infrastructure',
    started: 'Waiting for infrastructure to start',
    checked_in: 'Loading interpreter',
    available: 'Available, happy testing!',
    failed: 'Connection failed, please contact an administrator',
  };

  const [incomingStatus, setIncomingStatus] = useState(status);
  const [incomingMessages, setIncomingMessages] = useState([]);
  const [paramSessionId, setParamSessionId] = useState(sessionId);
  const [paramSessionToken, setParamSessionToken] = useState(sessionToken);

  const setMessages = (message) => {
    setIncomingMessages((incomingMessages) => [...incomingMessages, message]);
  };

  // Also disconnect the websocket on navigation
  document.addEventListener('turbo:click', () => {
      consumer.disconnect();
  });

  // Extracts the session information from the ruleset-specific cookie
  const loadSessionFromCookie = () => {
    let sessionInfo = [];

    // Split all cookies into separate arrays. Select the one with our ruleset as key
    const sessionString = document.cookie
      .split('; ')
      .filter(
        (e) => e.substring(0, e.indexOf('=')) === `${rulesetId}_${locale}`
      );

    // Base64Decode the session if available. Split it on sessionId and SessionToken
    if (sessionString[0]) {
      sessionInfo = atob(
        sessionString[0].substring(sessionString[0].indexOf('=') + 1)
      ).split('&');
    }

    return sessionInfo;
  };

  // Delete the potentially existing ruleset cookie by expiring it
  const resetSession = () => {
    document.cookie = `${rulesetId}_${locale}=; Max-Age=0; SameSite=Lax; Secure`;

    BRMSWidget.destroy();

    // Clear session id and token from params to prevent that they override
    setParamSessionId(null);
    setParamSessionToken(null);

    // Swap statuses to force a widget reload
    setIncomingStatus('checked_in');
    setTimeout(() => {
      setIncomingStatus('available');
    }, 500);

    return true;
  };

  // Create a hash containing either credentials for creating a session
  // or credentials for resuming one
  const setupWidgetHash = () => {
    let session;
    let token;
    if (!paramSessionId || !paramSessionToken) {
      const sessionInfo = loadSessionFromCookie();
      session = sessionInfo[0];
      token = sessionInfo[1];
    } else {
      session = paramSessionId;
      token = paramSessionToken;
    }

    // Load default widget config
    widgetHash = {
      url,
      ruleset: rulesetId,
      session,
      token,
      mode,
      railsEnv
    };

    // Add BasicAuth for creating sessions if there is none
    if (!session || !token) {
      widgetHash.credentials = {
        username,
        password,
      };
    }
  };

  useEffect(() => {
    if (incomingStatus === 'available') {
      setupWidgetHash();

      // Only load javascript if not loaded yet
      if (window.BRMSWidget) {
        BRMSWidget.create(widgetHash);
      } else {
        // Add widget javascript to HEAD and load the widget itself
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;

        script.onload = function () {
          if (BRMSWidget) {
            BRMSWidget.create(widgetHash);
          }
        };

        script.src = src_url;
        head.appendChild(script);
      }
      consumer.disconnect();
    } else if (incomingStatus === 'failed') {
      consumer.disconnect();
    }
  }, [incomingStatus]);

  useEffect(() => {
    if (status !== 'available') {
      consumer.subscriptions.create(
        { channel: 'DeploymentsChannel', id: deploymentId },
        {
          connected() {
              this.perform('new_connection');
          },
          received(data) {
            console.log('Received data: ', data);
            if (data.session_id) {
              setParamSessionId(data.session_id);
              setParamSessionToken(data.session_token);
            }
            if (data.status) {
              setIncomingStatus(data.status);
            }
            if (data.message) {
              setMessages(data.message);
            }
          },
        }
      );
    }
  }, [deploymentId]);

  return (
    <>
      {incomingStatus !== 'available' && (
        <div>
          <Title text={widgetMessages[incomingStatus]} />
          <Divider height={10} color={'transparent'} />
          <span className={styles.loader}></span>

          <Divider height={50} color={'transparent'} />

          {incomingMessages && incomingMessages.length > 0 && (
            <div className={styles.codeBlock}>
              {incomingMessages
                .slice()
                .reverse()
                .map((message, index) => (
                  <p key={index}>{message}</p>
                ))}
            </div>
          )}
        </div>
      )}

      {incomingStatus === 'available' && mode === 'preview' && (
        <div>
          <div id='brms-widget'></div>
          <Divider height={50} color={'transparent'} />
          <Button
            action={resetSession}
            text={'Reset Session'}
            color={'assertive'}
          />
        </div>
      )}
    </>
  );
};

export default WidgetWrapper;
