import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Button from '@components/button/button';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Detail from '@components/text/detail';
import Status from '@components/text/status';
import Icon from '@components/ui/icon';
import { Colors, Spacings } from '@components/variables';
import styles from './wrapper.module.scss';

const cx = classNames.bind(styles);

const Wrapper = forwardRef((props, ref) => {
  const classes = cx(
    {
      card: true,
      disabled: props.disabled,
      loading: props.loading,
      action: props.action,
    },
    props.color,
    props.size,
    props.className,
    props.header
  );

  const headerClasses = cx({
    header: true,
    headerWithButtons:
      props.header && props.header.buttons && props.header.buttons.length > 0,
    headerStatus: props.header && props.header.status,
    reverseHeaderText: props.header.badge && props.header.button,
    headerBackground: props.header.headerBackground !== false,
  });

  const lineClass = cx({
    [`line-${props.size}`]: true,
  });

  const renderInner = () => (
    <>
      {props.header && Object.keys(props.header).length > 0 && (
        <div className={headerClasses}>
          {(props.header.title || props.header.content) && (
            <div className={styles.headerText}>
              {props.header.title && (
                <Title className={styles.title} {...props.header.title} />
              )}
              {props.header.content && (
                <Content size={'s'} {...props.header.content} />
              )}
            </div>
          )}
          {props.action && (
            <div className={styles.actionIcon}>
              <Icon
                icon={'chevron-right'}
                color={'light'}
                width={9}
                height={9}
              />
            </div>
          )}
          {props.header.status && <Status {...props.header.status} />}
          {props.header.badge && (
            <Divider
              width={1}
              height={50}
              margin={[0, 30]}
              color={'stable-500'}
            />
          )}
          {props.header.badge && (
            <Detail reverse reverseBadge {...props.header.badge} />
          )}
          {props.header.buttons && (
            <div className={styles.headerButtons}>
              {props.header.buttons.map((item, index) => (
                <Button
                  key={index}
                  className={styles.headerButton}
                  color={'positive'}
                  emphasis
                  link
                  {...item}
                />
              ))}
            </div>
          )}
          {props.header.button && (
            <Button
              className={styles.headerButton}
              color={'positive'}
              emphasis
              {...props.header.button}
            />
          )}
          {props.header.action && (
            <div className={styles.headerIcon}>
              <Button
                color={'transparent'}
                size={'xs'}
                {...props.header.action}
              />
            </div>
          )}
        </div>
      )}
      {props.children}
    </>
  );

  return props.action ? (
    <a href={props.action} className={classes}>
      {renderInner()}
    </a>
  ) : (
    <div className={classes} ref={ref}>
      {renderInner()}
    </div>
  );
});
Wrapper.propTypes = {
  /** The color variant of the card */
  color: PropTypes.oneOf(Colors),
  /** To size of the card (spacing between card & content) */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** To action of the card */
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** To header content of the card (accepts all Title proptypes) */
  header: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(Title.propTypes),
    PropTypes.shape(Content.propTypes),
    PropTypes.shape(Detail.propTypes),
    PropTypes.shape(Status.propTypes),
    PropTypes.shape(Button.propTypes),
    PropTypes.bool,
  ]),
};

Wrapper.defaultProps = {
  color: 'light',
  size: 'l',
  header: false,
  action: false,
};

// Needed for Storybook
Wrapper.displayName = 'Wrapper';

export default Wrapper;
