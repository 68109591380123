import { Controller } from '@hotwired/stimulus';
import consumer from 'channels/consumer';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = ['messages', 'pollStatus', 'modalTitle'];
  static values = {
    deploymentId: Number,
    status: String,
    sessionsUrl: String,
    errorUrl: String,
    sessionId: Number,
    sessionToken: String,
    rulesetLocaleId: Number,
    error: String
  };
  static availableRedirectPerformed = false;

  connect() {
    // removing all previously existing subscriptions because the modal
    // was still listening to the previous deployment channels after reload.
    // Can be fixed more elegantly by storing current deployment_subscription.
    // And then removing that subscription instead of only disconnecting.
    consumer.subscriptions['subscriptions'].forEach((subscription) => {
      let identifier_object = JSON.parse(subscription.identifier);
      if (identifier_object.channel === 'DeploymentsChannel') {
        console.log('Removing lingering subscription');
        consumer.subscriptions.remove(subscription);
      }
    });

    document.addEventListener('turbo:click', () => {
      consumer.disconnect();
    });

    if (this.statusValue !== 'available') {
      this.createSubscription();
    } else {
      this.previewAvailable();
    }
  }

  createSubscription() {
    consumer.subscriptions.create(
      { channel: 'DeploymentsChannel', id: this.deploymentIdValue },
      {
        connected() {
          this.perform('new_connection');
        },
        received: this.handleReceived.bind(this),
      }
    );
  }

  handleReceived(data) {
    if (data.status) {
      this.statusValue = data.status;
    }

    if (data.session_id) {
      this.sessionIdValue = data.session_id;
    }

    if (data.session_token) {
      this.sessionTokenValue = data.session_token;
    }

    if (data.ruleset_locale_id) {
      this.rulesetLocaleIdValue = data.ruleset_locale_id;
    }

    if (data.error) {
      this.errorValue = data.error;
    }

    if (this.statusValue === 'available' && !this.availableRedirectPerformed) {
      this.previewAvailable();
    } else if (this.statusValue === 'failed') {
      this.showFailedState();
    }

    if (
      this.hasMessagesTarget &&
      data.message &&
      (data.message !== undefined || data.message !== '')
    ) {
      let brmsContent = document.createElement('brms-content');
      brmsContent.setAttribute('size', 'code');
      brmsContent.setAttribute('text', data.message);
      this.messagesTarget.appendChild(brmsContent);
    }

    if (
      this.hasPollStatusTarget &&
      data.poll_status &&
      (data.poll_status !== undefined || data.poll_status !== '')
    ) {
      this.pollStatusTarget.innerHTML = `<brms-title text="${data.poll_status}" size='h5' color='stable-900'></brms-title>`;
    }
  }

  previewAvailable() {
    this.availableRedirectPerformed = true;

    if (this.rulesetLocaleIdValue && this.sessionIdValue && this.sessionTokenValue) {
      this.setActiveSessionsCookie();
    }
    // prevent multiple gets from future messages
    consumer.disconnect();

    get(this.sessionsUrlValue, {
      responseKind: 'turbo-stream',
    });
  }

  setActiveSessionsCookie() {
    // Get the active_sessions cookie
    let activeSessions = document.cookie.split('; ').find(row => row.startsWith('active_sessions='));

    // If the cookie exists, parse its value as JSON. Otherwise, initialize as an empty object
    activeSessions = activeSessions ? JSON.parse(decodeURIComponent(activeSessions.split('=')[1])) : {};

    // Set the session_id and session_token as key-value pairs in the object
    activeSessions['RulesetLocale.' + this.rulesetLocaleIdValue] = {session_id: this.sessionIdValue, session_token: this.sessionTokenValue};

    // Update the active_sessions cookie
    document.cookie = `active_sessions=${encodeURIComponent(JSON.stringify(activeSessions))}; path=/`;
  }

  showFailedState() {
    if (this.errorUrlValue && this.errorValue) {
      const url = new URL(this.errorUrlValue);
      url.searchParams.append('error', this.errorValue);

      consumer.disconnect();
      get(url, {
        responseKind: 'turbo-stream',
      });
    }
  }
}
