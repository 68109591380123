import React from 'react';
import { Divider } from '@components/ui';
import CompareCard from '.';

export default {
  title: 'BRMS/card/CompareCard',
  component: CompareCard,
};

const Template = (args) => <CompareCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  header: {
    title: {
      text: 'Rule identifier',
      size: 'h4',
    },
  },
  items: [
    {
      label: 'Name',
      before: 'Ben Saunders',
      after: 'Ben Smith',
    },
    {
      label: 'Name',
      before: 'Ben Saunders',
      after: 'Ben Smith',
    },
  ],
};

export const Variants = () => (
  <>
    <DetailsCard
      {...Default.args}
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      {...Default.args}
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
      }}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      {...Default.args}
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
        status: {
          color: 'positive',
          icon: {
            icon: 'checkmark',
          },
          title: {
            text: 'Published',
          },
        },
      }}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
        content: {
          text: 'Life insurance',
        },
      }}
      details={[
        {
          label: {
            text: 'Status',
          },
          value: {
            status: {
              color: 'royal',
              icon: {
                icon: 'warning',
              },
              title: {
                text: 'Update required',
              },
            },
          },
        },
        {
          label: {
            text: 'Changes',
          },
          value: {
            text: 'Done',
          },
        },
      ]}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
        content: {
          text: 'Life insurance',
        },
      }}
      details={[
        {
          label: {
            text: 'Status',
          },
          value: {
            status: {
              color: 'positive',
              icon: {
                icon: 'checkmark',
              },
              title: {
                text: 'Published',
              },
            },
          },
        },
        {
          label: {
            text: 'Changes',
          },
          value: {
            text: 'Done',
          },
        },
      ]}
    />
  </>
);
