import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Badge } from '@components/badge';
import { Content, List } from '@components/text';
import { Button } from '@components/button';
import { Divider } from '@components/ui';
import styles from './flat.module.scss';

const cx = classNames.bind(styles);

const FlatRow = ({
  column,
  columnspan,
  contentClassName,
  className,
  last,
  final,
  simple,
}) => {
  const columnClasses = cx(
    {
      cell: !simple,
      last,
      final,
      simple,
    },
    className
  );
  const contentClasses = cx(
    {
      cellcontent: true,
      simpleCell: simple,
    },
    contentClassName
  );

  const CustomBadge = ({ badge, index }) => (
    <>
      <div key={index} className={styles.badges}>
        <Badge
          key={index}
          type={badge.type}
          size={'s'}
          content={badge}
          lighten
        />
        {index > -1 && <Divider width={5} color={'transparent'} />}
      </div>
    </>
  );

  // Split array of object into chunks of badges [{}, {}, {}, {}] => [[{}, {}], [{}, {}]]
  // if the current item is '&&\r', and it's not the last item in the original array, add another sub array
  const badgesArray = column?.badges?.reduce((result, item, index, arr) => {
    index || result.push([]);
    result[result.length - 1].push(item);
    (item?.text.replace(/\s/g, '') === '&&' ||
      item?.text.replace(/\s/g, '') === '||') &&
      index < arr.length - 1 &&
      result.push([]);
    return result;
  }, []);

  return (
    <td className={columnClasses} colSpan={columnspan || 1}>
      <div className={contentClasses}>
        {column.action ? (
          <Button link className={styles.button} {...column} />
        ) : (
          <Content color={simple ? 'balanced-500' : 'dark'} {...column} />
        )}
        {column.badges && (
          <div className={styles.badges}>
            <List max={1}>
              {badgesArray?.map((_, index) => (
                <div key={index} className={styles.itemBadge}>
                  {badgesArray[index].map((badge, index) => (
                    <CustomBadge key={index} badge={badge} index={index} />
                  ))}
                </div>
              ))}
            </List>
          </div>
        )}
      </div>
    </td>
  );
};

FlatRow.propTypes = {
  // Contents of the cell
  column: PropTypes.shape({}).isRequired,
  // Width of column
  columnspan: PropTypes.number,
  // Classname of content
  contentClassName: PropTypes.string,
  // Overhailing classname
  className: PropTypes.string,
  // Add specific last cell styling
  last: PropTypes.bool,
  // Add specific final cell styling
  final: PropTypes.bool,
  // Add specific simple cell styling
  simple: PropTypes.bool,
};

FlatRow.defaultProps = {
  columnspan: null,
  contentClassName: '',
  className: '',
  last: false,
  final: false,
  simple: false,
};

export default FlatRow;
