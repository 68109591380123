import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['card', 'select', 'input', 'checkbox', 'target'];

  toggle() {
    if (this.hasTargetTarget && this.hasCheckboxTarget) {
      const target = this.targetTarget;
      const checkbox = this.checkboxTarget;
      const isChecked = checkbox.checked;
      target.hidden = !isChecked;
    }
  }

  toggleDisabled() {
    const selectedOption = this.element.querySelector('#select-version').value;
    const isManual = selectedOption === 'Manuel';
    const parent = inputTarget.closest(`brms-select`);
    this.inputTarget.setAttribute('disabled', !isManual ? 'true' : 'false');
    this.inputTarget.setAttribute(
      'variant',
      !isManual ? 'default' : 'disabled'
    );
  }
}
