import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Colors, Spacings } from '@components/variables';
import Wrapper from '../wrapper';

const HtmlCard = ({ size, color, id, header, initial, className }) => {
  const [loader, setLoader] = useState(true);

  const divWrapper = useRef();
  useEffect(() => {
    // Temporary fix for making cocoon fields work (because of not displaying/loading issues)
    setTimeout(() => {
      if (divWrapper.current) {
        const template = id ? document.getElementById(id) : divWrapper.current.closest('[data-html-card-parent]')?.querySelector('[data-template]');
        if (template && divWrapper.current) {
          if (template.tagName.toLocaleLowerCase() === 'template') {
            const clone = template.content.cloneNode(true);
            divWrapper.current.appendChild(clone);
          } else {
            divWrapper.current.appendChild(template);
            template.classList.remove('u-hide');
          }
        }
      }
      setLoader(false);
    }, initial ? 2000 : 0)
  }, []);

  return (
    <Wrapper size={size} color={color} header={header} className={className}>
      {loader && initial && <img src={'/spinner.svg'} width={20} height={20} />}
      <div ref={divWrapper} />
    </Wrapper>
  );
};

HtmlCard.propTypes = {
  /** The id of the hidden template to be placed inside this card */
  id: PropTypes.string,
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The header of the card */
  header: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  /** Initial means that the card needs to be loaded using the timeOut fix */
  initial: PropTypes.bool,
  /** Adds a custom class to the card */
  className: PropTypes.string,
};

HtmlCard.defaultProps = {
  id: '',
  color: 'light',
  size: 'l',
  header: false,
  initial: true,
  className: '',
};

// Needed for Storybook
HtmlCard.displayName = 'HtmlCard';

export default HtmlCard;
