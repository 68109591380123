import React from 'react';
import { Provider } from 'mobx-react';
import Store from './store/store';
import Chart from './flowchart';

const FlowChart = ({ data, paths, completions, rules, subset, forceLock }) => {
  const store = new Store(data, paths, completions, rules, subset, forceLock);
  return (
    <Provider data={store}>
      <Chart />
    </Provider>
  );
};
export default FlowChart;
