const getAssignmentData = (id, node) => {
  const item = node.find((x) => x.id === ~~id);
  if (item) {
    const rows = item?.assignments[0]?.assignment_rows;
    return rows.map((row, key) => ({
      select: {
        id: row.id.toString(),
        type: 'select',
        attributes: [],
        options: [
          {
            value: row.variable,
            defaultValue: row.variable,
            label: row.variable,
            checked: true,
          },
        ],
      },
      fields: [
        {
          label: 'Operator',
          value: row.operator,
          inline: true,
          background: true,
        },
        {
          label: 'Value',
          value: row.value,
          inline: true,
          background: true,
          condition: row.condition,
          condition_id: row.condition_id,
          type: row.fieldtype,
          possible_operators: row.possible_operators,
          possible_variables: row.possible_variables,
        },
      ],
    }));
  }
};

const assignmentModalConfig = (hide, update, id, node) => ({
  header: {
    title: {
      text: 'Edit assignment',
    },
  },
  data: getAssignmentData(id, node),
  addData: {
    select: {
      id: '1',
      type: 'select',
      attributes: [],
      options: [
        { value: 'question', label: 'Question', checked: true },
        { value: 'premium', label: 'Premium' },
      ],
    },
    fields: [
      {
        label: 'field 1',
        inline: true,
        background: true,
      },
      {
        label: 'field 2',
        inline: true,
        background: true,
      },
    ],
  },
  selectLabel: {
    text: 'Variables',
    size: 'm',
  },
  fieldLabel: {
    text: 'Settings',
    size: 's',
  },
  footer: {
    buttonsLeft: [
      {
        color: 'stable-500',
        outline: true,
        icon: {
          icon: 'trash',
          color: 'assertive',
        },
      },
    ],
    buttonsRight: [
      {
        color: 'stable-500',
        text: 'Cancel',
        textColor: 'positive',
        outline: true,
        action: (e) => hide(e),
      },
      {
        text: 'Save changes',
        action: (e) => update(e),
      },
    ],
  },
});

export default assignmentModalConfig;
