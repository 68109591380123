import { Controller } from '@hotwired/stimulus';
import { newConfirmMethod } from './index';
export default class extends Controller {
  static values = {
    title: String,
    description: String,
    controller: String,
    function: String,
  };

  async toggle() {
    const message = JSON.stringify({
      title: this.hasTitleValue ? this.titleValue : 'Are you sure?',
      description: this.hasDescriptionValue
        ? this.descriptionValue
        : 'This action cannot be undone.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    });
    const confirmed = await newConfirmMethod(message);

    if (confirmed) {
      if (
        this.hasFunctionValue &&
        this.hasControllerValue &&
        this.hasFunctionValue
      ) {
        const outOfScopeController = this.application.controllers.find(
          (controller) => controller.identifier === this.controllerValue
        );
        outOfScopeController[this.functionValue]();
      }
    }
  }
}
