import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    src: String,
    event: String,
  };

  connect() {
    document.addEventListener(
      `rmv-accordion:toggle:${this.eventValue}`,
      (e) => {
        if (!this.element.src) {
          this.element.src = this.srcValue;
        }
      }
    );
  }
}
