import React, { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Element from '@components/form/element';
import Content from '@components/text/content';
import Button from '@components/button/button';
import { cspNonce } from '@rails/ujs';
import styles from './elementGroup.module.scss';

const cx = classNames.bind(styles);

const ElementGroup = ({
  className,
  elements,
  label,
  button,
  newElement,
  newSelectElement,
  newSwitchElement,
  type,
  options,
  disabled,
  remove,
  multi,
}) => {
  const [elementsArray, setElementsArray] = useState(elements);
  const [removeElementsArray, setRemoveElementsArray] = useState([]);
  const classes = cx(
    {
      elementGroup: true,
      multiSelect: type === 'multi-select' && options.length > 2,
    },
    className
  );

  const addElement = () => {
    let elementToAdd = null;

    if (newElement || newSelectElement || newSwitchElement) {
      elementToAdd =
        newSelectElement && (type === 'select' || type === 'multi-select')
          ? newSelectElement
          : newSwitchElement && type === 'switch-element'
          ? newSwitchElement
          : newElement;

      // Deep copy
      elementToAdd = JSON.parse(JSON.stringify(elementToAdd));

      // Allow random parameter for correctly generating name for new elements.
      // Range starts at 1000 to avoid collisions with existing elements.
      const randInt = Math.floor(Math.random() * 1_000_000_000 + 1000);

      if (elementToAdd.name) {
        elementToAdd.name = elementToAdd.name.replace('<RAND_INT>', randInt);
      }

      if (type === 'switch-element' && elementToAdd.modal) {
        elementToAdd.modal.elementTrigger.input.name =
          elementToAdd.modal.elementTrigger.input.name.replace(
            '<RAND_INT>',
            randInt
          );
      }

      if (elementToAdd.destroyName) {
        elementToAdd.destroyName = elementToAdd.destroyName.replace(
          '<RAND_INT>',
          randInt
        );
      }
      setElementsArray((prevState) => [...prevState, elementToAdd]);
    }
  };

  const removeElement = (index) => {
    if (elementsArray) {
      const updateArray = elementsArray.filter(
        (_, itemIndex) => index !== itemIndex
      );
      setElementsArray(updateArray);

      const updateRemoveArray = elementsArray.filter(
        (_, itemIndex) => index === itemIndex
      );

      setRemoveElementsArray((prevState) => [
        ...prevState,
        ...updateRemoveArray,
      ]);
    }
  };

  const buttonFragment = (index) =>
    !disabled &&
    button &&
    newElement &&
    (index === elementsArray.length - 1 || elementsArray.length === 0) && (
      <div className={styles.buttonWrapper}>
        <Button
          action={addElement}
          className={styles.button}
          color={'stable-500'}
          icon={{
            icon: 'plus',
            color: 'positive',
            width: 6,
            height: 6,
          }}
          outline
          {...button}
        />
      </div>
    );

  return (
    <div className={classes}>
      {label && <Content className={styles.label} size={'s'} {...label} />}
      {label && elements && <Divider height={5} color={'transparent'} />}
      {removeElementsArray && (
        <div className={styles.elements}>
          {removeElementsArray.length > 0 &&
            removeElementsArray.map((item, index) => (
              <React.Fragment key={index}>
                <div className={styles.item}>
                  <Element
                    {...{ name: item.destroyName, value: '1' }}
                    type={'hidden'}
                    options={options}
                    disabled={disabled}
                    multi={multi}
                    hidden
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
      )}
      {elementsArray && (
        <div className={styles.elements}>
          {elementsArray.length > 0 &&
            elementsArray.map((item, index) => (
              <React.Fragment key={index}>
                <div className={styles.item}>
                  <Element
                    remove={
                      !disabled &&
                      remove && {
                        action: (e) => {
                          e.preventDefault();
                          removeElement(index);
                        },
                      }
                    }
                    {...item}
                    type={type}
                    options={options}
                    disabled={disabled}
                    multi={multi}
                  />
                </div>
                {buttonFragment(index)}
              </React.Fragment>
            ))}
          {elementsArray.length === 0 && buttonFragment(0)}
        </div>
      )}
    </div>
  );
};

ElementGroup.propTypes = {
  className: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  button: PropTypes.oneOfType([
    PropTypes.shape(Button.propTypes),
    PropTypes.bool,
  ]),
  newElement: PropTypes.shape({}),
  newSelectElement: PropTypes.shape({}),
  newSwitchElement: PropTypes.shape({}),
  label: PropTypes.shape(Content.propTypes),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  remove: PropTypes.bool,
  multi: PropTypes.bool,
};

ElementGroup.defaultProps = {
  className: '',
  elements: null,
  button: false,
  newElement: null,
  newSelectElement: null,
  newSwitchElement: null,
  label: null,
  options: null,
  type: '',
  disabled: false,
  remove: false,
  multi: false,
};

// Needed for Storybook
ElementGroup.displayName = 'ElementGroup';

export default ElementGroup;
