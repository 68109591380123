import React from 'react';
import data from '@components/form/ajaxSelect/data.json';
import RuleModal from '.';

export default {
  title: 'BRMS/flowchart/modals/RuleModal',
  component: RuleModal,
};

const Template = (args) => <RuleModal {...args} data={data} />;

export const Default = Template.bind({});

Default.args = {
  isOpen: true,
  header: {
    title: {
      text: 'Add rule',
    },
  },
  footer: {
    buttonsLeft: [
      {
        color: 'stable-500',
        outline: true,
        icon: {
          name: 'trash',
          color: 'assertive',
        },
      },
    ],
    buttonsRight: [
      {
        color: 'stable-500',
        text: 'Cancel',
        textColor: 'positive',
        outline: true,
      },
      {
        text: 'Save changes',
      },
    ],
  },
  codeEditor: {},
  status: {
    icon: {
      icon: 'warning',
    },
    title: {
      text: 'There seems to be a problem with the activation condition',
    },
    color: 'royal',
  },
};
