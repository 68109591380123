import React from 'react';
import ToolTip from '.';

export default {
  title: 'BRMS/flowchart/cards/ToolTip',
  component: ToolTip,
};

const Template = (args) => (
  <ToolTip {...args}>
    <div style={{ margin: 100, textAlign: 'center' }}>Hover me</div>
  </ToolTip>
);

export const Default = Template.bind({});

Default.args = {
  buttons: [
    {
      outline: false,
      color: 'transparent',
      action: () => {},
      icon: {
        name: 'edit',
        color: 'positive',
        width: '32',
        height: '32',
      },
    },
    {
      outline: false,
      color: 'transparent',
      action: () => {},
      icon: {
        name: 'trash',
        color: 'assertive',
        width: 32,
        height: 32,
      },
    },
  ],
};
