import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './reading.module.scss';
import Simple from '../simple/index';
import Title from '@components/text/title';
import Content from '@components/text/content';
import Divider from '@components/ui/divider';
import Button from '@components/button/button';
import ReadingCard from '@components/card/readingCard';
import Group from '../group/index';
import { values } from 'lodash';

const cx = classNames.bind(styles);

const Reading = ({ className, group, inputLabel, title, value, newCard, submit, onChange, variableNames, aggregationMethod }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(value.count || null);
  const componentValue = value;
  // Sets the radio buttons
  const [localOptions, setLocalOptions] = useState(group.options);

  const classes = cx(
    {
      reading: true,
    },
    className
  );
  const newOptions = group.options;

  // Sets the default checked option based on the amount of readings.
  if (data.length) {
    newOptions[data.length - 1].checked = true
  }

  // Updates the 'count' state with the current option (radio) input value
  const handleChange = (event) => {
    if (event && event.target.value >= 0) {
      setCount(event.target.value);
      setData([]);

      newOptions.map(option => {
        if (option.value === event.target.value) {
          option.checked = true;
        }
        else {
          option.checked = false;
        }
      });
      setLocalOptions(newOptions);
    }
  }

  useEffect(() => {
    let array = [];

    // fill Data array based on count
    if (count !== null) {
      for (let i = 0; i < count; i++) {
        array[i] = data[i] ? data[i] : newCard;
      }
      setData(array);
    } else {
      setData(initialData || []);
    }
  }, [count]);

  const handleReading = (e, index) => {
    const { value, id } = e.target;

    const newData = [...data];

    const result = newData.map((item, x) => {
      if(x !== index) return item;

      const variable = newCard.variables[index + 1][id];

      variable.input.value = value;

      if (componentValue?.readings[variable.variable.id]) {
        if (typeof componentValue.readings[variable.variable.id][index] !== 'object' ) {
          componentValue.readings[variable.variable.id][index] = new Object();
        }
        componentValue.readings[variable.variable.id][index][id] = value;
      }

      return {
        ...item,
        variables: newCard.variables
      }
    });

    setData(result);
  };

  const aggregatedFunc = (array, method) => {
    const keys = Object.keys(array);
    let values = [];

    keys.map((key) => {
      values = values.concat(Object.values(array[key]));
    });

    if(method === 'agg_min') {
      return Math.min(...values);
    }
    if(method === 'agg_max') {
      return Math.max(...values);
    }
    if(method === 'agg_avg') {
      return values.reduce((a, b) => Number(a) + Number(b), 0) / values.length;
    }
  }

  let initialData = [];
  let readingsLength = 0
  useEffect(() => {
    componentValue.questions = newCard.variables;

    if (Array.isArray(value)) {
      value = value[0];
    }
    if (value !== null) {
      readingsLength = Object.values(value?.readings)[0]?.length;
      for (let i = 0; i < readingsLength; i++) {
        initialData[i] = {
          title: title,
          variables: newCard.variables
        }
      }
    }
  }, [value])

  const submitValues = () => {
    for (const variableName of variableNames) {
      // only send readings based on reading count
      componentValue.readings[variableName] = Object.fromEntries(Object.entries(componentValue.readings[variableName]).slice(0, count));
      componentValue.aggregated[variableName] = aggregatedFunc(componentValue.readings[variableName], componentValue.aggregationMethod[variableName]);
    }
    // only send questions based on reading count
    componentValue.questions = Object.fromEntries(Object.entries(newCard.variables).slice(0, count));
    componentValue.count = count;
    let componentValueArray = componentValue;
    onChange && onChange(componentValueArray, 'reading');
  }

  return (
    <div className={classes}>
      {inputLabel && <Content size={'s'} {...inputLabel} />}
      {(inputLabel && group) && <Divider height={5} color={'transparent'} />}
      {group && <Group type={'radio'} onChange={handleChange} options={localOptions} {...group} />}
      {group && <Divider height={20} color={'transparent'} />}
      {/* Looping through the reading cards based on the input value (count) */}
      {data?.map((item, index) => {
        return (
          <ReadingCard key={index} index={index+1} onChange={(e) => handleReading(e, index)} className={styles.readingCard} {...item} />
        )
      })}
      {submit && (
        <>
          <Divider height={20} color={'transparent'} />
          <Button {...submit} action={() => submitValues()} />
        </>
      )}
    </div>
  );
};

Reading.propTypes = {
  /** Adds a custom class */
  className: PropTypes.string,
  group: PropTypes.shape(Group.propTypes),
  /** The label of the input */
  inputLabel: PropTypes.shape(Content.propTypes),
  /** The card title */
  title: PropTypes.shape(Title.propTypes),
  /** The added new card in the cards array when the input value changes */
  newCard: PropTypes.oneOfType([
    PropTypes.shape(Title.propTypes),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(Content.propTypes),
        PropTypes.shape(Simple.propTypes),
      ])
    )
  ]),
  /** The submit button */
  submit: PropTypes.shape(Button.propTypes),
  /** The initial data (based on the onChange => output format) */
  value: PropTypes.shape({}).isRequired
};

Reading.defaultProps = {
  className: '',
  group: null,
  inputLabel: null,
  title: null,
  newCard: null,
  submit: null,
  variableNames: ['Diastolic', 'Systolic'],
  aggregationMethod: {
    Diastolic: 'min',
    Systolic: 'avg'
  },
  value: null,
};

// Needed for Storybook
Reading.displayName = 'Reading';

export default Reading;
