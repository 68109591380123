import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import { Title, Content } from '@components/text';
import Wrapper from '../wrapper';
import Simple from '@components/form/simple';
import styles from './readingCard.module.scss';

const cx = classNames.bind(styles);

const ReadingCard = ({ className, index, variables, title, onChange }) => {
  const classes = cx(
    {
      readingCard: true,
    },
    className
  );

  return (
    <Wrapper className={classes}>
      {title && <Title size={'h3'} {...title} text={title.text + ' ' + index} />}
      {title && variables && <Divider height={20} color={'transparent'} />}
      {variables[index]?.map((item, i) =>
        <React.Fragment key={i}>
          {i !== 0 && <Divider height={10} color={'transparent'} />}
          {item?.label && <Content size={'s'} {...item.label} />}
          {<Divider height={5} color={'transparent'} />}
          <Simple id={`${i}`} type={'text'} className={styles.input} onChange={onChange} {...item.input} />
        </React.Fragment>
      )}
    </Wrapper>
  );
};

ReadingCard.propTypes = {
  /** Title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** index used to map variables array */
  index: PropTypes.number,
  /** Input fields */
  variables: PropTypes.array,
  /** Input field onChange */
  onChange: PropTypes.func,
  /** Adds a custom class to the card */
  className: PropTypes.string,
};

ReadingCard.defaultProps = {
  title: null,
  index: 0,
  variables: null,
  onChange: null,
  className: '',
};

// Needed for Storybook
ReadingCard.displayName = 'ReadingCard';

export default ReadingCard;
