import React from 'react';
import PropTypes from 'prop-types';
import Title from '@components/text/title';
import Content from '@components/text/content';
import classNames from 'classnames/bind';
import styles from './assignment.module.scss';
import ToolTip from '../../tooltip';

const cx = classNames.bind(styles);

const AssignmentCard = ({ id, title, assignment_rows, className, buttons, disabled }) => {
  const wrapper = cx(
    {
      assignmentWrapper: true,
    },
    className
  );

  const renderWrapper = () => (
    <div className={wrapper}>
      <div className={styles.contentWrapper}>
        {title && (
          <div className={styles.header}>
            <Content
              color={'balanced-500'}
              size={'s'}
              text={title.text}
              className={styles.title}
            />
          </div>
        )}
        {assignment_rows && (
          <div className={styles.rows}>
            {assignment_rows
              .filter((r) => r.variable || r.operator || r.value || r.condition)
              .map((row, i) => (
                <div className={styles.rowItem} key={i}>
                  {row.variable && (
                    <Content
                      color={'balanced-500'}
                      size={'s'}
                      text={row.variable}
                      className={styles.rowVariable}
                    />
                  )}
                  {row.operator && (
                    <Content
                      color={'balanced-500'}
                      size={'s'}
                      text={row.operator}
                      className={styles.rowOperator}
                    />
                  )}
                  {row.condition && (                     
                    <Content
                      color={'balanced-500'}
                      size={'s'}
                      text={row.condition}
                      className={styles.rowValue}
                    />)}
                  {!row.condition && row.value && (
                    <Content
                      color={'balanced-500'}
                      size={'s'}
                      text={row.value}
                      className={styles.rowValue}
                    />
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );

  return !disabled && buttons && buttons.length > 0 ? (
    <ToolTip buttons={buttons}>{renderWrapper()}</ToolTip>
  ) : (
    renderWrapper()
  );
};

AssignmentCard.propTypes = {
  /** The id of the node */
  id: PropTypes.string,
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The row items of the card */
  assignment_rows: PropTypes.arrayOf(
    PropTypes.shape({
      variable: PropTypes.string,
      operator: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Disables card interaction */
  disabled: PropTypes.bool,
};

AssignmentCard.defaultProps = {
  id: null,
  title: '',
  assignment_rows: null,
  className: '',
  disabled: false,
};

// Needed for Storybook
AssignmentCard.displayName = 'AssignmentCard';

export default AssignmentCard;
