const dummyData = {
  id: 3,
  subset_rules: [
    {
      id: 39,
      activation: {
        from_ids: [],
        condition: null,
      },
      assignments: [
        {
          id: 11,
          assignment_rows: [
            {
              id: 11,
              variable: 'very_long_variable_very_long_variable',
              operator: '=',
              value: 'true',
            },
          ],
          condition: 'illness_3.date_symptoms < variable.policy_date',
        },
        {
          id: 12,
          assignment_rows: [
            {
              id: 12,
              variable: 'CT',
              operator: '!=',
              value: 'true',
            },
          ],
          condition: 'Yes',
          simple: true,
        },
      ],
      subset_activations: [],
      title:
        'Are you waiting for tests or the result of tests on connection with this condition',
      type: 'Question (bool)',
      x: null,
      y: null,
    },
    {
      id: 40,
      activation: {
        from_ids: [39],
        condition: 'test',
      },
      assignments: [
        {
          id: 12,
          assignment_rows: [
            {
              id: 12,
              variable: 'CT',
              operator: '=',
              value: 'true',
            },
          ],
          condition: 'illness_3.date_diagnose < variable.policy_date',
        },
      ],
      subset_activations: [],
      title: 'date_diagnose',
      type: 'QuestionRule::Date',
      x: null,
      y: null,
    },
    {
      id: 41,
      activation: {
        from_ids: [40],
        condition: 'illness_3.date_diagnose != null',
      },
      assignments: [],
      subset_activations: [
        {
          id: 13,
          title: 'Anaemia pregnancy rule',
          condition: 'Yes',
          simple: true,
        },
        {
          id: 14,
          title: 'Anaemia pregnancy rule 2',
          condition: 'No',
          simple: true,
        },
      ],
      title: 'date_sick_leave',
      type: 'QuestionRule::Date',
      x: null,
      y: null,
    },
    {
      id: 42,
      activation: {
        from_ids: [41],
        condition: 'illness_3.date_sick_leave < variable.policy_date',
      },
      assignments: [],
      subset_activations: [],
      title: 'sick_leave_termination',
      type: 'QuestionRule::Date',
      x: null,
      y: null,
    },
    {
      id: 43,
      activation: {
        from_ids: [41],
        condition: 'illness_3.date_sick_leave != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'details',
      type: 'QuestionRule::Text',
      x: null,
      y: null,
    },
    {
      id: 44,
      activation: {
        from_ids: [41],
        condition: 'illness_3.date_sick_leave != null',
      },
      assignments: [
        {
          id: 11,
          assignment_rows: [
            {
              id: 11,
              variable: 'CT',
              operator: '=',
              value: 'true',
            },
          ],
          condition: 'illness_3.date_symptoms < variable.policy_date',
        },
        {
          id: 12,
          assignment_rows: [
            {
              id: 12,
              variable: 'CT',
              operator: '!=',
              value: 'true',
            },
          ],
          condition: 'Yes',
          simple: true,
        },
      ],
      subset_activations: [],
      title: 'symptoms',
      type: 'QuestionRule::Text',
      x: null,
      y: null,
    },
    {
      id: 45,
      activation: {
        from_ids: [43, 44],
        condition: 'illness_3.details != null && illness_3.symptoms != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'hospitalized',
      type: 'QuestionRule::Boolean',
      x: null,
      y: null,
    },
    {
      id: 46,
      activation: {
        from_ids: [45],
        condition: 'illness_3.hospitalized == true',
      },
      assignments: [],
      subset_activations: [],
      title: 'number_of_days',
      type: 'QuestionRule::Number',
      x: null,
      y: null,
    },
    {
      id: 47,
      activation: {
        from_ids: [46],
        condition: 'illness_3.number_of_days != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'hospital_report',
      type: 'QuestionRule::Boolean',
      x: null,
      y: null,
    },
    {
      id: 48,
      activation: {
        from_ids: [45, 47],
        condition:
          'illness_3.hospitalized == false || illness_3.hospital_report != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'surgery',
      type: 'QuestionRule::Boolean',
      x: null,
      y: null,
    },
    {
      id: 49,
      activation: {
        from_ids: [48],
        condition: 'illness_3.surgery == true',
      },
      assignments: [],
      subset_activations: [],
      title: 'surgery_time',
      type: 'QuestionRule::Number',
      x: null,
      y: null,
    },
    {
      id: 50,
      activation: {
        from_ids: [49],
        condition: 'illness_3.surgery_time != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'surgery_report',
      type: 'QuestionRule::Boolean',
      x: null,
      y: null,
    },
    {
      id: 51,
      activation: {
        from_ids: [48, 50],
        condition:
          'illness_3.surgery == false || illness_3.surgery_report != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'treatment_type',
      type: 'QuestionRule::Radio',
      x: null,
      y: null,
    },
    {
      id: 52,
      activation: {
        from_ids: [51],
        condition: 'illness_3.treatment_type != null',
      },
      assignments: [],
      subset_activations: [],
      title: 'fully_recovered',
      type: 'QuestionRule::Boolean',
      x: null,
      y: null,
    },
    {
      id: 53,
      activation: {
        from_ids: [52],
        condition: 'illness_3.fully_recovered == true',
      },
      assignments: [],
      subset_activations: [],
      title: 'recovered_since',
      type: 'QuestionRule::Date',
      x: null,
      y: null,
    },
    {
      id: 54,
      activation: {
        from_ids: [53],
        condition: 'illness_3.recovered_since != null',
      },
      assignments: [
        {
          id: 13,
          assignment_rows: [
            {
              id: 13,
              variable: 'CT',
              operator: '=',
              value: 'true',
            },
          ],
          condition: null,
        },
      ],
      subset_activations: [],
      title: 'last_doctor_visit',
      type: 'QuestionRule::Date',
      x: null,
      y: null,
    },
    {
      id: 55,
      activation: {
        from_ids: [52],
        condition: 'illness_3.fully_recovered == false',
      },
      assignments: [],
      subset_activations: [],
      title: 'complications',
      type: 'QuestionRule::Boolean',
      x: null,
      y: null,
    },
    {
      id: 56,
      activation: {
        from_ids: [55],
        condition: 'illness_3.complications == false',
      },
      assignments: [
        {
          id: 14,
          assignment_rows: [
            {
              id: 14,
              variable: 'CT',
              operator: '=',
              value: 'true',
            },
          ],
          condition: null,
        },
      ],
      subset_activations: [],
      title: 'reason_not_recovered',
      type: 'QuestionRule::Text',
      x: null,
      y: null,
    },
    {
      id: 57,
      activation: {
        from_ids: [55],
        condition: 'illness_3.complications == true',
      },
      assignments: [
        {
          id: 15,
          assignment_rows: [
            {
              id: 15,
              variable: 'CT',
              operator: '=',
              value: 'true',
            },
          ],
          condition: null,
        },
      ],
      subset_activations: [],
      title: 'type_of_complication',
      type: 'QuestionRule::Text',
      x: null,
      y: null,
    },
  ],
  completions: [
    {
      name: 'cause.prev_symptoms',
      value: 'cause.prev_symptoms',
      score: 1,
      meta: 'rule in current subset',
    },
    {
      name: 'cause.date_symptoms',
      value: 'cause.date_symptoms',
      score: 1,
      meta: 'rule in current subset',
    },
    {
      name: 'cause.details',
      value: 'cause.details',
      score: 1,
      meta: 'rule in current subset',
    },
    {
      name: 'cause.accident_BD',
      value: 'cause.accident_BD',
      score: 1,
      meta: 'rule in current subset',
    },
    { name: 'today()', value: 'today()', score: 1, meta: 'current date' },
    {
      name: 'age_in_years()',
      value: 'age_in_years()',
      score: 1,
      meta: 'difference in years between current date and provided date',
    },
    {
      name: 'age_in_months()',
      value: 'age_in_months()',
      score: 1,
      meta: 'difference between current date and provided date in months',
    },
    {
      name: 'age_in_days()',
      value: 'age_in_days()',
      score: 1,
      meta: 'difference between current date and provided date in days',
    },
    {
      name: 'difference_in_years()',
      value: 'difference_in_years()',
      score: 1,
      meta: 'returns the difference in years between two dates',
    },
    {
      name: 'difference_in_months()',
      value: 'difference_in_months()',
      score: 1,
      meta: 'difference between provided dates in months',
    },
    {
      name: 'difference_in_days()',
      value: 'difference_in_days()',
      score: 1,
      meta: 'difference between provided dates in days',
    },
    {
      name: 'included_any()',
      value: 'included_any()',
      score: 1,
      meta: 'returns true if any provided item is inside the collection',
    },
    {
      name: 'included_all()',
      value: 'included_all()',
      score: 1,
      meta: 'returns true if all provided item are inside the collection',
    },
    {
      name: 'excluded_any()',
      value: 'excluded_any()',
      score: 1,
      meta: 'returns true if any provided item is not inside the collection',
    },
    {
      name: 'excluded_all()',
      value: 'excluded_all()',
      score: 1,
      meta: 'returns true if all provided items are not inside the collection',
    },
    {
      name: 'to_number()',
      value: 'to_number()',
      score: 1,
      meta:
        'returns the input as number (returns 0 if it cannot be casted to number)',
    },
    {
      name: 'included_in_tracking_values()',
      value: 'included_in_tracking_values()',
      score: 1,
      meta: 'returns true if the item is a value inside the tracking variable',
    },
    {
      name: 'sum_tracking_values()',
      value: 'sum_tracking_values()',
      score: 1,
      meta:
        'returns summed numeric values inside tracking variable as a decimal number',
    },
    {
      name: 'included()',
      value: 'included()',
      score: 1,
      meta: 'returns true if any provided item is inside the collection',
    },
    {
      name: 'excluded()',
      value: 'excluded()',
      score: 1,
      meta: 'returns true if any provided item is not inside the collection',
    },
  ],
  functions: [
    { name: 'today()', value: 'today()', score: 1, meta: 'current date' },
    {
      name: 'age_in_years()',
      value: 'age_in_years()',
      score: 1,
      meta: 'difference in years between current date and provided date',
    },
    {
      name: 'age_in_months()',
      value: 'age_in_months()',
      score: 1,
      meta: 'difference between current date and provided date in months',
    },
    {
      name: 'age_in_days()',
      value: 'age_in_days()',
      score: 1,
      meta: 'difference between current date and provided date in days',
    },
    {
      name: 'difference_in_years()',
      value: 'difference_in_years()',
      score: 1,
      meta: 'returns the difference in years between two dates',
    },
    {
      name: 'difference_in_months()',
      value: 'difference_in_months()',
      score: 1,
      meta: 'difference between provided dates in months',
    },
    {
      name: 'difference_in_days()',
      value: 'difference_in_days()',
      score: 1,
      meta: 'difference between provided dates in days',
    },
    {
      name: 'included_any()',
      value: 'included_any()',
      score: 1,
      meta: 'returns true if any provided item is inside the collection',
    },
    {
      name: 'included_all()',
      value: 'included_all()',
      score: 1,
      meta: 'returns true if all provided item are inside the collection',
    },
    {
      name: 'excluded_any()',
      value: 'excluded_any()',
      score: 1,
      meta: 'returns true if any provided item is not inside the collection',
    },
    {
      name: 'excluded_all()',
      value: 'excluded_all()',
      score: 1,
      meta: 'returns true if all provided items are not inside the collection',
    },
    {
      name: 'to_number()',
      value: 'to_number()',
      score: 1,
      meta:
        'returns the input as number (returns 0 if it cannot be casted to number)',
    },
    {
      name: 'included_in_tracking_values()',
      value: 'included_in_tracking_values()',
      score: 1,
      meta: 'returns true if the item is a value inside the tracking variable',
    },
    {
      name: 'sum_tracking_values()',
      value: 'sum_tracking_values()',
      score: 1,
      meta:
        'returns summed numeric values inside tracking variable as a decimal number',
    },
    {
      name: 'included()',
      value: 'included()',
      score: 1,
      meta: 'returns true if any provided item is inside the collection',
    },
    {
      name: 'excluded()',
      value: 'excluded()',
      score: 1,
      meta: 'returns true if any provided item is not inside the collection',
    },
  ],
};

const newNode = (id, x, y) => ({
  id: id,
  activation: {
    from_ids: [],
    condition: 'test',
  },
  assignments: [],
  subset_activations: [],
  title: 'date_diagnose',
  type: 'QuestionRule::Date',
  x: x,
  y: y,
});

export { dummyData, newNode };
