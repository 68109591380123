import React from 'react';
import CheckGroup from '.';

export default {
  title: 'BRMS/form/CheckGroup',
  component: CheckGroup,
};

const Template = (args) => (
  <>
    <CheckGroup {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  options: [
    {
      checked: false,
      label: 'Male',
      name: 'claim[gender]',
      value: 'male',
    },
    {
      checked: false,
      label: 'Female',
      name: 'claim[gender]',
      value: 'female',
    },
  ],
  select: {
    type: 'select',
    name: 'selectName',
    id: '#select1',
    options: [
      {
        checked: false,
        label: 'Male',
        name: 'claim[gender]',
        value: 'male',
      },
      {
        checked: false,
        label: 'X',
        name: 'claim[gender]',
        value: 'x',
      },
      {
        checked: false,
        label: 'Helicopter',
        name: 'claim[gender]',
        value: 'helicopter',
      },
    ],
  },
  button: {
    text: 'Other choice?',
  },
  onChange: (e) => console.log(e),
};

export const With_Label_Action = (args) => <CheckGroup {...args} />;

With_Label_Action.args = {
  options: [
    {
      checked: false,
      label: 'Male',
      name: 'claim[gender]',
      value: 'male',
      labelAction: {
        size: 'xs',
        text: '(Continue answering)',
        action: '#',
        link: true,
        color: 'balanced-500',
      },
    },
    {
      checked: false,
      label: 'Female',
      name: 'claim[gender]',
      value: 'female',
      labelAction: {
        size: 'xs',
        text: '(Continue answering)',
        action: '#',
        link: true,
        color: 'balanced-500',
      },
    },
  ],
  select: {
    type: 'select',
    name: 'selectName',
    id: '#select1',
    options: [
      {
        checked: false,
        label: 'Male',
        name: 'claim[gender]',
        value: 'male',
        labelAction: {
          size: 'xs',
          text: '(Continue answering)',
          action: '#',
          link: true,
          color: 'balanced-500',
        },
      },
      {
        checked: false,
        label: 'X',
        name: 'claim[gender]',
        value: 'x',
        labelAction: {
          size: 'xs',
          text: '(Continue answering)',
          action: '#',
          link: true,
          color: 'balanced-500',
        },
      },
      {
        checked: false,
        label: 'Helicopter',
        name: 'claim[gender]',
        value: 'helicopter',
        labelAction: {
          size: 'xs',
          text: '(Continue answering)',
          action: '#',
          link: true,
          color: 'balanced-500',
        },
      },
    ],
  },
  button: {
    text: 'Other choice?',
  },
};

export const With_Checked_Select_Options = (args) => <CheckGroup {...args} />;

With_Checked_Select_Options.args = {
  options: [
    {
      checked: false,
      label: 'Male',
      name: 'claim[gender]',
      value: 'male',
      labelAction: {
        size: 'xs',
        text: '(Continue answering)',
        action: '#',
        link: true,
        color: 'balanced-500',
      },
    },
    {
      checked: false,
      label: 'Female',
      name: 'claim[gender]',
      value: 'female',
      labelAction: {
        size: 'xs',
        text: '(Continue answering)',
        action: '#',
        link: true,
        color: 'balanced-500',
      },
    },
  ],
  select: {
    type: 'select',
    name: 'selectName',
    id: '#select1',
    options: [
      {
        checked: true,
        label: 'Male',
        name: 'claim[gender]',
        value: 'male',
        labelAction: {
          size: 'xs',
          text: '(Continue answering)',
          action: '#',
          link: true,
          color: 'balanced-500',
        },
      },
      {
        checked: true,
        label: 'X',
        name: 'claim[gender]',
        value: 'x',
        labelAction: {
          size: 'xs',
          text: '(Continue answering)',
          action: '#',
          link: true,
          color: 'balanced-500',
        },
      },
      {
        checked: false,
        label: 'Helicopter',
        name: 'claim[gender]',
        value: 'helicopter',
        labelAction: {
          size: 'xs',
          text: '(Continue answering)',
          action: '#',
          link: true,
          color: 'balanced-500',
        },
      },
    ],
  },
  button: {
    text: 'Other choice?',
  },
};
