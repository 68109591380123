import React from 'react';
import Blank from '.';

export default {
  title: 'BRMS/ui/Blank',
  component: Blank,
};

const Template = (args) => <Blank {...args} />;

export const Default = Template.bind({});

Default.args = {
  icon: {
    icon: 'empty',
  },
  title: {
    text: 'No items have been added',
  },
  content: {
    text: 'You can add a new item in the top right',
  },
};
