import React from 'react';
import PropTypes from 'prop-types';
import Detail from '@components/text/detail';
import Title from '@components/text/title';
import Icon from '@components/ui/icon';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '../wrapper';
import styles from './compare.module.scss';

const cx = classNames.bind(styles);

const CompareCard = ({ header, items }) => {
  return (
    <Wrapper header={header}>
      <div className={styles.inner}>
        {items.map((item) => <div className={styles.row}>
          <div className={styles.before}>
            <Detail label={{text: item.label}} value={{text: item.before}}  />
          </div>
          <div className={styles.arrow}>
            <Icon icon="chevron-right" width={6} heigth={6} color="light" />
          </div>
          <div className={styles.after}>
            <Detail label={{text: item.label}} value={{text: item.after}}  />
          </div>
        </div>)}
      </div>
    </Wrapper>
  );
};

CompareCard.propTypes = {
  /** Header of the card (accepts all title props) */
  header: PropTypes.shape(Title.propTypes),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

CompareCard.defaultProps = {
  color: 'light',
  size: 'm',
  className: '',
  action: '',
  header: {},
  details: [],
};

// Needed for Storybook
CompareCard.displayName = 'CompareCard';

export default CompareCard;
