import React from 'react';
import PropTypes from 'prop-types';
import Content from '@components/text/content';
import Title from '@components/text/title';
import Icon from '@components/ui/icon';
import classNames from 'classnames/bind';
import styles from './subset.module.scss';
import ToolTip from '../../tooltip';

const cx = classNames.bind(styles);

const SubsetCard = ({ id, title, className, link, icon, buttons }) => {
  const cardClasses = cx({ wrapper: true }, className);
  const Component = link ? 'a' : 'div';

  return (
    <ToolTip buttons={buttons}>
      <div className={cardClasses}>
        <Component href={link} className={styles.link}>
          {title && (
            <Content
              color={'positive'}
              size={'m'}
              className={styles.title}
              {...title}
            />
          )}
          {icon && (
            <Icon className={styles.icon} width={20} color="light" height={20} {...icon} />
          )}
        </Component>
      </div>
    </ToolTip>
  );
};

SubsetCard.propTypes = {
  /** The id of the node */
  id: PropTypes.string,
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** Link of rule */
  link: PropTypes.string,
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** The icon of the card */
  icon: PropTypes.shape(Icon.propTypes),
};

SubsetCard.defaultProps = {
  id: null,
  className: '',
  title: '',
  link: '',
  icon: {
    icon: 'back-round',
  },
};

// Needed for Storybook
SubsetCard.displayName = 'SubsetCard';

export default SubsetCard;
