import React, { useState, useEffect } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import classNames from 'classnames/bind';
import Divider from '@components/ui/divider';
import Button from '@components/button/button';
import Option from '@components/form/option';
import Select from '@components/form/select';
import AsynchronousSelect from '@components/form/asynchronousSelect';
import styles from './checkGroup.module.scss';

const cx = classNames.bind(styles);

const CheckGroup = ({
  className,
  defaultInput,
  options,
  select,
  button,
  onChange,
  answersEndpoint,
  children,
}) => {
  const [inputOptions, setInputOptions] = useState(options);
  const [selectOptions, setSelectOptions] = useState(select.options);
  const [active, setActive] = useState(false);
  const [localValue, setValue] = useState([
    ...options.filter((prop) => prop.checked).map((p) => p.value),
    ...select.options.filter((prop) => prop.checked).map((p) => p.value),
  ]);

  const classes = cx(
    {
      checkGroup: true,
    },
    className
  );

  const handleSelect = (e) => {
    const value = [...localValue, e.name];
    e.checked = true;
    setValue(value);
    setInputOptions([...inputOptions, e]);
    setActive(false);
    onChange && onChange(value, 'checkbox');
  };

  const changeValue = (elem) => {
    let value = [];
    if (elem.target.checked) {
      value = [...localValue, elem.target.value];
      setValue(value);
    } else {
      value = localValue.filter((name) => name != elem.target.value);
      setValue(value);
    }
    onChange && onChange(value, 'checkbox');
  };

  useEffect(() => {
    const additionalOptions = select.options.filter((option) => option.checked);
    setInputOptions([...options, ...additionalOptions]);
    setSelectOptions(select.options.filter((option) => !option.checked));
  }, [options, select.options]);

  return (
    <div className={classes}>
      {defaultInput &&
        inputOptions?.map((item, index) => (
          <React.Fragment key={index}>
            <div className={styles.item}>
              <Option
                type={'checkbox'}
                {...item}
                onChange={(e) => {
                  if (onChange) {
                    changeValue(e);
                  }
                }}
              />
            </div>
            <Divider height={10} color={'transparent'} />
          </React.Fragment>
        ))}
      {active && (
        <AsynchronousSelect
          onChange={handleSelect}
          {...select}
          attributes={[]}
          options={selectOptions}
          endpoint={answersEndpoint}
        />
      )}
      <Divider height={10} color={'transparent'} />
      {button && (
        <Button
          action={() => setActive(!active)}
          text={{ title: 'Other choice?' }}
          {...button}
        />
      )}
      {children && children}
    </div>
  );
};

CheckGroup.propTypes = {
  className: PropTypes.string,
  defaultInput: PropTypes.shape(Element.propTypes),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  button: PropTypes.shape(Button.propTypes),
  select: PropTypes.shape(Select.propTypes),
  children: PropTypes.node,
};

CheckGroup.defaultProps = {
  className: '',
  defaultInput: { type: 'checkbox', name: 'checkbox' },
  options: null,
  button: null,
  select: null,
  children: null,
};

// Needed for Storybook
CheckGroup.displayName = 'CheckGroup';

export default CheckGroup;
