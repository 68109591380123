import React from 'react';
import ReadingCard from '.';

export default {
  title: 'BRMS/card/ReadingCard',
  component: ReadingCard,
};

const Template = (args) => (
  <>
    <ReadingCard {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Reading',
  },
  variables: [
    [
      {
        label: {
          text: 'Enter your things',
        },
        input: {
          value: '',
        },
      },
      {
        label: {
          text: 'Enter your thingss',
        },
        input: {
          value: '',
        },
      },
    ],
  ],
};
