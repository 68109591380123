import { Controller } from '@hotwired/stimulus';

// This controller needs some refactoring: https://remarkgroup.atlassian.net/browse/BRMS-3642
export default class extends Controller {
  static targets = ['input', 'output'];
  static values = { showIf: String };

  connect() {
    this.waitForInputTarget();
  }

  waitForInputTarget() {
    if (this.hasInputTarget) {
      // Input target is already available, proceed with your logic
      this.toggle();
    } else {
      // Set up a MutationObserver to watch for changes in the DOM
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (this.hasInputTarget) {
            // Input target is now available, proceed with your logic
            this.toggle();
            // Disconnect the observer since we no longer need it
            observer.disconnect();
            break;
          }
        }
      });

      // Start observing the document for changes
      observer.observe(document, { childList: true, subtree: true });
    }
  }

  toggle() {
    setTimeout(() => {
      const option = this.inputTarget.querySelector('option');
      if (option && option.value != this.showIfValue) {
        this.outputTarget.style.display = 'none';
      } else if (this.inputTarget.innerHTML.includes(this.showIfValue)) {
        this.outputTarget.style.display = 'flex';
      }
    }, 1);
  }
}
