import React from 'react';
import ElementGroup from '.';

export default {
  title: 'BRMS/form/ElementGroup',
  component: ElementGroup,
};

const Template = (args) => (
  <>
    <ElementGroup {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  label: {
    text: 'Values',
  },
  button: true,
  elements: [
    {
      name: 'name[]',
      type: 'text',
    },
    {
      name: 'name[]',
      type: 'text',
    },
    {
      name: 'name[]',
      type: 'text',
    },
    {
      name: 'name[]',
      type: 'text',
    },
    {
      name: 'name[]',
      type: 'text',
    },
  ],
  newElement: {
    type: 'text',
    name: 'name[]',
  },
  newSelectElement: {
    type: 'select',
    name: 'name[]',
  },
};

export const withSwitchElement = () => (
  <ElementGroup
    {...Default.args}
    type={'switch-element'}
    options={[
      { text: 'I know when it was' },
      { text: 'I know how old I was' },
      { text: 'I am not sure' },
    ]}
    elements={[
      {
        initialDate: '2020-05-20',
        modal: {
          id: 'SwitchElementModal',
          elementTrigger: {
            content: {
              text: 'SwitchElement',
            },
            button: {
              icon: {
                icon: 'edit',
              },
            },
            input: {
              id: 'switchElementInput',
              name: 'input',
            },
          },
          header: {
            title: {
              text: 'Modal Wrapper',
            },
          },
          footer: {
            buttonsRight: [
              {
                text: 'Save changes',
                action: 'save',
              },
            ],
          },
        },
        elements: [
          {
            label: 'Select a date',
            type: 'datepicker',
            name: 'datepicker',
            id: 'datepicker#1',
          },
          {
            label: 'Select one of the options',
            type: 'select',
            name: 'select',
            id: 'select',
            options: [
              {
                value: 'option_1',
                label: 'Option 1',
                checked: true,
              },
              {
                value: 'option_2',
                label: 'Option 2',
              },
            ],
          },
          {
            label: 'Select one of the options',
            type: 'radio',
            name: 'radio',
            column: true,
            options: [
              {
                text: 'Option 1',
                name: 'option[name]',
                value: 0,
                checked: true,
              },
              {
                text: 'Option 2',
                name: 'option[name]',
                value: 1,
              },
              {
                text: 'Option 3',
                name: 'option[name]',
                value: 2,
              },
            ],
          },
        ],
      },
    ]}
    newSwitchElement={{
      modal: {
        id: 'SwitchElementModal',
        elementTrigger: {
          content: {
            text: 'SwitchElement',
          },
          button: {
            icon: {
              icon: 'edit',
            },
          },
          input: {
            id: 'switchElementInput',
            name: 'input',
          },
        },
        header: {
          title: {
            text: 'Modal Wrapper',
          },
        },
        footer: {
          buttonsRight: [
            {
              text: 'Save changes',
              action: 'save',
            },
          ],
        },
      },
      options: [
        { text: 'I know when it was' },
        { text: 'I know how old I was' },
        { text: 'I am not sure' },
      ],
      elements: [
        {
          label: 'Select a date',
          type: 'datepicker',
          name: 'datepicker',
          id: 'datepicker#1',
        },
        {
          label: 'Select one of the options',
          type: 'select',
          name: 'select',
          id: 'select',
          options: [
            {
              value: 'option_1',
              label: 'Option 1',
              checked: true,
            },
            {
              value: 'option_2',
              label: 'Option 2',
            },
          ],
        },
        {
          label: 'Select one of the options',
          type: 'radio',
          name: 'radio',
          column: true,
          options: [
            {
              text: 'Option 1',
              name: 'option[name]',
              value: 0,
              checked: true,
            },
            {
              text: 'Option 2',
              name: 'option[name]',
              value: 1,
            },
            {
              text: 'Option 3',
              name: 'option[name]',
              value: 2,
            },
          ],
        },
      ],
    }}
  />
);
