import React from 'react';
import { Divider } from '@components/ui';
import { Title } from '@components/text';
import Tooltip from '.';

export default {
  title: 'BRMS/ui/Tooltip',
  component: Tooltip,
  parameters: {
    componentSubtitle:
      'Uses React-Tippy (https://github.com/tvkhoa/react-tippy) under the hood',
  },
};

const Template = (args) => (
  <>
    <Divider height={50} color={'transparent'} />
    <Tooltip {...args}>
      <Title inline text={'Click me to trigger'} />
    </Tooltip>
    <Divider height={50} color={'transparent'} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  content: {
    text: 'I am a tooltip',
  },
  simple: true,
};

export const Variants = () => (
  <>
    {[
      'top',
      'top-start',
      'top-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
      'right',
      'right-start',
      'right-end',
    ].map((pos, index) => (
      <div style={{ textAlign: 'center' }} key={index}>
        <Divider height={50} color={'transparent'} />
        <Tooltip
          {...Default.args}
          position={pos}
          content={{ text: `I am a tooltip with position: ${pos}` }}
        >
          <Title inline text={pos} />
        </Tooltip>
      </div>
    ))}
  </>
);

export const Collection = () => {
  const content = [
    {
      checked: false,
      label: 'Han Solo',
      value: 'hansolo',
      disabled: true,
    },
    {
      checked: true,
      label: 'Palpatine',
      value: 'sithlord',
      disabled: true,
    },
    {
      checked: false,
      label: 'Windu',
      value: 'badmfr',
    },
    {
      checked: false,
      label: 'Windu',
      value: 'badmfr',
    },
    {
      checked: false,
      label: 'Windu',
      value: 'badmfr',
    },
  ];
  return (
    <Tooltip
      collection
      position='bottom'
      content={content}
      title={'Collection of'}
    >
      <Title inline text={'Click me to trigger'} />
    </Tooltip>
  );
};

export const Ordered = () => {
  const content = [
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
    { title: 'Some title', detail: 'Some detail' },
  ];
  return (
    <Tooltip ordered position='bottom' content={content}>
      <Title inline text={'Click me to trigger'} />
    </Tooltip>
  );
};

export const Triggers = () => (
  <>
    {['mouseenter', 'click'].map((trigger, index) => (
      <div style={{ textAlign: 'center' }} key={index}>
        <Divider height={50} color={'transparent'} />
        <Tooltip {...Default.args} trigger={trigger}>
          <Title inline text={`${trigger} me to trigger`} />
        </Tooltip>
      </div>
    ))}
  </>
);

export const Editor = () => (
  <>
    <Tooltip
      content={[
        { key: 'Some', error: 'Error' },
        { key: 'Some', error: 'Error' },
      ]}
      position='bottom'
      trigger={'mouseenter'}
      editor
    >
      <Title inline text={'Editor Tooltip'} />
    </Tooltip>
  </>
);

const Actions = [
  {
    icon: {
      icon: 'warning-square',
      color: 'positive',
    },
    text: 'Change password',
    action: '#',
  },
  {
    icon: {
      icon: 'warning-square',
      color: 'assertive',
    },
    text: 'Logout',
    action: '#',
  },
  {
    icon: {
      icon: 'warning-square',
      color: 'energized',
    },
    text: 'Remove account',
    action: '#',
  },
];
export const Simple_Vs_Complex = () => (
  <>
    <div style={{ textAlign: 'center' }}>
      <Divider height={50} color={'transparent'} />
      <Tooltip {...Default.args}>
        <Title inline text={'Simple'} />
      </Tooltip>
    </div>
    <div style={{ textAlign: 'center' }}>
      <Divider height={50} color={'transparent'} />
      <Tooltip {...Default.args} simple={false} actions={Actions}>
        <Title inline text={`Complex (Another component)`} />
      </Tooltip>
      <Divider height={50} color={'transparent'} />
      <Tooltip
        {...Default.args}
        simple={false}
        actions={Actions}
        button={{
          text: 'Add item',
          icon: {
            name: 'plus',
            width: 20,
            height: 18,
          },
          color: 'positive',
        }}
      />
    </div>
  </>
);

export const Legend = () => {
  const content = [
    {
      title: {
        text: 'Start',
      },
      sign: {
        icon: 'circle',
        color: 'positive',
      },
    },
    {
      title: {
        text: 'End',
      },
      sign: {
        icon: 'circle',
        color: 'energized',
      },
    },
    {
      title: {
        text: 'Error',
      },
      sign: {
        icon: 'circle',
        color: 'royal',
      },
    },
    {
      title: {
        text: 'Activation Condition',
      },
      sign: {
        icon: 'square',
      },
    },
    {
      title: {
        text: 'Rule',
      },
      sign: {
        icon: 'border',
      },
    },
    {
      title: {
        text: 'Assignment',
      },
      sign: {
        icon: 'dashed',
      },
    },
    {
      title: {
        text: 'Subset',
      },
      sign: {
        icon: 'dashed',
        color: 'positive',
        rotate: 'true',
      },
    },
  ];
  return (
    <Tooltip legend position='bottom' content={content}>
      <Title inline text={'Click me to trigger'} />
    </Tooltip>
  );
};

export const LegendIcons = () => {
  const content = [
    {
      title: {
        text: 'Start',
      },
      icon: {
        icon: 'empty',
      },
    },
    {
      title: {
        text: 'Start',
      },
      icon: {
        icon: 'empty',
      },
    },
  ];
  return (
    <Tooltip legend position='bottom' content={content}>
      <Title inline text={'Click me to trigger'} />
    </Tooltip>
  );
};
