import React from 'react';
import PropTypes from 'prop-types';
import Content from '@components/text/content';
import Title from '@components/text/title';
import Status from '@components/text/status';
import Divider from '@components/ui/divider';
import Tooltip from '@components/ui/tooltip';
import Badge from '@components/badge/badge';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '@components/card/wrapper';
import styles from './question.module.scss';
import ToolTip from '../../tooltip';

const cx = classNames.bind(styles);

const QuestionCard = ({
  id,
  size,
  color,
  title,
  label,
  content,
  className,
  diagram,
  unreachable,
  start,
  end,
  tooltipText,
  buttons,
  nodePorts,
  disabled,
  onClick,
}) => {
  const cardClasses = cx(
    {
      wrapper: true,
      badge: start && nodePorts?.[`${id}_top`]?.properties?.label?.text,
      diagram,
      unreachable,
      end,
      content,
    },
    className
  );

  const renderWrapper = () => (
    <>
      {start && nodePorts?.[`${id}_top`]?.properties?.label?.text && (
        <Badge
          className={styles.startingbadge}
          content={{ text: nodePorts?.[`${id}_top`]?.properties?.label?.text }}
          color={'positive'}
        />
      )}
      <Wrapper
        size={size}
        color={color}
        disabled={disabled}
        className={cardClasses}
      >
        <div className={styles.contentWrapper} onClick={() => onClick}>
          {(content || end || start || unreachable || label) && (
            <div className={styles.content}>
              {(end || start) && (
                <>
                  <Badge
                    className={styles.questionBadge}
                    content={{ text: start ? 'Start' : 'End' }}
                    color={start ? 'positive' : 'energized'}
                  />
                  <Divider width={5} color={'transparent'} />
                  <Content
                    color={start ? 'positive' : 'energized'}
                    size={'s'}
                    text={'•'}
                  />
                  <Divider width={5} color={'transparent'} />
                </>
              )}
              {unreachable && (
                <div className={styles.unreachableLabel}>
                  <Tooltip
                    trigger={'mouseenter'}
                    position={'top-start'}
                    content={{
                      text: tooltipText || unreachable.map((item) => item),
                    }}
                    simple
                  >
                    <Status
                      color={'royal'}
                      icon={{ icon: 'warning', iconModule: true }}
                    />
                  </Tooltip>
                  <Divider width={5} color={'transparent'} />
                </div>
              )}
              {content.map((c, i) => (
                <div key={i} className={styles.contentWrap}>
                  <Content size={'s'} {...c} />
                  <Divider width={5} color={'transparent'} />
                </div>
              ))}
              {!content && label && <Divider width={5} color={'transparent'} />}
              {label && (
                <>
                  <Content
                    color={
                      start
                        ? 'positive'
                        : end
                        ? 'energized'
                        : unreachable
                        ? 'royal'
                        : 'stable-500'
                    }
                    size={'s'}
                    text={'•'}
                  />
                  <Divider width={5} color={'transparent'} />
                  <Content size={'s'} {...label} />
                </>
              )}
            </div>
          )}
          {title && <Title size={'h4'} color={'dark'} wrap {...title} />}
        </div>
      </Wrapper>
    </>
  );

  return !disabled && buttons && buttons.length > 0 ? (
    <ToolTip buttons={buttons}>{renderWrapper()}</ToolTip>
  ) : (
    renderWrapper()
  );
};

QuestionCard.propTypes = {
  /** The id of the node */
  id: PropTypes.string,
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The label of the card, above the title */
  label: PropTypes.shape(Title.propTypes),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The list items of the card */
  content: PropTypes.arrayOf(PropTypes.shape(Content.propTypes)),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Automatically added when the card is used inside the diagram view. Sets the card on a fixed width */
  diagram: PropTypes.bool,
  /** The status warning */
  unreachable: PropTypes.bool,
  /** Start card with Badge & positive color */
  start: PropTypes.bool,
  /** End card with Badge & energized color */
  end: PropTypes.bool,
  /** The tooltip for the card */
  tooltipText: PropTypes.string,
  /** Disables card interaction */
  disabled: PropTypes.bool,
};

QuestionCard.defaultProps = {
  id: null,
  color: 'light',
  size: 'm',
  className: '',
  content: null,
  label: null,
  title: '',
  diagram: false,
  unreachable: false,
  start: false,
  end: false,
  tooltipText: null,
  disabled: false,
};

// Needed for Storybook
QuestionCard.displayName = 'QuestionCard';

export default QuestionCard;
