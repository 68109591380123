import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Content from '@components/text/content';
import Title from '@components/text/title';
import Button from '@components/button/button';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '../wrapper';
import styles from './widgetCard.module.scss';

const cx = classNames.bind(styles);

const WidgetCard = ({ className, preview, url }) => {
  const scriptLoaded = () => {
    BRMSWidget.create({
      testMode: 'true',
      url: 'required, not needed',
      ruleset: 'required, not needed',
      preview: JSON.parse(preview),
    });
  };

  useEffect(() => {
    const element = document.getElementById('brms-widget');
    const script = document.createElement('script');
    script.setAttribute('id', 'questionnaire');
    script.src = url;
    element.appendChild(script);
    script.onload = () => scriptLoaded();
  }, []);

  const cardClasses = cx({ wrapper: true }, className);
  return (
    <Wrapper className={cardClasses}>
      <div id='brms-widget'></div>
    </Wrapper>
  );
};

WidgetCard.propTypes = {
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Data for showing the questionnaire */
  preview: PropTypes.string,
  /** URL of the provided widget */
  url: PropTypes.string,
};

WidgetCard.defaultProps = {
  className: '',
  preview: null,
  url: 'http://localhost:8081/widget.js',
};

// Needed for Storybook
WidgetCard.displayName = 'WidgetCard';

export default WidgetCard;
